import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AtendimentoService } from "src/app/services/atendimento.service";
import { DespesaReceitaService } from "src/app/services/despesa-receita.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { TipoAtendimentoService } from "src/app/services/tipo-atendimento.service";
import { UsuarioService } from "src/app/services/usuario.service";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { ConfirmationService } from "primeng/api";
import 'moment-timezone';
import { ArquivoService } from "src/app/services/arquivo.service";
import { FileUpload } from "primeng/fileupload";
@Component({
    selector: "app-atendimentos",
    templateUrl: "./atendimentos.component.html",
    styleUrls: ["./atendimentos.component.css"],
})
export class AtendimentosComponent implements OnInit {
    @ViewChild('myFiles') myFiles!: FileUpload;


    cols = [
        { field: "solicitante", header: "Pessoa atendida" },
        { field: "tipo", header: "Tipo" },
        { field: "situacao", header: "Situação" },
        { field: "data_hora_atendimento", header: "Data e hora atendimento" },
        { field: "data_cadastro", header: "Data do cadastro" },
    ];

    colsTipoAtendimento = [
        { field: "id", header: "ID" },
        { field: "nome", header: "Nome" },
    ];

    dropDownTipoTipoAtendimento = [
        { value: null, label: "Selecione" },
        { value: "MEDICO", label: "Médico" },
        { value: "HISTORICO", label: "Histórico do cliente" },
    ];

    uploadedFiles = [];

    dataSourceTipoAtendimento = [];

    loadingGrid = false;

    loading = false;

    dataSource = [];

    totalRecords = 0;

    nomePacienteFiltro;

    nomeMedicoFiltro;

    dateFilter = [];

    statusFilter;

    responsibleFilter;

    typeFilter;

    especialidadeFilter;

    valorFilter;

    descricaoFilter;

    nomeUsuarioFiltro;

    modalForm = false;

    modalFormTipoAtendimento = false;

    formTipoAtendimento: FormGroup;

    formAtendimento: FormGroup;

    filteredPessoas = [];

    dropdownTipo = [];

    dropdownEspecialidade = [];

    tipoAtendimentoSelected;

    dropdownUsuarios = [];

    dropdownCredenciados = [];

    dropdownSituacaoAtendimento = [
        { value: 0, label: "Aberto" },
        { value: 1, label: "Em andamento" },
        { value: 2, label: "Finalizado" },
        { value: 3, label: "Cancelado" },
    ];

    dropdownSituacaoAtendimentoFilter = [
        { value: null, label: "Todos" },
        { value: 0, label: "Aberto" },
        { value: 1, label: "Em andamento" },
        { value: 2, label: "Finalizado" },
        { value: 3, label: "Cancelado" },
    ];

    payamentsOpen = [];

    openPayaments = false;

    loadingPayaments = false;

    dropdownTipoFilter = [];

    dropdownCredenciadosFilter = [];

    pt = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    constructor(
        private formBuilder: FormBuilder,
        private tipoAtendimentoService: TipoAtendimentoService,
        private _pessoaService: PessoaService,
        private usuarioService: UsuarioService,
        private atendimentoService: AtendimentoService,
        private despesaReceitaService: DespesaReceitaService,
        private confirmationService: ConfirmationService,
        private arquivoService: ArquivoService,
    ) { }

    ngOnInit(): void {
        this.formTipoAtendimento = this.formBuilder.group({
            id: [null],
            nome: [null, Validators.required],
            tipo: [null, Validators.required],
        });

        this.formAtendimento = this.formBuilder.group({
            id: [null],
            solicitante: [null, Validators.required],
            pessoa_responsavel_id: [null],
            tipo_atendimento_id: [null, Validators.required],
            especialidade_id: [null],
            usuario_responsavel_id: [null],
            descricao: [null],
            situacao: [null],
            data_hora: [null],
            valor: [null],
        });

        this.dateFilter = null;

        this.tipoAtendimentoIndex();

        this.listAllCredenciados();

        this.listUsuarios();

        this.listAllAtendimentos();
    }

    listAllAtendimentos() {
        let request = "";

        if (this.nomePacienteFiltro != null && this.nomePacienteFiltro != "") {
            request += "&solicitante=" + this.nomePacienteFiltro;
        }

        console.log(this.typeFilter);

        if (this.typeFilter != null) {
            request += "&tipo=" + this.typeFilter;
        }

        if (this.responsibleFilter != null) {
            request += "&responsavel=" + this.responsibleFilter;
        }

        if (this.dateFilter != null) {
            if (this.dateFilter[0]) {
                let startDate = moment(this.dateFilter[0]).format("YYYY-MM-DD");
                let endDate = moment(this.dateFilter[1]).format("YYYY-MM-DD");

                request += "&inicio=" + startDate;
                request += "&fim=" + endDate;
            }
        }

        if (this.statusFilter != null) {
            request += "&situacao=" + this.statusFilter;
        }

        if (this.especialidadeFilter != null) {
            request += "&especialidade=" + this.especialidadeFilter;
        }

        if (this.valorFilter != null) {
            request += "&valor=" + this.valorFilter;
        }

        if (this.descricaoFilter != null) {
            request += "&descricao=" + this.descricaoFilter;
        }

        this.atendimentoService.index(null, request).subscribe((json) => {
            this.dataSource = json.map((a) => {
                return {
                    id: a.id,
                    solicitante: a.solicitante?.nome,
                    tipo: a.tipo_atendimento?.nome,
                    situacao: a.situacao_label,
                    valor: a.valor,
                    responsavel: a.responsavel?.nome || '--',
                    especialidade: a.especialidade?.nome || '--',
                    descricao: a.descricao,
                    data_hora_atendimento: moment(a.data_hora).format("DD/MM/YYYY HH:mm"),
                    data_cadastro: moment(a.created_at).format("DD/MM/YYYY HH:mm"),
                };
            });
            this.totalRecords = this.dataSource.length;
        });
    }

    showDialogForm(id = undefined) {
        this.tipoAtendimentoSelected = null
        this.uploadedFiles = [];
        this.formAtendimento.reset();


        if (id != undefined) {
            this.atendimentoService.show(id).subscribe((json) => {
                this.formAtendimento.patchValue(json);

                if (json.atendimento_arquivos.length) {
                    json.atendimento_arquivos.map(item => {
                        this.uploadedFiles.push(item.arquivo);
                    })
                }

                this.changeCredenciados(json.especialidade_id);

                this.changeTipoAtendimento()
            });

        }

        this.modalForm = true;
    }

    filterPessoa(event) {
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = json;
        });
    }

    duplicarRegistro(id) {
        this.loading = true;
        this.confirmationService.confirm({
            message: "Você realmente deseja duplicar este Registro ?",
            header: "Confirmação da ação",
            icon: "pi pi-info-circle",
            accept: () => {
                this.formAtendimento.reset();

                this.atendimentoService.show(id).subscribe((json) => {
                    this.formAtendimento.patchValue(json);

                    let form = this.formAtendimento.value;

                    let body = {
                        pessoa_solicitante_id: form.solicitante.id,
                        pessoa_responsavel_id: form.pessoa_responsavel_id,
                        tipo_atendimento_id: form.tipo_atendimento_id,
                        especialidade_id: form.especialidade_id,
                        descricao: form.descricao,
                        usuario_responsavel_id: form.usuario_responsavel_id,
                        valor: form.valor,
                        data_hora: form.data_hora,
                        situacao: form.situacao,
                    };

                    this.atendimentoService.save(body).subscribe((json) => {
                        this.showDialogForm(json.id);
                        this.loading = false;
                        this.listAllAtendimentos();
                    });
                });
            },
        });
    }

    saveTipoAtendimento() {
        let body = this.formTipoAtendimento.value;
        this.tipoAtendimentoService.save(body, body.id).subscribe((json) => {
            this.modalFormTipoAtendimento = false;
            this.tipoAtendimentoIndex();
        });
    }

    listUsuarios() {
        this.usuarioService.grid().subscribe((json) => {
            this.dropdownUsuarios = json.map((u) => {
                return { value: u.id, label: u.email };
            });
        });
    }

    save() {
        let form = this.formAtendimento.value;

        let body = {
            pessoa_solicitante_id: form.solicitante.id,
            pessoa_responsavel_id: form.pessoa_responsavel_id,
            tipo_atendimento_id: form.tipo_atendimento_id,
            especialidade_id: form.especialidade_id,
            descricao: form.descricao,
            usuario_responsavel_id: form.usuario_responsavel_id,
            valor: form.valor,
            data_hora: form.data_hora,
            situacao: form.situacao,
            arquivos: this.uploadedFiles
        };

        console.log('body', body)

        this.atendimentoService.save(body, form.id).subscribe((json) => {
            this.modalForm = false;
            this.listAllAtendimentos();
        });
    }

    listAllCredenciados() {
        this.dropdownCredenciadosFilter = [];
        this.dropdownCredenciadosFilter.push({ value: null, label: "Todos" });

        this._pessoaService.listAllCredenciados().subscribe((json) => {
            this.dropdownCredenciados = json.map((c) => {
                return { value: c.id, label: c.nome };
            });

            json.map((c) => {
                this.dropdownCredenciadosFilter.push({
                    value: c.id,
                    label: c.nome,
                });
            });

            this._pessoaService.listAllClinicas().subscribe(json => {
                json.forEach(element => {
                    this.dropdownCredenciados.push({ value: element.id, label: element.nome });

                    this.dropdownCredenciadosFilter.push({
                        value: element.id,
                        label: element.nome,
                    });
                });
            })
        });
    }

    changeCredenciados(idEspecialidade = null) {
        this._pessoaService
            .getEspecialidadeByCredenciado(
                this.formAtendimento.get("pessoa_responsavel_id").value
            )
            .subscribe((json) => {
                this.dropdownEspecialidade = json.map((e) => {
                    return {
                        value: e.especialidade_id,
                        label: e.especialidade.nome,
                    };
                });

                if (idEspecialidade != null) {
                    this.formAtendimento.get('especialidade_id').setValue(idEspecialidade)
                }
            });
    }

    tipoAtendimentoIndex() {
        this.dropdownTipoFilter = [];
        this.dropdownTipoFilter.push({ value: null, label: "Todos" });

        this.tipoAtendimentoService.index().subscribe((json) => {
            this.dataSourceTipoAtendimento = json;
            this.dropdownTipo = [];

            json.map((item) => {
                this.dropdownTipo.push({
                    value: item.id,
                    label: item.nome,
                    tipo: item.tipo,
                });

                this.dropdownTipoFilter.push({
                    value: item.id,
                    label: item.nome,
                    tipo: item.tipo,
                });
            });
        });
    }

    changeTipoAtendimento() {
        console.log(this.dropdownTipo);
        let tipoAtendimento = this.dropdownTipo.find((item) => {
            return (
                item.value ==
                this.formAtendimento.get("tipo_atendimento_id").value
            );
        });
        this.tipoAtendimentoSelected = tipoAtendimento.tipo;

    }

    showDialogFormTipoAtendimento(id = null) {
        this.modalFormTipoAtendimento = true;

        this.formTipoAtendimento.reset();

        this.tipoAtendimentoService.show(id).subscribe((json) => {
            this.formTipoAtendimento.patchValue(json);
        });
    }

    loadLazy(event = null) { }

    onSelectSolicitante() {
        this.loadingPayaments = true;

        let solicitante = this.formAtendimento.get("solicitante").value;

        let queryString = `pessoa_id=${solicitante.id}&status=ABERTO&typeList=list`;

        this.despesaReceitaService
            .index(null, queryString)
            .subscribe((payamentsOpen) => {
                queryString = `pessoa_id=${solicitante.id}&status=ATRASADO&typeList=list`;
                this.despesaReceitaService
                    .index(null, queryString)
                    .subscribe((payamentsLate) => {
                        payamentsOpen.forEach((element) => {
                            this.payamentsOpen.push({
                                valor: element.valor,
                                vencimento: element.vencimento,
                                status: "open",
                            });
                        });

                        payamentsLate.forEach((element) => {
                            this.payamentsOpen.push({
                                valor: element.valor,
                                vencimento: element.vencimento,
                                status: "late",
                            });
                        });

                        this.loadingPayaments = false;
                    });
            });
    }

    get solicitanteSelected() {
        let solicitante = this.formAtendimento.get("solicitante").value;
        return solicitante?.id || null;
    }

    exportExcelAtendimentos() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "atendimentos");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    onUpload(event) {

        let formData = new FormData();

        event.files.forEach(file => {
            console.log(file)
            formData.append('myFiles[]', file);
        });

        this.arquivoService.multi(formData).subscribe(json => {
            this.uploadedFiles = json;
            this.myFiles.clear();
        })

    }

    removeArquivo(file) {
        let files = this.uploadedFiles.filter(f => {return f != file});
        this.uploadedFiles = files;
    }
}
