import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PlanoService } from 'src/app/services/plano.service';

@Component({
  selector: 'app-plano-mundial-card',
  templateUrl: './plano-mundial-card.component.html',
  styleUrls: ['./plano-mundial-card.component.css']
})
export class PlanoMundialCardComponent implements OnInit {
  @Output() onPayload = new EventEmitter<any>();

  payload = {
    idade_limite: null
  };

  @Input() idPlano;


  constructor(
    private planoService: PlanoService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {

    this.payload = {
      idade_limite: null
    };

    if (this.idPlano != undefined) {
      this.planoService.show(this.idPlano).subscribe(json => {
        if (json?.payload) {
          this.payload = JSON.parse(json.payload);
        }
      })
    }
  }

  payloadChange() {
    this.onPayload.emit(this.payload)
  }
}
