import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { ContratoService } from "src/app/services/contrato.service";
import { PessoaService } from "src/app/services/pessoa.service";

@Component({
    selector: "app-contrato-dependentes-mundialcard",
    templateUrl: "./contrato-dependentes.component.html",
    styleUrls: ["./contrato-dependentes.component.css"],
})
export class ContratoDependentesComponent implements OnInit {
    dependenteSelected: any;

    colsDependentes: any[] = [
        { field: "id", header: "ID", type: "default" },
        { field: "nome", header: "Nome", type: "default" },
        {
            field: "payload",
            subfield: "valor_adicional",
            header: "Valor adicional",
            type: "currency",
        },
    ];

    @Input() dependentes: any[] = [];

    @Input() id;

    @Input() titular;

    @Input() tipoDependentePlano;

    @Output() onEmitDependentes = new EventEmitter<any>();

    @Output() onEmitValorAdicional = new EventEmitter<any>();

    filteredPessoas: any[] = [];

    dropdownGrauParentesco: any[] = [
        { value: 1, label: "Cônjugue" },
        { value: 2, label: "Filhos" },
        { value: 3, label: "Pai e mãe" },
        { value: 4, label: "Outros" },
    ];

    grauParentesco: any;

    planoAdicional: boolean;
    loadingCarteirinha: boolean = false;

    valorAdicional = 0;

    modalDependente = false;

    formDependente: FormGroup;

    ageDependente: number = 0;

    uploadFileFuncionarios = [];

    pt: any = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    constructor(
        private _pessoaService: PessoaService,
        private contratoService: ContratoService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.formDependente = this.formBuilder.group({
            id: [null],
            nome: [null],
            cpf: [null],
            nascimento: [null],
        });

        // this.dependentes = [];
    }

    showModalDependente() {
        this.formDependente.reset();
        this.modalDependente = true;
    }

    saveDependente() {
        this.calculateAge();

        if (
            this.ageDependente >= 10 &&
            this.formDependente.get("cpf").value == null
        ) {
            alert("O campo CPF é obrigatorio");
            return;
        }

        let body = {
            idade: this.ageDependente,
            pessoa: {
                ativo: true,
                nome: this.formDependente.get("nome").value,
                tipo: "FISICA",
                cliente: true,
                dependente: true,
            },
            pessoaFisica: {
                cpf: this.formDependente.get("cpf").value,
                nascimento: this.formDependente.get("nascimento").value,
            },
        };

        this._pessoaService
            .saveDependenteToPessoaContrato(body)
            .subscribe((json) => {
                this.modalDependente = false;
            });
    }

    addDependente() {
        if (
            this.dependenteSelected == undefined ||
            this.dependenteSelected == null
        ) {
            alert("Selecione um dependente");
            return;
        }

        let payload = {
            grau_parentesco: this.grauParentesco,
            plano_adicional: this.planoAdicional,
            valor_adicional: this.valorAdicional,
        };

        this.dependentes.push({
            id: this.dependenteSelected["id"],
            nome: this.dependenteSelected["nome"],
            payload: payload,
        });
        this.dependenteSelected = null;
        this.onEmitDependentes.emit(this.dependentes);

        let valorAdicional = 0;
        this.dependentes.map((dependente) => {
            valorAdicional += dependente.payload.valor_adicional || 0;
        });

        this.onEmitValorAdicional.emit(valorAdicional);

        this.planoAdicional = false;
        this.valorAdicional = 0;
    }

    filterPessoa(event) {
        //
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = [];
            if (json.length > 0) {
                json.map((pessoa) => {
                    this.filteredPessoas = [
                        ...this.filteredPessoas,
                        {
                            id: pessoa.id,
                            celular_principal: pessoa.celular_principal,
                            nome: `${pessoa.nome} - ${pessoa.cpf}`,
                        },
                    ];
                });
            } else {
                this.filteredPessoas = [];
            }
        });
    }

    remove(rowData) {
        let dependentes = this.dependentes.filter((d) => {
            return d.id != rowData.id;
        });
        this.dependentes = dependentes;
        this.onEmitDependentes.emit(this.dependentes);
    }

    downloadCarteirinha(event) {
        this.loadingCarteirinha = true
        this.contratoService
            .getCarteirinha(this.id, this.titular.id, event.id)
            .subscribe((json) => {
                window.open(json, "_blank");
                this.loadingCarteirinha = false
            });
    }

    changeGrauParentesco() {
        let tipo = this.tipoDependentePlano.find((t) => {
            return t.tipo == this.grauParentesco;
        });

        console.log("tipoDependentePlano", this.tipoDependentePlano);
        console.log("grauParentesco", this.grauParentesco);

        if (tipo == undefined) {
            this.planoAdicional = true;
        } else {
            this.planoAdicional = false;
        }
    }

    calculateAge() {
        const birthdateString = moment(
            this.formDependente.get("nascimento").value
        ).format("YYYY-MM-DD");
        // Create a Date object for the birthdate
        const birthdate = new Date(birthdateString);

        // Get the current date
        const currentDate = new Date();

        // Calculate the age by subtracting the birth year from the current year
        let age = currentDate.getFullYear() - birthdate.getFullYear();

        // Check if the birthday has occurred this year
        if (
            currentDate.getMonth() < birthdate.getMonth() ||
            (currentDate.getMonth() === birthdate.getMonth() &&
                currentDate.getDate() < birthdate.getDate())
        ) {
            age--;
        }

        this.ageDependente = age;
    }
}
