import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { FileUpload } from "primeng/fileupload";
import { forkJoin } from "rxjs";
import { ArquivoService } from "src/app/services/arquivo.service";
import { ContratoService } from "src/app/services/contrato.service";
import { DespesaReceitaService } from "src/app/services/despesa-receita.service";
import { FormaPagamentoService } from "src/app/services/forma-pagamento.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { PlanoContaService } from "src/app/services/plano-conta.service";
import { PlanoService } from "src/app/services/plano.service";
import { UsuarioService } from "src/app/services/usuario.service";
import * as FileSaver from "file-saver";

@Component({
    selector: "app-contratos",
    templateUrl: "./contratos.component.html",
    styleUrls: ["./contratos.component.css"],
})
export class ContratosComponent implements OnInit {
    @ViewChild("myFile") myFiles!: FileUpload;

    fileContratoAssinado: any = undefined;
    dataSource = [];
    dados: any;
    dataSourceAgrupamentos = [];

    cols = [
        { field: "id", header: "ID" },
        { field: "titular", header: "Titular" },
        { field: "situacao", header: "Situação" },
        { field: "created_at", header: "Data do cadastro" },
        { field: "usuario", header: "Gerado por" },
    ];

    modal: boolean = false;

    filteredPessoas: any[] = [];

    dropdownPlano: any[] = [];

    form: FormGroup;

    formAgrupamento: FormGroup;

    parcelaList: any[] = [];

    pt: any = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    dependentes: any[] = [];

    dropdownPlanoContas: any[] = [];

    dropdownSituacao: any[] = [
        { value: "ATIVO", label: "Ativo" },
        { value: "INATIVO", label: "Inativo" },
        { value: "BAIXADO", label: "Baixado" },
    ];

    subdominio = localStorage.getItem("subdominio");

    planoSelected: any;

    userSelected: any;

    situacaoSelected: any;

    dropdownUsuario: any[] = [];

    titularFiltro: any;

    tipoDependentePlano;

    valorAdicional = 0;

    dropdownFormaPagamento = [];

    dropdownVendedores = [];

    direitoInserir: boolean = false;
    direitoAlterar: boolean = false;
    loadingCarteirinha: boolean = false;

    titularesPJ = [];

    disabledDays: number[] = [31];

    invalidDates;

    selectedContratos = [];

    modalFaturamento = false;

    agrupamentosContrato = [];

    modalAgrupamentos = false;

    titularFiltroAgrupamento;

    constructor(
        private _pessoaService: PessoaService,
        private _formBuilder: FormBuilder,
        private planoService: PlanoService,
        private _despesaReceitaService: DespesaReceitaService,
        private _planoContasService: PlanoContaService,
        private contratoService: ContratoService,
        private usuarioService: UsuarioService,
        private arquivoService: ArquivoService,
        private _formaPagamentoService: FormaPagamentoService
    ) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: [null],
            titular: [null, Validators.required],
            plano_id: [null],
            plano_conta_id: [null],
            numero_meses: [null],
            valor: [null],
            sub_total: [null],
            gerar_parcelas: [true],
            primeiro_vencimento: [null],
            situacao: [null, Validators.required],
            numero: [null],
            tipo: [null],
            faturamento: [null],
            empresa: [null],
            vendedor_id: [null, Validators.required],
            forma_pagamento_id: [null],
            dia_vencimento: [null],
            numero_parcelas: [null],
            reparcelamento: [false],
        });

        this.formAgrupamento = this._formBuilder.group({
            titular: [null],
            sub_total: [null],
            imposto_deduzido: [null],
            total: [null],
            vencimento: [null],
            forma_pagamento_id: [null],
        });

        let today = new Date();
        today.setDate(31);
        this.invalidDates = [today];

        this.listPlanos();

        this.listPlanoContaDespesa();

        this.listUsuarios();

        this.index();

        this.indexAgrupamento();

        this.listFormaPagamento();

        this.listVendedores();

        this.direitoInserir =
            this.contratoService.direitos("INSERIR_CONTRATOS");
        this.direitoAlterar =
            this.contratoService.direitos("ALTERAR_CONTRATOS");
    }

    listFormaPagamento() {
        this._formaPagamentoService.index().subscribe((formasPagamentos) => {
            this.dropdownFormaPagamento = formasPagamentos.map((f) => {
                return { value: f.id, label: f.descricao };
            });
        });
    }

    onEmitDependentes(event) {
        this.dependentes = event;
    }

    changeType(type) {
        console.log(type);
    }

    index() {
        let request = "";

        if (this.planoSelected != null && this.planoSelected != undefined) {
            request += "&plano=" + this.planoSelected;
        }

        if (this.userSelected != null && this.userSelected != undefined) {
            request += "&usuario=" + this.userSelected;
        }

        if (this.titularFiltro != null && this.titularFiltro != undefined) {
            request += "&titular=" + this.titularFiltro;
        }

        if (
            this.situacaoSelected != null &&
            this.situacaoSelected != undefined
        ) {
            request += "&situacao=" + this.situacaoSelected;
        }

        this.contratoService.index(null, request).subscribe((contratos) => {
            contratos.map((contrato) => {
                contrato.loading = false
                contrato.created_at = moment(contrato.created_at).format(
                    "DD/MM/YYYY HH:mm"
                );
            });

            this.dataSource = contratos;
        });
    }

    indexAgrupamento() {
        this.selectedContratos = [];

        let request = "&tipo_titular=JURIDICA";

        if (this.titularFiltroAgrupamento != null && this.titularFiltroAgrupamento != '') {
            request += `&titular=${this.titularFiltroAgrupamento}`;
        }

        this.contratoService.index(null, request).subscribe((contratos) => {
            contratos.map((contrato) => {
                contrato.created_at = moment(contrato.created_at).format(
                    "DD/MM/YYYY HH:mm"
                );
            });

            this.dataSourceAgrupamentos = contratos;
        });
    }

    showPDF(rowData = null) {
        this.contratoService
            .getContratoMundialCard(rowData.id)
            .subscribe((json) => {
                // const fileURL = URL.createObjectURL(json.link);
                window.open(json.link, "_blank");
            });
    }

    listPlanoContaDespesa() {
        this.dropdownPlanoContas = [];
        this._planoContasService.getForReceitas().subscribe((json) => {
            json.map((item) => {
                this.dropdownPlanoContas.push({
                    value: item.id,
                    label: item.nome,
                });
            });
        });
    }

    listUsuarios() {
        this.usuarioService.grid().subscribe((usuarios) => {
            usuarios.map((usuario) => {
                this.dropdownUsuario.push({
                    value: usuario.id,
                    label: usuario.name,
                });
            });
        });
    }

    downloadCarteirinha() {
        this.loadingCarteirinha = true
        let contrato = this.dados;
        this.contratoService
            .getCarteirinha(contrato.id, contrato.titular.id)
            .subscribe((json) => {
                window.open(json, "_blank");
                this.loadingCarteirinha = false
            });
    }

    showDialogForm(rowData = null) {
        this.valorAdicional = 0;

        this.modal = true;

        this.dependentes = [];

        this.form.enable();

        this.form.reset();

        this.titularesPJ = [];

        this.parcelaList = [];

        this.fileContratoAssinado = undefined;

        if (rowData != null) {
            this.form.get("gerar_parcelas").setValue(false);

            this.contratoService.show(rowData.id).subscribe((contrato) => {
                console.log("contrato", contrato);
                contrato.primeiro_vencimento = new Date(
                    contrato.primeiro_vencimento
                );

                this.form.patchValue(contrato);
                console.log("contrato", this.form.value);
                if (contrato.tipo == "PJ") {
                    contrato?.funcionarios.map((funcionario) => {
                        let obj = {};

                        obj["titular_id"] = funcionario.pessoa.id;
                        obj["titular_nome"] = funcionario.pessoa.nome;
                        obj["titular_cpf"] = funcionario.cpf;
                        obj["plano_id"] = funcionario.plano?.id || null;
                        obj["plano_nome"] = funcionario.plano?.nome || null;
                        obj['nascimento'] = funcionario.nascimento;
                        obj["plano_valor"] = parseFloat(
                            funcionario.plano?.valor || 0
                        );
                        obj["ativo"] = true;

                        let dependentes = [];

                        let totalAdicional = 0;
                        funcionario?.dependentes.map((dependente) => {
                            let payload = JSON.parse(dependente.payload);
                            dependentes.push({
                                id: dependente.pessoa.id,
                                nome: dependente.pessoa.nome,
                                payload: payload,
                            });
                            totalAdicional += parseFloat(
                                payload.valor_adicional
                            );
                        });

                        obj["dependentes"] = dependentes;
                        obj["valor_adicional"] = totalAdicional;
                        obj["total"] = obj["plano_valor"] + totalAdicional;

                        this.titularesPJ = [...this.titularesPJ, obj];
                    });
                } else {
                    this.planoService
                        .show(this.form.get("plano_id").value)
                        .subscribe((plano) => {
                            this.tipoDependentePlano = plano.tipo_dependentes;
                        });

                    setTimeout(() => {
                        contrato.dependentes.map((item) => {
                            this.dependentes.push({
                                id: item.dependente.id,
                                nome: item.dependente.nome,
                                payload: JSON.parse(item.payload),
                            });
                        });
                    }, 1000);

                    if (contrato.contrato_pf != null) {
                        this.fileContratoAssinado = contrato.contrato_pf;
                    }
                }
                this.dados = this.form.value;
                this.form.disable();
                this.form.get("situacao").enable();
                this.form.get("reparcelamento").enable();

                this.form.get("forma_pagamento_id").setValue(null);
                this.form.get("primeiro_vencimento").setValue(null);
                this.form.get("numero_parcelas").setValue(null);

                this.form.get("forma_pagamento_id").enable();
                this.form.get("primeiro_vencimento").enable();
                this.form.get("numero_parcelas").enable();
            });
        } else {
            this.dados = this.form.value;
            this.form.get("valor").disable();
            this.form.get("gerar_parcelas").setValue(true);
            this.form.get("tipo").setValue("PF");
        }
    }

    listVendedores() {
        let request = "vendedor=1";
        this._pessoaService.index(null, request).subscribe((vendedores) => {
            this.dropdownVendedores = vendedores.map((item) => {
                return { value: item.id, label: item.nome };
            });
        });
    }

    uploadHandlerDoc(event) {
        this.fileContratoAssinado = event.files[0];
        console.log(this.fileContratoAssinado);
        this.myFiles.clear();
    }

    listPlanos() {
        this.planoService.index().subscribe((planos) => {
            planos.map((plano) => {
                this.dropdownPlano.push({
                    value: plano.id,
                    label: plano.nome,
                    valor: plano.valor,
                });
            });
        });
    } 

    selectedVencimento(event) {
        console.log(event);
        let date = new Date(event);
        if (date.getDate() == 31) {
            date.setDate(30);
            this.form.get("primeiro_vencimento").setValue(date);
        }
    }

    filterPessoa(event) {
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = json.filter((p) => {
                return p.tipo == "FISICA" && p.cliente == true;
            });
        });
    }

    filterPessoaJuridica(event) {
        this._pessoaService
            .autoCompleteEmpresa(event.query)
            .subscribe((json) => {
                this.filteredPessoas = json.filter((x) => {
                    return x.cliente == true;
                });
            });
    }

    onEmitValorAdicional(event) {
        this.valorAdicional = event;
        let total =
            parseFloat(this.form.get("sub_total").value) + this.valorAdicional;
        this.form.get("valor").setValue(total);
    }

    totalContrato(event) {
        this.form.get("sub_total").setValue(event);
        this.form.get("valor").setValue(event);
    }

    changePlano() {
        let plano = this.dropdownPlano.find((p) => {
            return p.value == this.form.get("plano_id").value;
        });
        this.form.get("sub_total").setValue(plano.valor);

        let total =
            parseFloat(this.form.get("sub_total").value) + this.valorAdicional;
        this.form.get("valor").setValue(total);

        this.planoService
            .show(this.form.get("plano_id").value)
            .subscribe((plano) => {
                this.tipoDependentePlano = plano.tipo_dependentes;
            });
    }

    addTitular(event) {
        this.titularesPJ = event;

        let valor = 0;
        let valor2 = 0;
        this.titularesPJ.map((item) => {
            valor2 += parseFloat(item.total);
            if (item["ativo"] == true) {
                valor += parseFloat(item.total);
            }
        });

        console.log("valor", valor);
        console.log("valor2", valor2);

        this.form.get("valor").setValue(valor);
        this.form.get("sub_total").setValue(valor);
    }

    save() {
        let requests = [];

        this.form.enable();

        let configuracao = JSON.parse(localStorage.getItem("configuracao"));

        let form = this.form.value;

        let body: any = {};

        body = {
            ...body,
            contrato: {
                titular_id: form.titular?.id || null,
                plano_id: form.plano_id,
                numero_meses: form.numero_meses,
                valor: form.valor,
                sub_total: form.sub_total,
                situacao: form.situacao,
                numero: form.numero,
                empresa_id: form.empresa?.id || null,
                faturamento: form.faturamento,
                tipo: form.tipo,
                primeiro_vencimento: form.primeiro_vencimento,
                gerar_parcelas: form.gerar_parcelas,
                vendedor_id: form.vendedor_id,
                numero_parcelas: form.numero_parcelas,
                forma_pagamento_id:
                    form.tipo == "PF" ? form.forma_pagamento_id : null,
            },
            parcelas: this.parcelaList,
        };

        if (this.form.get("id").value != null) {
            body = {
                ...body,
                reparcelamento: this.form.get("reparcelamento").value,
            };
        }

        if (form.tipo == "PJ") {
            body = {
                ...body,
                titulares: this.titularesPJ,
            };
        } else {
            body = {
                ...body,
                dependentes: this.dependentes,
            };
        }

        // if (form.id == null || form.tipo == 'PJ') {

        // } else {
        //   body = {
        //     ...body, contrato: { situacao: form.situacao },
        //   }
        // }

        let formData = new FormData();
        formData.append("payload", JSON.stringify(body));
        formData.append("myFile", this.fileContratoAssinado);

        if (form.id == null) {
            requests.push(this.contratoService.store(formData));
        } else {
            requests.push(
                this.contratoService.update(formData, form.id, null, true)
            );
        }

        forkJoin(requests).subscribe((json) => {
            this.index();
            this.modal = false;

            let contrato = json[0];
            if (form.id == null) {
                // if (form.gerar_parcelas && form.tipo == 'PF') {
                //   requests = [];
                //   let primeiro_vencimento = new Date(form.primeiro_vencimento);
                //   for (let index = 1; index <= form.numero_meses; index++) {
                //     let payload: any = {};
                //     if (index == 2) {
                //       let month = primeiro_vencimento.getMonth();
                //       primeiro_vencimento.setMonth(month + 1);
                //       primeiro_vencimento.setDate(form.dia_vencimento);
                //     }
                //     payload = {
                //       pessoa_id: form.titular.id,
                //       vencimento: moment(primeiro_vencimento).format("YYYY-MM-DD"),
                //       plano_contas_id: configuracao.plano_conta_id,
                //       valor: form.valor,
                //       numero_documento: form.numero,
                //       pago: false,
                //       tipo: 'RECEITA',
                //       contrato_id: contrato['id'],
                //       forma_pagamento_id: form.forma_pagamento_id,
                //       dia_faturamento: form.faturamento
                //     };
                //     if (index > 2) {
                //       let month = primeiro_vencimento.getMonth();
                //       primeiro_vencimento.setMonth(month + 1);
                //     }
                //     requests.push(this._despesaReceitaService.store(payload));
                //   }
                //   forkJoin(requests).subscribe(ret => {
                //   })
                // }
            }
        });
    }

    printCarne(row) {
        this.contratoService.generateCarne(row.id).subscribe((data) => {
            data["cobranca"].forEach((element) => {
                let url = element;
                let link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                link.click();
            });

            let link = document.createElement("a");
            link.href = data["carne"];
            link.target = "_blank";
            link.click();
        });
    }

    termoCancelamento(row) {
        let contrato = this.dataSource.find((p) => {
            return p.id == row.id;
        });
        contrato.loading = true
        this.contratoService.termoCancelamento(row.id).subscribe((data) => {
           window.open(data, "_blank");
           contrato.loading = false
        });
    }

    printLink(url) {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
    }

    getLastDayOfMonth(year, month) {
        // Create a date object for the next month's first day
        let nextMonth: any = new Date(year, month + 1, 1);
        // Subtract one day from the next month's first day
        const lastDay = new Date(nextMonth - 1);
        return lastDay.getDate();
    }

    addParcelas() {
        console.log("addParcelas");

        this.parcelaList = [];

        let monthAdd = 0;

        for (
            let index = 1;
            index <= this.form.get("numero_parcelas").value;
            index++
        ) {
            var dataVencimento = new Date(
                this.form.get("primeiro_vencimento").value
            );

            let dayVencimento = dataVencimento.getDate();

            dataVencimento.setDate(1);

            dataVencimento.setMonth(dataVencimento.getMonth() + monthAdd);

            let lastDay = this.getLastDayOfMonth(
                dataVencimento.getFullYear(),
                dataVencimento.getMonth()
            );
            if (dayVencimento > lastDay) {
                dayVencimento = lastDay;
            }

            dataVencimento.setDate(dayVencimento);

            // if (index >= 2) {
            //   let dia = dataVencimento.getDate();
            //   dataVencimento.setMonth(dataVencimento.getMonth() + monthAdd);
            //   dataVencimento.setDate(dia);
            // }

            let adesao = false;
            if (index == 1 && this.form.get("reparcelamento").value != true) {
                adesao = true;
            }

            this.parcelaList.push({
                parcela: index,
                valor: this.form.get("valor").value,
                vencimento: new Date(dataVencimento),
                adesao: adesao,
                pagamento: this.dropdownFormaPagamento.find((p) => {
                    return p.value == this.form.get("forma_pagamento_id").value;
                })["label"],
            });

            monthAdd++;
        }

        console.log("parcelaList", this.parcelaList);
    }

    closeModal() {
        this.modal = false;
    }

    showModalFaturamento() {

        this.formAgrupamento.reset();

        this.formAgrupamento
            .get("titular")
            .setValue(this.selectedContratos[0]["titular"]);

        let sum = 0;
        this.selectedContratos.map((item) => {
            sum += parseFloat(item.valor) || 0;
        });

        this.formAgrupamento.get("sub_total").setValue(sum);
        this.formAgrupamento.get("total").setValue(sum);

        this._pessoaService
            .show(this.selectedContratos[0]["titular_id"])
            .subscribe((json) => {
                let cobranca = json.cobranca;
                this.formAgrupamento
                    .get("forma_pagamento_id")
                    .setValue(cobranca.forma_pagamento_id);

                let vencimento = new Date();
                vencimento.setDate(cobranca.dia_vencimento);
                this.formAgrupamento.get("vencimento").setValue(vencimento);

                this.modalFaturamento = true;
            });
    }

    totContratoFat() {
        let sum = 0;
        this.selectedContratos.map((item) => {
            sum += parseFloat(item.valor) || 0;
        });

        sum = sum - this.formAgrupamento.get("imposto_deduzido").value;

        this.formAgrupamento.get("total").setValue(sum);
    }

    closeModalFaturamento() {
        this.modalFaturamento = false;
    }

    saveFaturamento() {
        let titularId = this.selectedContratos[0]["titular_id"];
        this.selectedContratos.map((item) => {
            if (item.titular_id != titularId) {
                alert("Existem contratos selecionados de diferentes titulares");
                return;
            }
        });

        let body = {
            agrupamento: {
                total: this.formAgrupamento.get("total").value,
                imposto_deduzido:
                    this.formAgrupamento.get("imposto_deduzido").value,
                vencimento: this.formAgrupamento.get("vencimento").value,
                forma_pagamento_id:
                    this.formAgrupamento.get("forma_pagamento_id").value,
            },
            contratos: this.selectedContratos,
        };

        this.contratoService.agruparContratos(body).subscribe((json) => {
            this.modalFaturamento = false;
        });
    }

    getAgrupamentos(rowData) {
        this.contratoService
            .getAgrupamentos(rowData.id)
            .subscribe((agrupamentos) => {
                this.agrupamentosContrato = agrupamentos;
                this.modalAgrupamentos = true;
            });
    }

    closeModalAgrupamentos() {
        this.modalAgrupamentos = false;
    }

    exportExcel() {
        let data: any[] = [];
        this.dataSource.map((item) => {
            data.push({
                id: item.id,
                titular: item.titular,
                data_cadastro: item.created_at,
                gerado_por: item.usuario,
            });
        });

        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "contratos");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            "contratos" + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    cancel(rowData) {
        if (confirm("Deseja realmente cancelar o contrato?")) {
            this.contratoService.delete(rowData.id).subscribe(json => {
                this.index();
            })
        }
    }
}
