import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.css']
})
export class GenericGridComponent implements OnInit {
  @Input() cols:any[] = [];

  @Output() dataSource:any[] = [];

  @Output() onShowDialogForm = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  showDialogForm(rowData = null) {
    this.onShowDialogForm.emit(rowData);
  }
}
