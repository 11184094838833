import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../services/auth.service";
import { UsuarioService } from "../services/usuario.service";
import { DireitoService } from "../services/direito.service";

@Component({
    selector: "app-login",
    templateUrl: "./app.login.component.html",
    styleUrls: ["./app.login.component.css"],
    host: {
        "(document:keydown)": "handleKeyboardEvents($event)",
    },
})
export class AppLoginComponent {
    key: any;
    loading: boolean = false;
    returnUrl: string = "/";

    loginForm!: FormGroup;
    formSubmitted: boolean = false;
    error: string = "";

    showPassword: boolean = false;

    showInputSubdominio: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private usuarioService: UsuarioService,
        private _direito: DireitoService
    ) {}

    handleKeyboardEvents(event: KeyboardEvent) {
        this.key = event.which || event.keyCode;
        if (this.key == 13) {
            this.onSubmit();
        }
    }

    changeDomain() {
        localStorage.setItem('subdominio', this.loginForm.get('subdominio').value);
    }

    ngOnInit(): void {
        let hostname = window.location.hostname;

        let subdominio;

        if (
            hostname == "localhost" ||
            hostname == "dev-financeiro.bksoft.com.br" ||
            hostname == "financeiro.bksoft.com.br"
        ) {
            this.showInputSubdominio = true;
        } else {
            subdominio = hostname.split(".")[0];
        }

        localStorage.setItem("subdominio", subdominio);

        this.loginForm = this.fb.group({
            subdominio: subdominio,
            email: [null],
            password: [null],
        });
    }

    setSubdominio() {
        let subdominio = localStorage.getItem("subdominio");
        if (subdominio != this.loginForm.get("subdominio").value) {
            // localStorage.clear();
            localStorage.setItem(
                "subdominio",
                this.loginForm.get("subdominio").value
            );
            window.location.reload();
        }
    }

    /**
     * convenience getter for easy access to form fields
     */
    get formValues() {
        return this.loginForm.controls;
    }

    getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    };

    /**
     * On submit form
     */
    onSubmit(): void {
        localStorage.clear();

        this.loading = true;
        let form = this.loginForm.value;
        console.log(form);
        this.usuarioService.authenticate(form).subscribe(
            (json) => {
                if (json.token) {
                    json.user["avatar"] = "assets/images/perfil.png";

                    localStorage.setItem("token-financeiro", json.token);
                    localStorage.setItem("device", this.getDeviceType());
                    localStorage.setItem("email", form.email);
                    localStorage.setItem("user", JSON.stringify(json.user));
                    localStorage.setItem("codigo_empresa", json.codigo_empresa);
                    localStorage.setItem(
                        "empresa",
                        JSON.stringify(json.empresa)
                    );
                    localStorage.setItem("conexao", form.subdominio);
                    localStorage.setItem(
                        "configuracao",
                        JSON.stringify(json.configuracao)
                    );
                    localStorage.setItem(
                        "confWebsocket",
                        JSON.stringify(json.confWebsocket)
                    );
                    localStorage.setItem("subdominio", json.subdominio);
                    this._direito
                        .listByGrupo(json.user.grupo_usuario_id)
                        .subscribe((json) => {
                            localStorage.setItem(
                                "direitos",
                                JSON.stringify(json)
                            );
                            this.loading = false;
                            this.router.navigate([""]);

                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        });
                }
            },
            (err) => {
                this.loading = false;
            }
        );
    }
}
