import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";

import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ColorPickerModule } from "primeng/colorpicker";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SidebarModule } from "primeng/sidebar";
import { SliderModule } from "primeng/slider";
import { SpinnerModule } from "primeng/spinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

import { AppCodeModule } from "./app.code.component";
import { AppComponent } from "./app.component";
import { AppMainComponent } from "./app.main.component";
import { AppConfigComponent } from "./app.config.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppProfileComponent } from "./app.profile.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppWizardComponent } from "./pages/app.wizard.component";
import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
import { DashboardBankingComponent } from "./demo/view/dashboardbanking.component";
import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
import { InputDemoComponent } from "./demo/view/inputdemo.component";
import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
import { TableDemoComponent } from "./demo/view/tabledemo.component";
import { ListDemoComponent } from "./demo/view/listdemo.component";
import { TreeDemoComponent } from "./demo/view/treedemo.component";
import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
import { MediaDemoComponent } from "./demo/view/mediademo.component";
import { MenusDemoComponent } from "./demo/view/menusdemo.component";
import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
import { MiscDemoComponent } from "./demo/view/miscdemo.component";
import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
import { FileDemoComponent } from "./demo/view/filedemo.component";
import { DocumentationComponent } from "./demo/view/documentation.component";
import { DisplayComponent } from "./utilities/display.component";
import { ElevationComponent } from "./utilities/elevation.component";
import { FlexboxComponent } from "./utilities/flexbox.component";
import { GridComponent } from "./utilities/grid.component";
import { IconsComponent } from "./utilities/icons.component";
import { SpacingComponent } from "./utilities/spacing.component";
import { TypographyComponent } from "./utilities/typography.component";
import { TextComponent } from "./utilities/text.component";
import { WidgetsComponent } from "./utilities/widgets.component";

import { CountryService } from "./demo/service/countryservice";
import { EventService } from "./demo/service/eventservice";
import { NodeService } from "./demo/service/nodeservice";
import { MenuService } from "./app.menu.service";
import { CustomerService } from "./demo/service/customerservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";
import { IconService } from "./demo/service/iconservice";
import { Interceptor } from "./interceptor/Interceptor";
import { ConfirmationService, MessageService } from "primeng/api";

import { NgxLoadingModule } from "ngx-loading";
import { BkFormComponent } from "./components/bk-form/bk-form.component";
import { BkGridComponent } from "./components/bk-grid/bk-grid.component";

import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { EditorModule } from "primeng/editor";
import { FyGridComponent } from "./components/fy-grid/fy-grid.component";
import { PasswordModule } from "primeng/password";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FormaPagamentoComponent } from "./web/common-modules/forma-pagamento/forma-pagamento.component";
import { DespesaReceitaComponent } from "./web/common-modules/despesa-receita/despesa-receita.component";
import { TableComponent } from "./components/table/table.component";
import { FooterDialogComponent } from "./components/footer-dialog/footer-dialog.component";
import { PlanoContaComponent } from "./web/common-modules/plano-conta/plano-conta.component";

import { NgxPrintModule } from "ngx-print";
import { NgxPrintElementModule } from "ngx-print-element";
// import { ConfiguracaoComponent } from './web/SISFLY/configuracao/configuracao.component';
// import { ConfiguracaoComponent } from './web/comm /configuracao/configuracao.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FullCalendarModule } from "primeng/fullcalendar";
import { PessoaComponent } from "./web/common-modules/pessoa/pessoa.component";
import { CampoPersonalizadoComponent } from "./web/common-modules/campo-personalizado/campo-personalizado.component";
import { CampoPersonalizadoPessoaComponent } from "./web/common-modules/pessoa/campo-personalizado-pessoa/campo-personalizado-pessoa.component";
import { PlanosMundialCardComponent } from "./web/common-modules/planos/planos.component";
import { GenericGridComponent } from "./web/common-modules/generic-grid/generic-grid.component";
import { PlanoMundialCardComponent } from "./web/mundialcard/planp-mundial-card/plano-mundial-card.component";
import { ContratosComponent } from "./web/common-modules/contratos/contratos.component";
import { ContratoDependentesComponent } from "./web/mundialcard/contrato-dependentes/contrato-dependentes.component";
import { AtendimentosComponent } from "./web/mundialcard/atendimentos/atendimentos.component";
import { UsuariosComponent } from "./web/common-modules/usuarios/usuarios.component";
import { BkUploadComponent } from "./web/components/bk-upload/bk-upload.component";
import { ConfiguracaoComponent } from "./web/common-modules/configuracao/configuracao.component";
import { ConfiguracaoPageComponent } from "./web/common-modules/configuracao/configuracao-page/configuracao-page.component";
import { ContratoTitularPlanoComponent } from "./web/mundialcard/contrato-titular-plano/contrato-titular-plano.component";
import { TesteComponent } from "./web/common-modules/teste/teste.component";
import { BitcoinComponent } from "./web/common-modules/bitcoin/bitcoin.component";
import { ShowMultiPdfComponent } from "./web/common-modules/despesa-receita/show-multi-pdf/show-multi-pdf.component";
import { AtivoDigitalComponent } from "./web/cadastro/ativo-digital/ativo-digital.component";
import { ComprarAtivoDigitalComponent } from "./web/comprar/comprar-ativo-digital/comprar-ativo-digital.component";
import { VenderAtivoDigitalComponent } from "./web/vender/vender-ativo-digital/vender-ativo-digital.component";
import { FinanceiroComponent } from "./web/common-modules/financeiro/financeiro.component";
import { CobrancaAutomaticaComponent } from "./web/common-modules/cobranca-automatica/cobranca-automatica.component";
import { ChartLineComponent } from "./web/components/chart-line/chart-line.component";
import { ChartBarComponent } from "./web/components/chart-bar/chart-bar.component";
import { AgendaComponent } from "./web/common-modules/agenda/agenda.component";
import { ContaBancariaComponent } from "./web/common-modules/conta-bancaria/conta-bancaria.component";
import { FinanceiroDashboardComponent } from "./web/dashboard/financeiro-dashboard/financeiro-dashboard.component";
import { AtendimentoDashboardComponent } from "./web/dashboard/atendimento-dashboard/atendimento-dashboard.component";
import { MovimentacaoBancariaComponent } from "./web/common-modules/movimentacao-bancaria/movimentacao-bancaria.component";
import { CarteirinhaComponent } from "./web/mundialcard/carteirinha/carteirinha.component";
import { EnvioCobrancaComponent } from "./web/common-modules/envio-cobranca/envio-cobranca.component";
import { UploadComponent } from "./web/alis/upload/upload.component";
import { RelatorioPageComponent } from './web/common-modules/relatorios/relatorio-page/relatorio-page.component';
import { InadimplenteComponent } from './web/common-modules/relatorios/inadimplente/inadimplente.component';
import { ClientePageComponent } from './web/common-modules/relatorios/cliente-page/cliente-page.component';
import { CentroCustoComponent } from './web/common-modules/centro-custo/centro-custo.component';
import { PedidoVendaComponent } from "./web/common-modules/pedido-venda/pedido-venda.component";

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: ".",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SidebarModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        EditorModule,
        ReactiveFormsModule,
        NgxLoadingModule.forRoot({}),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        PasswordModule,
        LoadingBarModule,
        LoadingBarHttpClientModule,
        NgxSkeletonLoaderModule,
        NgxPrintModule,
        NgxPrintElementModule,
        MessagesModule,
        ProgressSpinnerModule,
        FullCalendarModule,
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppProfileComponent,
        AppConfigComponent,
        DashboardDemoComponent,
        DashboardBankingComponent,
        FormLayoutDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        WidgetsComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppWizardComponent,
        BkFormComponent,
        BkGridComponent,
        FormaPagamentoComponent,
        DespesaReceitaComponent,
        FyGridComponent,
        TableComponent,
        FooterDialogComponent,
        PlanoContaComponent,
        ConfiguracaoComponent,
        PessoaComponent,
        CampoPersonalizadoComponent,
        CampoPersonalizadoPessoaComponent,
        PlanosMundialCardComponent,
        GenericGridComponent,
        PlanoMundialCardComponent,
        ContratosComponent,
        ContratoDependentesComponent,
        AtendimentosComponent,
        UsuariosComponent,
        BkUploadComponent,
        ConfiguracaoPageComponent,
        ContratoTitularPlanoComponent,
        TesteComponent,
        BitcoinComponent,
        ShowMultiPdfComponent,
        AtivoDigitalComponent,
        ComprarAtivoDigitalComponent,
        VenderAtivoDigitalComponent,
        FinanceiroComponent,
        CobrancaAutomaticaComponent,
        ChartLineComponent,
        ChartBarComponent,
        AgendaComponent,
        ContaBancariaComponent,
        FinanceiroDashboardComponent,
        AtendimentoDashboardComponent,
        MovimentacaoBancariaComponent,
        CarteirinhaComponent,
        EnvioCobrancaComponent,
        UploadComponent,
        RelatorioPageComponent,
        InadimplenteComponent,
        ClientePageComponent,
        CentroCustoComponent,
        PedidoVendaComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MenuService,
        MessageService,
        ConfirmationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}
