import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() values: any[] = [];
  @Input() cols: any[] = [];
  @Input() btnAdd: boolean = false;
  @Input() btnUpload: boolean = false;
  @Input() exibirCodigo: boolean = false;
  @Input() labelBtnAdd: string = null;
  @Input() btnEdit: boolean = false;
  @Input() btnDelete: boolean = false;
  @Input() filter: boolean = true;
  @Input() legendTable: string = '';
  @Output() onEdit = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  globalFilterFields: String[] = []

  constructor() { }

  ngOnInit(): void {
    if (this.cols.length > 0) {
      this.globalFilterFields = this.cols.map(x => { return x.field });
    }
  }

}
