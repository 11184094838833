import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AeronaveService } from 'src/app/services/aeronave.service';
import { DireitoService } from 'src/app/services/direito.service';
import { GrupoUsuarioService } from 'src/app/services/grupo-usuario.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { UsuarioFormService } from 'src/app/services/usuario-form.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  usuario: any[] = [];
  grupos: any[] = [];
  cols: any[] = [
    { field: 'nome', header: 'Nome' },
    { field: 'email', header: 'E-mail / Login' },
    { field: 'grupo', header: 'Grupo' },
  ];
  cols_grupos: any[] = [
    { field: 'nome', header: 'Grupo' },
    // { field: 'email', header: 'E-mail / Login' },
    // { field: 'grupo', header: 'Grupo' },
  ];

  formDialog: boolean = false;
  remover: boolean = false;
  id_usuario: any
  nova_pessoa: boolean = false;
  mensagem_usuario: boolean = false;
  mensagem_usuario_email: boolean = false;
  divergencia: boolean = true;
  mensagem_divergencia: boolean = false;
  email_cadastrado: boolean = false;
  modalGrupo: boolean = false;
  sem_cotista: boolean = false;
  desabilita_botao_aeronave: boolean = false;
  desabilita_botao_cotista: boolean = false;
  desabilita_botao_pessoa: boolean = false;
  cotista_utilizado: boolean = false;
  dropDownCotistas: any[] = []
  dropDownPessoas: any[] = []
  filteredPessoa: any[] = [];
  form: FormGroup;
  empresa: any;
  matricula_aeronave: any;
  nome_pessoa: any;
  cotista: any;
  max_cotista: any;
  items = [
    { label: 'Administrador' },
    { label: 'Usuários' },
  ]
  cols_direito: any[];
  home = { icon: 'pi pi-home', routerLink: '/' };

  globalFilterFields = [
    "id",
    "nome",
    "email",
    "grupo",
  ]
  user = JSON.parse(localStorage.getItem('user'));
  acessos: any;
  novosDireitos: any[] = []
  selectedDireitos: any[] = [];
  dropDownAeronaves: any[] = [];
  dropDownGrupo: any[] = []
  array_aeronave: any[] = [];
  array_pessoa: any[] = [];
  array_cotista: any[] = [];

  constructor(private http: HttpClient,
    protected _formBuilder: FormBuilder,
    protected _usuarioService: UsuarioService,
    private _grupoUsuarioService: GrupoUsuarioService,
    private _direito: DireitoService,
  ) { }

  subdominio = localStorage.getItem('subdominio');

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      usuario: this._formBuilder.group({
        id: [null],
        email: [null, Validators.required],
        password: [null],
        pessoa_id: [null],
        integracao_whatsapp_id: [null],
        ativo: [null],
        departamento_id: [null],
        funcionario_id: [null],
        grupo_usuario_id: [null, Validators.required],
        pessoa: [null],
        usuario_automacao: [null],
        altera_senha: [null],
        departamentos: [null],
      }),
      pessoa: this._formBuilder.group({
        nome: [null, Validators.required],
        apelido: [null],
        id: [null],
      }),
      grupo: this._formBuilder.group({
        nome: [null, Validators.required],
        id: [null],
      }),
      filtro: this._formBuilder.group({
        grupo_id: [null],
      }),
    });
    let aba = {
      index: 0
    }
    this.changeAba(aba)

  }

  listGrupos() {
    this.dropDownGrupo = [];
    this._grupoUsuarioService.index().subscribe(json => {
      json.map((item: any) => {
        this.dropDownGrupo.push({ value: item.id, label: item.nome });
      })
    })
  }

  index() {
    this._usuarioService.grid().subscribe(json => {
      this.usuario = []
      json.map(usuario => {


        this.usuario.push({
          id: usuario.id,
          nome: usuario.name,
          email: usuario.email,
          grupo: usuario.grupo
        })
      })
    })

  }

  changeAba(e) {
    console.log(e)
    if (e.index == 0) {
      this.listGrupos()
      this.index()
    }

    if (e.index == 1) {
      this.indexGrupos()
    }

    if (e.index == 2) {
      this.listGrupos()
      this.indexDireitos()
    }
  }

  changeGrupo() {

    this.acessos = []
    this.selectedDireitos = [];


    this._direito.listByGrupo(this.form.get('filtro.grupo_id').value).subscribe(json => {
      json.map(item => {

        let data = {
          label: item.descricao, direito_id: item.direito_id
        }

        this.selectedDireitos.push({
          data: data,
        });

        console.log(this.selectedDireitos)

      });

      this.indexDireitos();

    })
  }

  indexDireitos() {
    if (this.subdominio == 'mundialcard') {
      this._direito.getArvore('FINANCEIRO').subscribe(json1 => {
        this._direito.getArvore('CLINICA').subscribe(json2 => {
          this.acessos = json1.concat(json2)
          this.cols_direito = [
            { field: 'label', header: 'Descrição' },
          ];
        })
      })
    } else {
      this._direito.getArvore('FINANCEIRO').subscribe(json => {
        this.acessos = json
        this.cols_direito = [
          { field: 'label', header: 'Descrição' },
        ];
      })
    }


  }

  indexGrupos() {

    this._grupoUsuarioService.index().subscribe(json => {
      this.grupos = [];
      json.map((item: any) => {
        this.grupos.push({ id: item.id, nome: item.nome });
      })
    })

  }

  editGrupo(event) {
    this.form.reset();
    this._grupoUsuarioService.show(event.id).subscribe((json: any) => {
      this.form.get('grupo')?.patchValue(json);
      this.modalGrupo = true;

    })
  }

  openNew() {
    this.form.reset();
    this.form.get('usuario.ativo')?.setValue(true);
    this.formDialog = true
  }

  openNewGrupo() {
    this.modalGrupo = true
  }

  edit(row: any) {
    this.form.reset();
    this._usuarioService.show(row.id).subscribe((json: any) => {
      this.form.get('usuario')?.patchValue(json);
      this.formDialog = true;
      this.form.get('pessoa.nome')?.setValue(json.pessoa.nome);
      this.form.get('pessoa.apelido')?.setValue(json.pessoa?.fisica?.apelido);
      this.form.get('usuario.grupo_usuario_id').setValue(json.grupo_usuario_id)

      let departamentos: any[] = [];
      json.departamentos.map(departamento => {
        departamentos.push({ value: departamento.id, label: departamento.nome });
      });
      this.form.get('usuario.departamentos').setValue(departamentos);
    })
  }








  hideDialog() {
    this.form.reset()
    this.formDialog = false;
  }




  save() {
    let x = this.form.get('pessoa').value;
    let y = this.form.get('usuario').value;

    let params = {
      usuario: {
        email: y.email,
        password: y.password,
        ativo: y.ativo,
        grupo_usuario_id: y.grupo_usuario_id,
        altera_senha: y.altera_senha,
        pessoa: {
          nome: x.nome,
          apelido: x.apelido,

        },
      }

    }



    if (this.form.get('usuario.id')?.value) {
      this._usuarioService.update(params, this.form.get('usuario.id')?.value).subscribe(json => {
        this.index();
        this.formDialog = false;
      })
    } else {
      this._usuarioService.storeCrm(params).subscribe(json => {
        this.index();
        this.formDialog = false;
      })
    }
  }

  saveGrupo() {
    let grupo = this.form.get('grupo').value

    let params = {
      nome: grupo.nome
    }

    if (grupo.id == null) {
      this._grupoUsuarioService.store(params).subscribe(json => {
        this.indexGrupos()
        this.modalGrupo = false
      })
    } else {
      this._grupoUsuarioService.store(params, grupo.id).subscribe(json => {
        this.indexGrupos()
        this.modalGrupo = false
      })
    }
  }

  selectedDireito() {
    this.novosDireitos = [];
    this.selectedDireitos.map(item => {
      this.novosDireitos.push({
        id: item.data.direito_id,
        superior_id: item.data.superior_id,
      });

    })
  }

  saveVinculo() {
    console.log(this.novosDireitos)
    // let direitos: any[] = []
    // let superior
    // this.novosDireitos.map(item => {
    //   superior = item.superior_id

    //   direitos.push(item.id)
    //   if (superior != null && superior != '') {
    //     let existe = direitos.find(item => item == superior)
    //     if (existe == undefined) {
    //       direitos.push(superior)
    //     }
    //   }

    // })



    let params = {
      direitos: this.novosDireitos,
      grupo_usuario_id: this.form.get('filtro.grupo_id').value,
    }


    this._direito.saveVinculo(true, params).subscribe(json => {
      console.log(json)

    })

  }

}
