import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContaService } from 'src/app/services/conta.service';
import { FormaPagamentoService } from 'src/app/services/forma-pagamento.service';

@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss']
})
export class FormaPagamentoComponent implements OnInit {
  items = [
    { label: 'Formas de pagamento' }]

  home = { icon: 'pi pi-home', routerLink: '/' };

  paymentList: any[] = [];

  form: FormGroup;

  cols: any[] = [
    { field: 'descricao', header: 'Descrição' },
    { field: 'tipo', header: 'Tipo' },
    { field: 'entrada_label', header: 'Entrada' },
    { field: 'saida_label', header: 'Saida' },
    { field: 'conta', header: 'Conta' },
  ]
  formaPagamentoList: any[] = [];

  totalRegistros: number = 0;

  modal: boolean = false;



  dropDownTipo: any[] = [
    { value: 'DINHEIRO', label: 'Dinheiro' },
    { value: 'CREDITO', label: 'Crédito' },
    { value: 'DEBITO', label: 'Débito' },
    { value: 'CARTEIRA', label: 'Carteira' },
    { value: 'BOLETO', label: 'Boleto' },
    { value: 'PIX', label: 'PIX' },
    { value: 'TED', label: 'TED' },
    { value: 'DOC', label: 'DOC' },
  ];

  dropDownConta: any[] = [];

  direitoInserir: boolean = false
  direitoAlterar: boolean = false

  constructor(
    private _formBuilder: FormBuilder,
    private _formaPagamentoService: FormaPagamentoService,
    private _contaService: ContaService,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      formaPagamento: this._formBuilder.group({
        id: [null],
        descricao: [null],
        conta_id: [null],
        permite_parcelamento: [null],
        entrada: [false],
        saida: [false],
        tipo: [false],
      })
    });

    this.index();
    this._contaService.index().subscribe(json => {
      this.dropDownConta.push({ value: null, label: 'Selecione' })
      json.map(f => {

        let label = `${f.banco.nome}`;

        label += ` Ag: ${f.agencia} Cc: ${f.conta}`;

        if (f.digito != null) {
          label += `- ${f.digito}`;
        }

        this.dropDownConta.push({ value: f.id, label: label })
      });
    })

    this.direitoInserir = this._contaService.direitos('INSERIR_FORMA_PAGAMENTO')
    this.direitoAlterar = this._contaService.direitos('ALTERAR_FORMA_PAGAMENTO')
  }


  showDialogForm(item = null) {
    this.form.reset();

    if (item == null) {
      this.form.get('formaPagamento').patchValue({
        entrada: true,
        saida: true,
        permite_parcelamento: true,
      });
    } else {
      this._formaPagamentoService.show(item.id).subscribe(json => {
        this.form.get('formaPagamento').patchValue(json)
      });
    }

    this.modal = true;
  }

  index() {
    this._formaPagamentoService.getDataTable().subscribe(json => {
      json.map(item => {
        item.entrada_label = item.entrada ? 'Sim' : 'Não';
        item.saida_label = item.saida ? 'Sim' : 'Não';
      });
      this.paymentList = json;
    })
  }


  close() {
    this.modal = false;
  }

  save() {
    let params = this.form.get('formaPagamento').value;
    this._formaPagamentoService.save(params, params.id).subscribe(json => {
      this.index();
      this.modal = false;
    })
  }

  showConta() {
    let tipo = this.form.get('formaPagamento.tipo').value;
    if (tipo == 'CREDITO' || tipo == 'DEBITO' || tipo == 'BANCARIO') {
      return true;
    } else {
      return false;
    }
  }

}
