import { Component, OnInit } from '@angular/core';
import { DespesaReceitaService } from 'src/app/services/despesa-receita.service';
import { IntegracaoWhatsappService } from 'src/app/services/integracao-whatsapp.service';

@Component({
  selector: 'app-envio-cobranca',
  templateUrl: './envio-cobranca.component.html',
  styleUrls: ['./envio-cobranca.component.css']
})
export class EnvioCobrancaComponent implements OnInit {
  day: number
  instancia: any
data: any[]
selectedData: any[]
dropDownCanalAtendimento: any[] = [];
  constructor(
    private _despesaReceitaService: DespesaReceitaService,
    private canalAtendimentoService: IntegracaoWhatsappService
  ) { }

  ngOnInit() {
    this.dropDownCanalAtendimento = [];
    this.canalAtendimentoService
        .index()
        .subscribe((canalAtendimentoList) => {
            canalAtendimentoList.map((item: any) => {
                this.dropDownCanalAtendimento.push({
                    value: item.id,
                    label: item.nome,
                    token: item.token,
                    identificador: item.identificador,
                });
            });
            this.instancia = this.dropDownCanalAtendimento[0]
        });

  }

  filter(){
    this._despesaReceitaService.listInvoicesPjPerDay(this.day).subscribe(json => {
      this.data = json
    })
  }

 send(){
  let selected: any[] = []
  this.selectedData.map(item => {
    selected.push(item.contrato_id)
  })
  let params = {
    contractListId: selected,
    instance: this.instancia.identificador,
    token: this.instancia.token,
  }
  this._despesaReceitaService.sendInvoices(params).subscribe(json => {
    console.log(json)
  })
 }

 styleGrid(item) {
    if (item.countEnviados > 0) {
      return { 'background-color': '#d0f5d8' };
    }
}

}
