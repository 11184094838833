import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PedidoVendaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'pedido-venda');
  }

  invoice(id): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/faturar/${id}`, null);
  }

}
