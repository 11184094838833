import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer-dialog',
  templateUrl: './footer-dialog.component.html',
  styleUrls: ['./footer-dialog.component.css']
})
export class FooterDialogComponent implements OnInit {
  @Output() onClose = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();
  @Input() btnClose = true;
  @Input() btnSave = true;

  @Input() disableButtonSave = false;

  constructor() { }

  ngOnInit(): void {
  }

}
