import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class DireitoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'direito');
  }

  getByDescricao(descricao: string): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/by/descricao/${descricao}`);
  }

  listByUsuario(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/by/usuario/${id}`);
  }

  listByGrupo(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/by/grupo/${id}`);
  }

  getArvore(plataforma = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/arvore`

    if (plataforma != null) {
      url += '?plataforma=' + plataforma;
    }

    return this.http.get(url);
  }

  saveVinculo(grupo = null, params): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/link/usuario`

    if (grupo != null) {
      url += '?grupo=' + grupo
    }

    return this.http.post(url, params);
  }
}
