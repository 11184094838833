import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ContaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'conta');
  }

  autocomplete(param): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/autocomplete/${param}`);
  }
  autoCompleteOnlyCaixas(param = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/autoCompleteOnlyCaixas/${param}`);
  }

  getBancos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/bancos`);
  }
}
