import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { PlanoService } from 'src/app/services/plano.service';
import * as moment from 'moment';
import { TipoPlanoService } from 'src/app/services/tipo-plano.service';
import { PlanoComissaoService } from 'src/app/services/plano-comissao.service';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
  selector: 'app-planos-mundial-card',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css']
})
export class PlanosMundialCardComponent implements OnInit {
  dataSource: any[] = [];
  tipos: any[] = [];
  plano_comissao: any[] = [];
  array_coberturas: any[] = [];

  dropDownTipos: any[] = [];
  dropDownPlanos: any[] = [];

  filteredPessoas: any[] = [];

  cols: any[] = [
    { field: 'id', header: 'ID' },
    { field: 'nome', header: 'Nome' },
    { field: 'created_at', header: 'Data do cadastro' },
  ];

  cols_tipos: any[] = [
    { field: 'id', header: 'ID' },
    { field: 'nome', header: 'Nome' },
    { field: 'descricao', header: 'Descrição' },
    { field: 'created_at', header: 'Data do cadastro' },
  ];

  cols_plano_comissao: any[] = [
    { field: 'id', header: 'ID' },
    { field: 'nome', header: 'Pessoa' },
    { field: 'plano', header: 'Plano' },
    { field: 'valor', header: 'Valor' },
    { field: 'created_at', header: 'Data do cadastro' },
  ];

  dropDownTiposDependente: any[] = [
    { value: 1, label: 'Cônjuge' },
    { value: 2, label: 'Filhos' },
    { value: 3, label: 'Pai e mãe' },
    { value: 4, label: 'Outros' },
  ];

  dropDownTipoPlanoComissao: any[] = [
    { value: null, label: 'Digite para pesquisar' },
    { value: 0, label: 'Percentual' },
    { value: 1, label: 'Monetário' },
  ];

  modal: boolean = false;
  modal_tipo: boolean = false;
  modal_plano_comissao: boolean = false;

  customFields: any[] = [];

  subdominio = localStorage.getItem('subdominio')

  form: FormGroup;

  idadeLimite = 0;

  payload;

  direitoInserirPlanos: boolean = false
  direitoAlterarPlanos: boolean = false

  constructor(
    private _formBuilder: FormBuilder,
    private planoService: PlanoService,
    private tipoPlanoService: TipoPlanoService,
    private planoComissaoService: PlanoComissaoService,
    protected _pessoaService: PessoaService,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      descricao: [null],
      tipo_plano_id: [null],
      plano_id: [null],
      pessoa_id: [null],
      tipo: [null],
      tipo_dependente: [[]],
      valor: [0],
    });

    let aba = {
      index: 0
    }
    this.changeAba(aba)



  }

  addCobertura(){
    this.array_coberturas.push({
        descricao: null
    })
}

removeCobertura(item) {
    let arr = this.array_coberturas.filter((e) => {
        return e != item;
    });
    this.array_coberturas = arr;
}

  changeAba(e) {
    console.log(e)
    if (e.index == 0) {
      this.direitoInserirPlanos = this.planoService.direitos('INSERIR_PLANOS')
      this.direitoAlterarPlanos = this.planoService.direitos('ALTERAR_PLANOS')
      this.index();
      this.indexTipos();
    }

    if (e.index == 1) {
      this.indexTipos();
    }

    if (e.index == 2) {
      this.indexPlanoComissao();
    }
  }

  onPayload(event) {
    this.payload = event;
  }

  showDialogForm(rowData = null) {
    this.modal = true;
    this.array_coberturas = []
    this.form.reset();

    if (rowData != null) {
      this.planoService.show(rowData.id).subscribe(plano => {
        this.form.patchValue(plano);

        if (plano.tipo_plano_id) {

          this.form.get('tipo_plano_id').setValue(plano.tipo_plano_id)
        }
        if (plano.tipo_dependentes) {
          let dependentes: any[] = []
          plano.tipo_dependentes.map(item => {
            dependentes.push(item.tipo)

          })
          this.form.get('tipo_dependente').setValue(dependentes)
        }
        if (plano.plano_cobertura) {
          plano.plano_cobertura.map(item => {
           this.array_coberturas.push({
            descricao: item.descricao
           })

          })
        }

        this.payload = JSON.parse(plano.payload);
      })
    }
  }

  filterPessoa(event) {
    this._pessoaService.autoCompleteAll(event.query).subscribe(json => {
      this.filteredPessoas = json;
    });
  }

  showDialogTipoForm(rowData = null) {
    this.modal_tipo = true;
    this.form.reset();

    if (rowData != null) {
      this.tipoPlanoService.show(rowData.id).subscribe(plano => {
        this.form.patchValue(plano);

      })
    }
  }

  showDialogPcForm(rowData = null) {
    this.modal_plano_comissao = true;

    this.form.reset();

    this.form.get('tipo').setValue(0);

    if (rowData != null) {
      this.planoComissaoService.show(rowData.id).subscribe(plano => {
        this.form.patchValue(plano);

        let pessoa = {
          id: plano.pessoa.id,
          nome: plano.pessoa.nome,
        }

        this.form.get('pessoa_id').setValue(pessoa);
      })
    }
  }

  index() {
    this.planoService.index().subscribe(json => {
      json.map(item => {
        item.created_at = moment(item.created_at).format("DD/MM/YYYY HH:mm");
      });

      this.dataSource = json;
      this.dropDownPlanos = [];
      this.dropDownPlanos.push({ value: null, label: 'Digite para pesquisar' });
      this.dataSource.map(item => {
        this.dropDownPlanos = [...this.dropDownPlanos, { value: item.id, label: item.nome }];
      })
    })
  }

  handleChange(e) {
    var index = e.index;
    console.log(index)
  }

  indexTipos() {
    this.tipoPlanoService.index().subscribe(json => {
      console.log(json)
      json.map(item => {
        item.created_at = moment(item.created_at).format("DD/MM/YYYY HH:mm");
      });
      this.tipos = json;
      this.dropDownTipos = [];
      this.dropDownTipos.push({ value: null, label: 'Digite para pesquisar' });
      this.tipos.map(item => {
        this.dropDownTipos = [...this.dropDownTipos, { value: item.id, label: item.nome }];
      })
    })
  }

  indexPlanoComissao() {
    this.planoComissaoService.index().subscribe(json => {
      console.log(json)
      json.map(item => {
        item.created_at = moment(item.created_at).format("DD/MM/YYYY HH:mm");
      });
      this.plano_comissao = json;

    })
  }

  save() {
    let coberturas: any[] = []
this.array_coberturas.map(item => {
    coberturas.push(
        item.descricao
    )
})
    let body = {
      plano: {
        nome: this.form.get('nome').value,
        valor: this.form.get('valor').value,
        tipo_plano_id: this.form.get('tipo_plano_id').value,
        payload: this.payload,
       
      },
      tipo_dependente: this.form.get('tipo_dependente').value,
      coberturas: coberturas
    }

    this.planoService.save(body, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.index();
    });
  }

  saveTipo() {
    let body = {
      nome: this.form.get('nome').value,
      descricao: this.form.get('descricao').value,
    }

    let request = [];

    if (this.form.get('id').value == null) {
      request.push(this.tipoPlanoService.store(body));
    } else {
      request.push(this.tipoPlanoService.update(body, this.form.get('id').value));
    }

    forkJoin(request).subscribe(json => {
      this.modal_tipo = false;
      this.indexTipos();
    })
  }

  savePlanoComissao() {
    let pessoa = this.form.get('pessoa_id').value;

    let body = {
      plano_id: this.form.get('plano_id').value,
      pessoa_id: pessoa.id,
      valor: this.form.get('valor').value,
      tipo: this.form.get('tipo').value,
    }

    this.planoComissaoService.save(body, this.form.get('id').value).subscribe(json => {
      this.modal_plano_comissao = false;
      this.indexPlanoComissao();
    });
  }

}
