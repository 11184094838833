import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class MovimentacaoBancariaService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'movimentacao-bancaria');
    }

    filtro(params, page = null): Observable<any> {
        let url = `${this.url_base}/${this.prefixo}/filtro`;

        if (page !== null) {
            url += '?page=' + page;
        }


        return this.http.post(url, params);
    }


    somaDebitos(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/get/soma/debitos`, params);
    }

    somaCreditos(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/get/soma/creditos`, params);
    }

    transfer(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/transferir`, params);
    }
}
