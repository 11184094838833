import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class PlanoContaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'plano-contas');
  }

  tree(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/arvore`);
  }

  getForDespesas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/despesa`);
  }

  getForReceitas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/receita`);
  }
}
