// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  demo: 'default',
  // urlUpload: 'https://api3.avalongestor.com.br/api/upload-file',
  // urlMultiUpload: 'https://api-hml.avalongestor.com.br/api/multi/upload/files',
  // urlBase: 'https://api-hml.avalongestor.com.br',
  // urlStorage: 'https://api3.avalongestor.com.br/storage',

  //PROD

  // urlBase: 'https://api2.avalongestor.com.br',
  // urlUpload: 'https://api2.avalongestor.com.br/api/upload-file',
  // urlMultiUpload: 'https://api2.avalongestor.com.br/api/multi/upload/files',
  // urlStorage: 'https://api2.avalongestor.com.br/storage',

  //DEV
  urlBase: 'https://api-bkchat-dev.avalongestor.com.br',
  urlUpload: 'https://api-bkchat-dev.avalongestor.com.br/api/upload-file',
  urlMultiUpload: 'https://api-bkchat-dev.avalongestor.com.br/api/multi/upload/files',
  urlStorage: 'https://api-bkchat-dev.avalongestor.com.br/storage',

  // urlBase: 'https://chatapi-aws.avalongestor.com.br',
  // urlUpload: 'https://chatapi-aws.avalongestor.com.br/api/upload-file',
  // urlMultiUpload: 'https://chatapi-aws.avalongestor.com.br/api/multi/upload/files',
  // urlStorage: 'https://chatapi-aws.avalongestor.com.br/storage',



  //VALDIR
  // urlUpload: 'http://localhost:8000/api/upload-file',
  // urlMultiUpload: 'http://localhost:8000/api/multi/upload/files',
  // urlBase: 'http://localhost:8000',
  // urlStorage: 'http://localhost:8000/storage',  


  // urlBase: 'http://18.228.23.54',
  // urlUpload: 'http://18.228.23.54/api/upload-file',
  // urlMultiUpload: 'http://18.228.23.54/api/multi/upload/files',
  // urlStorage: 'http://18.228.23.54/storage',

  // urlBase: 'https://devchat.avalongestor.com.br',
  // urlUpload: 'https://devchat.avalongestor.com.br/api/upload-file',
  // urlMultiUpload: 'https://devchat.avalongestor.com.br/api/multi/upload/files',
  // urlStorage: 'https://devchat.avalongestor.com.br/storage',


  // urlUpload: 'https://e896-2804-1b3-8100-c70-91b0-8006-d44f-99ee.sa.ngrok.io/Github/avalongestor-api/public/api/upload-file',
  // urlMultiUpload: 'https://e896-2804-1b3-8100-c70-91b0-8006-d44f-99ee.sa.ngrok.io/Github/avalongestor-api/public/api/multi/upload/files',
  // urlBase: 'https://e896-2804-1b3-8100-c70-91b0-8006-d44f-99ee.sa.ngrok.io/Github/avalongestor-api/public',
  // urlStorage: 'https://e896-2804-1b3-8100-c70-91b0-8006-d44f-99ee.sa.ngrok.io/Github/avalongestor-api/public/storage',






  GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.