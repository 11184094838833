import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
  selector: 'app-bitcoin',
  templateUrl: './bitcoin.component.html',
  styleUrls: ['./bitcoin.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})
export class BitcoinComponent implements OnInit {
  form: FormGroup;
  tela: number = 1
  porcentagem: number = 8
  key: any;
  etapas: number = 12
  etapaAtual: number = 1
  base64textString: any
  termoAceite: boolean = false
  loading: boolean = false
  sucesso: boolean = false
  errCep: boolean = false

  tipo_documento: any

  nao_informou_crypto: boolean = false
  nao_informou_valor: boolean = false
  falta_selecionar_termo: boolean = false
  primeiro_nome_vazio: boolean = false
  ultimo_nome_vazio: boolean = false
  campo_cpf_vazio: boolean = false
  campo_cnpj_vazio: boolean = false
  campo_telefone_vazio: boolean = false
  campo_email_vazio: boolean = false
  campo_email_conf_invalido: boolean = false
  campo_email_invalido: boolean = false
  campo_tipo_documento_vazio: boolean = false
  email_diferente: boolean = false
  endereco_invalido: boolean = false
  comentario_vazio: boolean = false

  voltou: boolean = false

  selectedTipo: any = null;
  selectedAtivoDigital: any = null;
  selectedCrypto: any = null;
  selectedValor: any = null;
  selectedLugar: any = null;

  selectedTermoAceite1: any = null;
  selectedTermoAceite2: any = null;
  selectedTermoAceite3: any = null;

  class: any = 'row  p-grid  p-fluid'
  classBtn: any = 'p-d-flex p-jc-between'

  filesFrente: any = null
  filesVerso: any = null
  filesCnpj: any = null
  filesCs: any = null
  filesTermo: any = null

  docFrente: any[] = []
  docVerso: any[] = []
  docTermo: any[] = []
  docCnpj: any[] = []
  docCs: any[] = []

  tiposPessoa: any[] = [{ name: ' Pessoa Física', key: 'pf' }, { name: ' Pessoa Jurídica', key: 'pj' }, { name: ' Ambas', key: 'amb' }];
  arrayAtivosDigitais: any[] = [{ name: ' Sim', key: 'sim' }, { name: ' Não', key: 'nao' }];
  arrayCryptos: any[] = [{ name: ' USDT (Tether)', key: 'usdt' }, { name: ' BTC (Bitcoin)', key: 'btc' }, { name: ' ETH (Ethereum)', key: 'eth' }, { name: ' Outras', key: 'outras' },];
  arrayValores: any[] = [
    { name: ' De R$  1,00 a R$1.000,00', key: 0 },
    { name: ' De R$  1.000,00 a R$10.000,00', key: 1 },
    { name: ' De R$  10.000,00 a R$50.000,00', key: 2 },
    { name: ' Acima de R$50.000,00', key: 3 },
  ];
  arrayLugares: any[] = [{ name: ' Site', key: 'site' },
  { name: ' Google', key: 'google' },
  { name: ' P2P', key: 'p2p' },
  { name: ' Indicação', key: 'indicacao' },
  ];

  dropDownTipo: any[] = [
    { value: null, key: 'Selecione' },
    { value: 0, key: 'CNH, RG e Passaporte (Frente)' },
    { value: 1, key: 'CNH, RG e Passaporte (Verso)' },
    { value: 2, key: 'Selfie com documento pessoal' },
  ]

  arrayTermoAceite1: any[] = [
    { value: 1, key: 'Estou ciente e me responsabilizo na negociação de criptoativos junto a empresa ED Intermediação de Negócios (BitEasy) CNPJ: 21.130.938/0001-04.' },
  ]
  arrayTermoAceite2: any[] = [
    { value: 1, key: 'Empresa não promete ganhos ou investimentos de qualquer natureza com criptoativos, faz somente a intermediação de compra e venda conforme normativa Nº 1899 de 10/07/2019.' },
  ]
  arrayTermoAceite3: any[] = [

    { value: 1, key: 'É de minha responsabilidade onde irei alocar, custodiar ou armazenar os criptoativos negociados com a ED Intermediação de Negocios (BitEasy) CNPJ: 21.130.938/0001-04' },
  ]

  dropDownTipoDocumento: any[] = [
    { value: 'RG', label: 'RG' },
    { value: 'CNH', label: 'CNH' },
  ]

  documentos: any[] = [{ name: 'RG', key: 'rg' }, { name: 'CNH', key: 'cnh' }];
  selectedDocumento: any = null;
  comentario: any = null

  constructor(
    private _formBuilder: FormBuilder,
    private _pessoaService: PessoaService,
    private http: HttpClient,

  ) { }

  ngOnInit(): void {
    this.selectedTipo = this.tiposPessoa[1];
    // this.selectedAtivoDigital = this.arrayAtivosDigitais[1];
    // this.selectedCrypto = this.arrayCryptos[1];
    this.selectedValor = this.arrayValores[1];
    this.selectedLugar = this.arrayLugares[1];
    this.selectedDocumento = this.documentos[1];

    this.form = this._formBuilder.group({
      dados_pessoais: this._formBuilder.group({
        primeiro_nome: [null, Validators.required],
        meio_nome: [null],
        tipo_documento: [null],
        tipo: [null, Validators.required],
        ultimo_nome: [null, Validators.required],
      }),
      pf: this._formBuilder.group({
        cpf: [null, Validators.required],
        telefone: [null, Validators.required],
        email: [null, Validators.required],
        conf_email: [null, Validators.required],
        cep: [null, Validators.required],
        endereco: [null, Validators.required],
        numero: [null],
        complemento: [null],
        estado: [null, Validators.required],
        cidade: [null, Validators.required],
      }),
      pj: this._formBuilder.group({
        cnpj: [null, Validators.required],
      })
    })
  }

  nextPage() {
    this.class = 'next row  p-grid  p-fluid'
    this.classBtn = 'next p-d-flex p-jc-between'
    setTimeout(() => {
      this.classBtn = 'p-d-flex p-jc-between'
    }, 1000);
    let dados

    if (this.tela == 1) {
      this.classBtn = 'p-d-flex p-jc-between'
      dados = this.form.get('dados_pessoais').value
      if ((dados.primeiro_nome == null || dados.primeiro_nome == '' || dados.primeiro_nome == ' ') || (dados.ultimo_nome == null || dados.ultimo_nome == '' || dados.ultimo_nome == ' ')) {
        if ((dados.primeiro_nome == null || dados.primeiro_nome == '' || dados.primeiro_nome == ' ')) {
          this.primeiro_nome_vazio = true
        } else {
          this.primeiro_nome_vazio = false
        }
        if ((dados.ultimo_nome == null || dados.ultimo_nome == '' || dados.ultimo_nome == ' ')) {
          this.ultimo_nome_vazio = true
        } else {
          this.ultimo_nome_vazio = false
        }

        return
      } else {
        if ((dados.primeiro_nome != null || dados.primeiro_nome != '' || dados.primeiro_nome != ' ')) {
          this.primeiro_nome_vazio = false
        } else {
          this.primeiro_nome_vazio = true
        }
        if ((dados.ultimo_nome != null || dados.ultimo_nome != '' || dados.ultimo_nome != ' ')) {
          this.ultimo_nome_vazio = false
        } else {
          this.ultimo_nome_vazio = true
        }

        this.etapaAtual = 2
        this.tela = 2
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      }
    }
    if (this.tela == 2) {
      this.etapaAtual = 2
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 3) {
      dados = this.form.get('pf').value
      if ((dados.cpf == null || dados.cpf == '' || dados.cpf == ' ')) {
        this.campo_cpf_vazio = true
        return
      } else {
        this.campo_cpf_vazio = false

        if (this.form.get('dados_pessoais.tipo').value == 'amb') {
          this.etapaAtual = 4
          this.tela = 16
          this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
          return
        } else {
          this.etapaAtual = 4
          this.tela = 4
          this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
          return
        }

      }

    }
    if (this.tela == 4) {
      dados = this.form.get('pf').value
      if ((dados.telefone == null || dados.telefone == '' || dados.telefone == ' ')) {
        this.campo_telefone_vazio = true
        return
      }

      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 5
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 5
      } else {
        this.etapaAtual = 6
      }

      this.campo_telefone_vazio = false
      this.tela = 5
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return


    }
    if (this.tela == 5) {
      dados = this.form.get('pf').value
      if ((dados.email == null || dados.email == '' || dados.email == ' ') || (dados.conf_email == null || dados.conf_email == '' || dados.conf_email == ' ')) {
        this.campo_email_vazio = true

        return
      } else if (dados.email != dados.conf_email) {
        this.email_diferente = true

      } else {
        this.email_diferente = false
        this.campo_email_vazio = false

        if (dados.email) {
          let valida = this.validateEmail(dados.email)
          if (valida == false) {
            this.campo_email_invalido = true
            return
          } else {
            this.campo_email_invalido = false
          }
        }
        if (dados.conf_email) {
          let valida = this.validateEmail(dados.email)
          if (valida == false) {
            this.campo_email_conf_invalido = true
            return
          } else {
            this.campo_email_conf_invalido = false
          }
        }


        if (this.form.get('dados_pessoais.tipo').value == 'pf') {
          this.etapaAtual = 6
        } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
          this.etapaAtual = 6
        } else {
          this.etapaAtual = 7
        }
        this.tela = 6
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      }

    }
    if (this.tela == 6) {
      dados = this.form.get('pf').value
      if ((dados.cep == null || dados.cep == '' || dados.cep == ' ') || (dados.endereco == null || dados.endereco == '' || dados.endereco == ' ') || (dados.cidade == null || dados.cidade == '' || dados.cidade == ' ') || (dados.estado == null || dados.estado == '' || dados.estado == ' ')) {
        this.endereco_invalido = true
        return
      }

      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 7
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 7
      } else {
        this.etapaAtual = 8
      }
      this.endereco_invalido = false
      this.tela = 7
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return

    }
    if (this.tela == 7) {
      dados = this.form.get('dados_pessoais').value
      if (this.docFrente.length == 0 || this.docVerso.length == 0) {
        return
      }

      if (dados.tipo_documento == null) {
        this.campo_tipo_documento_vazio = true
        return
      }


      // if (this.form.get('dados_pessoais.tipo').value == 'pj' || this.form.get('dados_pessoais.tipo').value == 'amb') {
      //   if (this.docFrente.length == 0 || this.docVerso.length == 0 || this.docTermo.length == 0 || this.docCnpj.length == 0 || this.docCs.length == 0) {
      //     return
      //   }
      // }
      this.campo_tipo_documento_vazio = false
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {

        this.etapaAtual = 8
        this.tela = 9
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {

        this.etapaAtual = 8
        this.tela = 8
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else {
        this.etapaAtual = 9
        this.tela = 8
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      }



    }
    if (this.tela == 8) {
      if (this.docCnpj.length == 0 || this.docCs.length == 0) {
        return
      }

      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 9
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 9
      } else {
        this.etapaAtual = 10
      }
      this.tela = 9
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }
    if (this.tela == 9) {
      if (this.docTermo.length == 0) {
        return
      }
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {

        this.etapaAtual = 9
        this.tela = 10
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {

        this.etapaAtual = 10
        this.tela = 10
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else {
        this.etapaAtual = 11
        this.tela = 10
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      }

    }
    if (this.tela == 10) {
      if (this.selectedTermoAceite1 == null || this.selectedTermoAceite2.value == null || this.selectedTermoAceite3.value == null) {
        this.falta_selecionar_termo = true
        return
      }
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.falta_selecionar_termo = false
        this.etapaAtual = 10
        this.tela = 11
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.falta_selecionar_termo = false
        this.etapaAtual = 11
        this.tela = 11
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else {
        this.falta_selecionar_termo = false
        this.etapaAtual = 12
        this.tela = 11
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      }


    }
    if (this.tela == 11) {

      if (this.selectedValor == null) {
        this.nao_informou_valor = true
        return
      }


      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 12
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 13
      } else {
        this.etapaAtual = 14
      }
      this.tela = 12
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }
    if (this.tela == 12) {
      if (this.selectedCrypto == null) {
        this.nao_informou_crypto = true
        return
      }
      this.nao_informou_crypto = false
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 12
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 13
      } else {
        this.etapaAtual = 14
      }
      this.tela = 13
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }
    if (this.tela == 13) {
      if (this.selectedValor == null) {
        this.nao_informou_valor = true
        return
      }
      this.nao_informou_valor = false
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 13
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 14
      } else {
        this.etapaAtual = 15
      }
      this.tela = 14
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }
    if (this.tela == 14) {
      if (this.selectedLugar.key == 'indicacao') {
        if (this.comentario == null || this.comentario == '' || this.comentario == ' ') {
          this.comentario_vazio = true
          return
        }
      }
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 14
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 15
      } else {
        this.etapaAtual = 16
      }
      this.comentario_vazio = false
      this.tela = 15
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return

    }
    if (this.tela == 16) {
      let dados = this.form.get('pj').value
      if (dados.cnpj == null || dados.cnpj == '' || dados.cnpj == ' ') {
        this.campo_cnpj_vazio = true
        return
      }

      if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 4
      } else {
        this.etapaAtual = 5
      }

      this.campo_cnpj_vazio = false

      this.tela = 4
      this.porcentagem = Math.trunc(Math.trunc(100 * this.etapaAtual / this.etapas))
      return
    }

  }

  getCEP() {
    let cep = this.form.get('pf.cep').value
    cep = parseInt(cep.replace(/[^0-9]/g, ""));

    if (cep == null) {
      return;
    }


    this.http
      .get(`https://viacep.com.br/ws/${cep}/json`)
      .toPromise()
      .then(
        (result) => {
          console.log(result)
          // this.form.get('pf.bairro').setValue(result["bairro"]);
          // this.form.get('pf.complemento').setValue(result["complemento"])
          this.form.get('pf.endereco').setValue(result["logradouro"] + ' - ' + result["bairro"])
          this.form.get('pf.cidade').setValue(result["localidade"])
          this.form.get('pf.estado').setValue(result["uf"])
          this.errCep = false;
        },
        (err) => {
          this.errCep = true;
        }
      );

  }


  previousPage() {
    this.class = 'previous row  p-grid  p-fluid'
    this.classBtn = 'previous p-d-flex p-jc-between'
    setTimeout(() => {
      this.classBtn = 'p-d-flex p-jc-between'
    }, 1000);
    if (this.tela == 1) {
      this.etapaAtual = 1
      this.tela = 1
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 2) {
      this.etapaAtual = 1
      this.tela = 1
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 3) {
      this.etapaAtual = 2
      this.tela = 2
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 4) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 3
        this.tela = 3
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 3
        this.tela = 16
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      } else {
        this.etapaAtual = 4
        this.tela = 16
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
        return
      }

    }
    if (this.tela == 5) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 4
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 4
      } else {
        this.etapaAtual = 5
      }

      this.tela = 4
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 6) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 5
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 5
      } else {
        this.etapaAtual = 6
      }

      this.tela = 5
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 7) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 6
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 6
      } else {
        this.etapaAtual = 7
      }

      this.tela = 6
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 8) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 6
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 7
      } else {
        this.etapaAtual = 8
      }
      this.tela = 7
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 9) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 7
        this.tela = 7
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 8
        this.tela = 8
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      } else if (this.form.get('dados_pessoais.tipo').value == 'amb') {
        this.etapaAtual = 9
        this.tela = 8
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      }

    }
    if (this.tela == 10) {

      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 8
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 9
      } else {
        this.etapaAtual = 10
      }

      this.tela = 9
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 11) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 9
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 10
      } else {
        this.etapaAtual = 11
      }

      this.tela = 10
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 12) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 10
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 11
      } else {
        this.etapaAtual = 12
      }

      this.tela = 11
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 13) {
      if (this.selectedAtivoDigital.key == 'sim') {
        if (this.form.get('dados_pessoais.tipo').value == 'pf') {
          this.etapaAtual = 11
        } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
          this.etapaAtual = 12
        } else {
          this.etapaAtual = 13
        }
        this.tela = 12
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      } else if (this.selectedAtivoDigital.key == 'nao') {
        if (this.form.get('dados_pessoais.tipo').value == 'pf') {
          this.etapaAtual = 10
        } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
          this.etapaAtual = 11
        } else {
          this.etapaAtual = 12
        }
        this.tela = 11
        this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      }
    }
    if (this.tela == 14) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 12
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 13
      } else {
        this.etapaAtual = 14
      }
      this.tela = 13
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 15) {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 13
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 14
      } else {
        this.etapaAtual = 15
      }
      this.tela = 14

      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
    }
    if (this.tela == 16) {
      if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 2
        this.tela = 2
      } else {
        this.etapaAtual = 3
        this.tela = 3
      }

      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }

  }

  tipoPessoa(event) {
    this.class = 'next row  p-grid  p-fluid'
    this.classBtn = 'next p-d-flex p-jc-between'
    setTimeout(() => {
      this.classBtn = 'p-d-flex p-jc-between'
    }, 1000);
    this.form.get('dados_pessoais.tipo').setValue(event)
    if (event == 'pf') {
      this.etapaAtual = 3
      this.etapas = 14
      this.porcentagem = 23
      this.tela = 3
    } if (event == 'pj') {
      this.etapaAtual = 3
      this.etapas = 15
      this.porcentagem = 23
      this.tela = 16
    }
    if (event == 'amb') {
      this.etapaAtual = 3
      this.etapas = 16
      this.porcentagem = 23
      this.tela = 3
    }

  }

  ativoDigital(event) {
    if (event == 'sim') {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 11
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 12
      } else {
        this.etapaAtual = 13
      }
      this.selectedCrypto = null
      this.selectedValor = null
      this.tela = 12
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    } else if (event == 'nao') {
      if (this.form.get('dados_pessoais.tipo').value == 'pf') {
        this.etapaAtual = 12
      } else if (this.form.get('dados_pessoais.tipo').value == 'pj') {
        this.etapaAtual = 13
      } else {
        this.etapaAtual = 14
      }
      this.selectedCrypto = null
      this.selectedValor = null
      this.tela = 13
      this.porcentagem = Math.trunc(100 * this.etapaAtual / this.etapas)
      return
    }
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    this.key = event.which || event.keyCode;
    if (this.key == 13) {
      if (this.tela == 13) {
        this.enviarFormulario()

      } else {
        this.nextPage()
      }

    }
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // formularioPessoaFisica() {
  //   let pessoa = this.form.get('dados_pessoais').value
  //   let pf = this.form.get('pf').value
  //   let pj = this.form.get('pj').value

  //   let requests = [];

  //   let formData: FormData = new FormData();

  //   this.docFrente.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);
  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData).subscribe)

  //   formData = new FormData();

  //   this.docVerso.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));


  //   formData = new FormData();

  //   this.docTermo.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));

  //   forkJoin(requests).subscribe((json: any[]) => {
  //     let urlDocFrente = json[0]['url'];
  //     let urlDocVerso = json[1]['url'];
  //     let urlDocTermo = json[2]['url'];

  //     let params = {
  //       pessoa: {
  //         primeiro_nome: pessoa.primeiro_nome,
  //         meio_nome: pessoa.meio_nome,
  //         ultimo_nome: pessoa.ultimo_nome,
  //         tipo: pessoa.tipo,
  //         email: pf.email,
  //         telefone: pf.telefone,
  //         negociou: this.selectedAtivoDigital.key,
  //         crypto: this.selectedCrypto.key,
  //         valor: this.selectedCrypto.key,
  //       },
  //       pessoa_fisica: {
  //         cpf: pf.cpf
  //       },
  //       pessoa_juridica: {
  //         cnpj: pj.cnpj
  //       },
  //       endereco: {
  //         cep: pf.cep,
  //         endereco: pf.endereco,
  //         estado: pf.estado,
  //         cidade: pf.cidade,
  //       },
  //       como_conheceu: {
  //         onde: this.selectedLugar.key,
  //         comentario: this.comentario,
  //       },
  //       urlDocFrente: urlDocFrente,
  //       urlDocVerso: urlDocVerso,
  //       urlDocTermo: urlDocTermo
  //     }

  //   })
  // }

  // formularioPessoaJuridica() {
  //   let pessoa = this.form.get('dados_pessoais').value
  //   let pf = this.form.get('pf').value
  //   let pj = this.form.get('pj').value

  //   let requests = [];

  //   let formData: FormData = new FormData();

  //   this.docCnpj.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);
  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData).subscribe)

  //   formData = new FormData();

  //   this.docCs.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));


  //   formData = new FormData();

  //   this.docTermo.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));

  //   forkJoin(requests).subscribe((json: any[]) => {
  //     let urlDocCnpj = json[0]['url'];
  //     let urlDocCs = json[1]['url'];
  //     let urlDocTermo = json[2]['url'];

  //     let params = {
  //       pessoa: {
  //         primeiro_nome: pessoa.primeiro_nome,
  //         meio_nome: pessoa.meio_nome,
  //         ultimo_nome: pessoa.ultimo_nome,
  //         tipo: pessoa.tipo,
  //         email: pf.email,
  //         telefone: pf.telefone,
  //         negociou: this.selectedAtivoDigital.key,
  //         crypto: this.selectedCrypto.key,
  //         valor: this.selectedCrypto.key,
  //       },
  //       pessoa_fisica: {
  //         cpf: pf.cpf
  //       },
  //       pessoa_juridica: {
  //         cnpj: pj.cnpj
  //       },
  //       endereco: {
  //         cep: pf.cep,
  //         endereco: pf.endereco,
  //         estado: pf.estado,
  //         cidade: pf.cidade,
  //       },
  //       como_conheceu: {
  //         onde: this.selectedLugar.key,
  //         comentario: this.comentario,
  //       },
  //       urlDocCnpj: urlDocCnpj,
  //       urlDocCs: urlDocCs,
  //       urlDocTermo: urlDocTermo
  //     }

  //   })
  // }

  // formularioAmbos() {
  //   let pessoa = this.form.get('dados_pessoais').value
  //   let pf = this.form.get('pf').value
  //   let pj = this.form.get('pj').value

  //   let requests = [];

  //   let formData: FormData = new FormData();

  //   this.docFrente.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);
  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData).subscribe)

  //   formData = new FormData();

  //   this.docVerso.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));


  //   formData = new FormData();

  //   this.docTermo.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));

  //   this.docCnpj.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);
  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData).subscribe)

  //   formData = new FormData();

  //   this.docCs.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));


  //   formData = new FormData();

  //   this.docTermo.map(item => {
  //     let file = item;
  //     formData.append('myFile', file);

  //     // let url: any;

  //     // var reader = new FileReader();



  //     // reader.readAsDataURL(file);

  //     // reader.onload = (_event) => {
  //     //   url = reader.result;

  //     //   file.caption = item.caption;

  //     //   formData.append('myFile', file);
  //     // }
  //   });

  //   formData.append('bucket', "4sells-storage");
  //   requests.push(this._pessoaService.upload_s3(formData));

  //   forkJoin(requests).subscribe((json: any[]) => {
  //     let urlDocFrente = json[0]['url'];
  //     let urlDocVerso = json[1]['url'];
  //     let urlDocCnpj = json[2]['url'];
  //     let urlDocCs = json[3]['url'];
  //     let urlDocTermo = json[4]['url'];

  //     let params = {
  //       pessoa: {
  //         primeiro_nome: pessoa.primeiro_nome,
  //         meio_nome: pessoa.meio_nome,
  //         ultimo_nome: pessoa.ultimo_nome,
  //         tipo: pessoa.tipo,
  //         email: pf.email,
  //         telefone: pf.telefone,
  //         negociou: this.selectedAtivoDigital.key,
  //         crypto: this.selectedCrypto.key,
  //         valor: this.selectedCrypto.key,
  //       },
  //       pessoa_fisica: {
  //         cpf: pf.cpf
  //       },
  //       pessoa_juridica: {
  //         cnpj: pj.cnpj
  //       },
  //       endereco: {
  //         cep: pf.cep,
  //         endereco: pf.endereco,
  //         estado: pf.estado,
  //         cidade: pf.cidade,
  //       },
  //       como_conheceu: {
  //         onde: this.selectedLugar.key,
  //         comentario: this.comentario,
  //       },
  //       urlDocFrente: urlDocFrente,
  //       urlDocVerso: urlDocVerso,
  //       urlDocCnpj: urlDocCnpj,
  //       urlDocCs: urlDocCs,
  //       urlDocTermo: urlDocTermo
  //     }

  //   })
  // }

  enviarFormulario() {
    this.loading = true
    this.sucesso = false
    let pessoa = this.form.get('dados_pessoais').value
    let pf = this.form.get('pf').value
    let pj = this.form.get('pj').value
    let urlDocFrente: any = null
    let urlDocVerso: any = null
    let urlDocCnpj: any = null
    let urlDocCs: any = null
    let urlDocTermo: any = null

    if (this.docFrente.length > 0) {
      let formData: FormData = new FormData();

      this.docFrente.map(item => {
        let file = item;

        let url: any;

        var reader = new FileReader();



        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          url = reader.result;

          file.caption = item.caption;

          formData.append('myFile', file);
        }
      });
      setTimeout(() => {
        formData.append('bucket', "4sells-storage");
        this._pessoaService.upload_s3(formData).subscribe(json => {
          urlDocFrente = json.url
        }, err => {
          alert("Erro ao enviar arquivos para o servidor")
        })

      }, 1000);
    }

    if (this.docVerso.length > 0) {
      let formData: FormData = new FormData();

      this.docVerso.map(item => {
        let file = item;

        let url: any;

        var reader = new FileReader();



        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          url = reader.result;

          file.caption = item.caption;

          formData.append('myFile', file);
        }
      });
      setTimeout(() => {
        formData.append('bucket', "4sells-storage");
        this._pessoaService.upload_s3(formData).subscribe(json => {
          urlDocVerso = json.url
        }, err => {
          alert("Erro ao enviar arquivos para o servidor")
        })

      }, 1000);
    }

    if (this.docCnpj.length > 0) {
      let formData: FormData = new FormData();

      this.docCnpj.map(item => {
        let file = item;

        let url: any;

        var reader = new FileReader();



        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          url = reader.result;

          file.caption = item.caption;

          formData.append('myFile', file);
        }
      });
      setTimeout(() => {
        formData.append('bucket', "4sells-storage");
        this._pessoaService.upload_s3(formData).subscribe(json => {
          urlDocCnpj = json.url
        }, err => {
          alert("Erro ao enviar arquivos para o servidor")
        })

      }, 1000);
    }

    if (this.docCs.length > 0) {
      let formData: FormData = new FormData();

      this.docCs.map(item => {
        let file = item;

        let url: any;

        var reader = new FileReader();



        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          url = reader.result;

          file.caption = item.caption;

          formData.append('myFile', file);
        }
      });
      setTimeout(() => {
        formData.append('bucket', "4sells-storage");
        this._pessoaService.upload_s3(formData).subscribe(json => {
          urlDocCs = json.url
        }, err => {
          alert("Erro ao enviar arquivos para o servidor")
        })

      }, 1000);
    }

    if (this.docTermo.length > 0) {
      let formData: FormData = new FormData();

      this.docTermo.map(item => {
        let file = item;

        let url: any;

        var reader = new FileReader();



        reader.readAsDataURL(file);

        reader.onload = (_event) => {
          url = reader.result;

          file.caption = item.caption;

          formData.append('myFile', file);
        }
      });
      setTimeout(() => {
        formData.append('bucket', "4sells-storage");
        this._pessoaService.upload_s3(formData).subscribe(json => {
          urlDocTermo = json.url
        }, err => {
          alert("Erro ao enviar arquivos para o servidor")
        })

      }, 1000);
    }

    setTimeout(() => {
      let crypto: any[] = []
      let resultCrypto: any
      if (this.selectedAtivoDigital.key == 'sim') {

        this.selectedCrypto.map(item => {
          crypto.push(item.name)
        })

        resultCrypto = crypto.toString()
      } else {
        resultCrypto = null
      }
      console.log(crypto)
      let params = {
        pessoa: {
          primeiro_nome: pessoa.primeiro_nome,
          meio_nome: pessoa.meio_nome,
          ultimo_nome: pessoa.ultimo_nome,
          tipo: pessoa.tipo,
          email: pf.email,
          telefone: pf.telefone,
          negociou: this.selectedAtivoDigital.key,
          crypto: resultCrypto,
          valor: this.selectedValor.key,
          tipo_documento_pessoal: pessoa.tipo_documento
        },
        pessoa_fisica: {
          cpf: pf.cpf
        },
        pessoa_juridica: {
          cnpj: pj.cnpj
        },
        endereco: {
          cep: pf.cep,
          endereco: pf.endereco,
          estado: pf.estado,
          cidade: pf.cidade,
          numero: pf.numero,
        },
        como_conheceu: {
          onde: this.selectedLugar.key,
          comentario: this.comentario,
        },
        urlDocFrente: urlDocFrente,
        urlDocVerso: urlDocVerso,
        urlDocCnpj: urlDocCnpj,
        urlDocCs: urlDocCs,
        urlDocTermo: urlDocTermo
      }
      this.loading = false
      this.sucesso = true

      this._pessoaService.createBiteasy(params).subscribe(json => {
        console.log(json)
      })

      console.log(params)
    }, 10000)



  }




  onUploadFrente(event) {

    for (let file of event.files) {
      this.docFrente.push(file);
    }


  }
  onUploadVerso(event) {
    for (let file of event.files) {
      this.docVerso.push(file);
    }

  }
  onUploadCnpj(event) {
    for (let file of event.files) {
      this.docCnpj.push(file);
    }
  }

  onUploadCs(event) {
    for (let file of event.files) {
      this.docCs.push(file);
    }
  }
  onUploadTermo(event) {
    for (let file of event.files) {
      this.docTermo.push(file);
    }
  }


}
