import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { AppMainComponent } from "./app.main.component";
import { ReservaService } from "./services/reserva.service";
import { UsuarioService } from "./services/usuario.service";

@Component({
    selector: "app-topbar",
    template: `
        <div class="topbar clearfix">
            <div class="topbar-left">
                <a routerLink="/">
                    <img
                        src="assets/layout/images/logo.png"
                        class="topbar-logo"
                        routerLink="/"
                    />
                </a>
            </div>

            <div class="topbar-right">
                <a
                    id="menu-button"
                    href="#"
                    (click)="app.onMenuButtonClick($event)"
                    [ngClass]="{ 'menu-button-rotate': app.rotateMenuButton }"
                >
                    <i class="pi pi-angle-left"></i>
                </a>

                <a
                    id="topbar-menu-button"
                    href="#"
                    (click)="app.onTopbarMenuButtonClick($event)"
                >
                    <i class="pi pi-bars"></i>
                </a>

                <ul
                    class="topbar-items fadeInDown"
                    [ngClass]="{ 'topbar-items-visible': app.topbarMenuActive }"
                >
                    <li
                        #profile
                        class="profile-item"
                        *ngIf="app.profileMode === 'top' || app.isHorizontal()"
                        [ngClass]="{
                            'active-top-menu': app.activeTopbarItem === profile
                        }"
                    >
                        <a
                            href="#"
                            (click)="app.onTopbarItemClick($event, profile)"
                        >
                            <div>
                                <div
                                    style="display:inline-block;vertical-align:top;"
                                >
                                    <img
                                        class="profile-image"
                                        src="assets/layout/images/perfil.png"
                                    />
                                </div>
                                <div style="display:inline-block;">
                                    <span class="topbar-item-name">{{
                                        user.name
                                    }}</span>
                                    <span class="topbar-item-role">{{
                                        user.email
                                    }}</span>
                                </div>
                            </div>
                        </a>

                        <ul class="layout-menu fadeInDown">
                            <li role="menuitem">
                                <a href="#" (click)="logout()">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Sair do sistema</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <!-- <li
                        #notifications
                        [ngClass]="{
                            'active-top-menu':
                                app.activeTopbarItem === notifications
                        }"
                    >
                        <a
                            href="#"
                            (click)="
                                app.onTopbarItemClick($event, notifications)
                            "
                        >
                            <i class="topbar-icon pi pi-fw pi-bell"></i>
                            <span class="topbar-badge animated rubberBand">{{
                                notificacoes
                            }}</span>
                            <span class="topbar-item-name">Notificações</span>
                        </a>
                        <ul class="layout-menu fadeInDown">
                            <li
                                role="menuitem"
                                *ngFor="
                                    let item of array_notificacao;
                                    let i = index
                                "
                            >
                                <a (click)="link(item)">
                                    <span>Agendamento | {{ item.label }}</span>
                                </a>
                            </li>
                        </ul>
                    </li> -->
                </ul>
            </div>
        </div>
    `,
})
export class AppTopBarComponent {
    private subscription: Subscription = new Subscription();

    notificacoes: number = 0;

    user = JSON.parse(localStorage.getItem("user"));

    data: any;
    interval: any;

    array_notificacao: any[] = [];

    constructor(
        public app: AppMainComponent,
        protected _usuarioService: UsuarioService,
        private router: Router,
        protected _reservaService: ReservaService,
        private messageService: MessageService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
        clearInterval(this.interval);
        localStorage.clear();
    }

    logout() {
        setTimeout(() => {
            sessionStorage.clear();
            localStorage.clear();
            this.router.navigate(["/login"]);
        }, 1000);
    }
}
