import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campo-personalizado-pessoa',
  templateUrl: './campo-personalizado-pessoa.component.html',
  styleUrls: ['./campo-personalizado-pessoa.component.css']
})
export class CampoPersonalizadoPessoaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
