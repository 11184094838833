import { Component } from '@angular/core';
import { SettingsService } from './services/settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'

})
export class AppComponent {



    loading: any = localStorage.getItem('loading');

    constructor(public _settingsService: SettingsService) { }

    ngOnInit() {
    }

}
