
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { DashboardBankingComponent } from './demo/view/dashboardbanking.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppWizardComponent } from './pages/app.wizard.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';

import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppLoginComponent } from './pages/app.login.component';
import { DespesaReceitaComponent } from './web/common-modules/despesa-receita/despesa-receita.component';
import { FormaPagamentoComponent } from './web/common-modules/forma-pagamento/forma-pagamento.component';
import { PlanoContaComponent } from './web/common-modules/plano-conta/plano-conta.component';
import { AuthGuardService } from './guard/auth-guard.service';
import { PessoaComponent } from './web/common-modules/pessoa/pessoa.component';
import { CampoPersonalizadoPessoaComponent } from './web/common-modules/pessoa/campo-personalizado-pessoa/campo-personalizado-pessoa.component';
import { PlanosMundialCardComponent } from './web/common-modules/planos/planos.component';
import { ContratosComponent } from './web/common-modules/contratos/contratos.component';
import { AtendimentosComponent } from './web/mundialcard/atendimentos/atendimentos.component';
import { ConfiguracaoComponent } from './web/common-modules/configuracao/configuracao.component';
import { BitcoinComponent } from './web/common-modules/bitcoin/bitcoin.component';
import { AtivoDigitalComponent } from './web/cadastro/ativo-digital/ativo-digital.component';
import { ComprarAtivoDigitalComponent } from './web/comprar/comprar-ativo-digital/comprar-ativo-digital.component';
import { VenderAtivoDigitalComponent } from './web/vender/vender-ativo-digital/vender-ativo-digital.component';
import { FinanceiroComponent } from './web/common-modules/financeiro/financeiro.component';
import { AgendaComponent } from './web/common-modules/agenda/agenda.component';
import { ContaBancariaComponent } from './web/common-modules/conta-bancaria/conta-bancaria.component';
import { FinanceiroDashboardComponent } from './web/dashboard/financeiro-dashboard/financeiro-dashboard.component';
import { MovimentacaoBancariaComponent } from './web/common-modules/movimentacao-bancaria/movimentacao-bancaria.component';
import { CarteirinhaComponent } from './web/mundialcard/carteirinha/carteirinha.component';
import { UploadComponent } from './web/alis/upload/upload.component';
import { RelatorioPageComponent } from './web/common-modules/relatorios/relatorio-page/relatorio-page.component';
import { CentroCustoComponent } from './web/common-modules/centro-custo/centro-custo.component';
import { PedidoVendaComponent } from './web/common-modules/pedido-venda/pedido-venda.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivate: [AuthGuardService],
                children: [
                    { path: '', component: FinanceiroDashboardComponent },
                    { path: 'dashboards/generic', component: DashboardDemoComponent },
                    { path: 'dashboards/dashboard_banking', component: DashboardBankingComponent },
                    { path: 'atendimento/dashboard', component: DashboardBankingComponent },
                    { path: 'relatorio', component: RelatorioPageComponent },

                    {
                        path: 'financeiro', children: [
                            // { path: 'despesa', component: DespesaReceitaComponent },
                            // { path: 'receita', component: DespesaReceitaComponent },
                            { path: 'dashboard', component: FinanceiroDashboardComponent },
                            { path: 'page', component: FinanceiroComponent },
                            { path: 'balanco', component: DespesaReceitaComponent },
                            { path: 'forma-pagamento', component: FormaPagamentoComponent },
                            { path: 'plano-conta', component: PlanoContaComponent },
                            // { path: 'pessoa/cliente', component: PessoaComponent },
                            // { path: 'pessoa/fornecedor', component: PessoaComponent },
                            { path: 'pessoa/campos-customizados', component: CampoPersonalizadoPessoaComponent },
                            { path: 'contratos', component: ContratosComponent },
                            { path: 'pessoas', component: PessoaComponent },
                            { path: 'configuracoes', component: ConfiguracaoComponent },
                            { path: 'contas', component: ContaBancariaComponent },
                            { path: 'movimentacao-bancaria', component: MovimentacaoBancariaComponent },
                            { path: 'centro-custo', component: CentroCustoComponent },
                            { path: 'pedido', component: PedidoVendaComponent },
                        ]
                    },
                    {
                        path: 'cadastro', children: [
                            { path: 'ativo-digital', component: AtivoDigitalComponent },

                        ]
                    },
                    {
                        path: 'comprar', children: [
                            { path: 'ativo-digital', component: ComprarAtivoDigitalComponent },

                        ]
                    },
                    {
                        path: 'vender', children: [
                            { path: 'ativo-digital', component: VenderAtivoDigitalComponent },

                        ]
                    },
                    { path: 'planos', component: PlanosMundialCardComponent },
                    { path: 'atendimentos', component: AtendimentosComponent },
                    { path: 'agenda', component: AgendaComponent },
                ]
            },

            { path: 'bitcoin', component: BitcoinComponent },
            { path: 'carteirinha-mundialcard/:id', component: CarteirinhaComponent },
            { path: 'error', component: AppErrorComponent },
            { path: 'accessdenied', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: 'wizard', component: AppWizardComponent },
            { path: 'upload', component: UploadComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
