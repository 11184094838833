import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fy-grid',
  templateUrl: './fy-grid.component.html',
  styleUrls: ['./fy-grid.component.scss']
})
export class FyGridComponent implements OnInit {
  @Input() legend: string;
  @Input() values: any;
  @Input() cols: any;
  @Input() exportColumns: any;
  @Input() globalFilterFields: any;


  @Input() buttonDelete: boolean = false;
  @Input() buttonChange: boolean = false;
  @Input() buttonImagem: boolean = false;
  @Input() buttonDownload: boolean = false;
  @Input() buttonDetail: boolean = false;
  @Input() buttonEmail: boolean = false;
  @Input() buttonRemove: boolean = false;
  @Input() buttonNew: boolean = true;
  @Input() buttonCalendar: boolean = false;
  @Input() buttonExport: boolean = false;
  @Input() buttonsExport: boolean = false;
  @Input() buttonEdit: boolean = true;
  @Input() buttonSearch: boolean = true;
  @Input() header: boolean = true;
  @Input() slotButtons: boolean = false;
  @Input() slotButtonEditar: boolean = false;
  @Input() paginator: boolean = true;
  @Input() rows: number = 10;


  msgRegistros: any;



  @Output() openNew = new EventEmitter<any>();
  @Output() openCalendar = new EventEmitter<any>();
  @Output() change = new EventEmitter<{ id: any }>();
  @Output() edit = new EventEmitter<{ id: any }>();
  @Output() delete = new EventEmitter<{ id: any }>();
  @Output() detail = new EventEmitter<{ id: any }>();
  @Output() download = new EventEmitter<{ id: any }>();
  @Output() email = new EventEmitter<{ id: any }>();
  @Output() image = new EventEmitter<{ id: any }>();

  constructor() { }

  ngOnInit(): void {
    // this.msgRegistros = this.values.length > 1 ? 'Registros' : 'Registro';
  }



  onEditEvent(id) {
    this.edit.emit({ id });
  }

  onDeleteEvent(id) {
    this.delete.emit({ id });
  }

  onChangeEvent(id) {
    this.change.emit({ id });
  }

  onDownloadEvent(id) {
    this.download.emit({ id });
  }

  onDetailEvent(id) {
    this.detail.emit({ id });
  }

  onEmailEvent(id) {
    this.email.emit({ id });
  }

  onImageEvent(id) {
    this.image.emit({ id });
  }


  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.values);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "cursos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }



}
