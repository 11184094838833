import { Component, OnInit } from "@angular/core";
import { PessoaService } from "../../../services/pessoa.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { CampoCustomizadoService } from "src/app/services/campo-customizado.service";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { ArquivoService } from "src/app/services/arquivo.service";
import { ImportacaoService } from "src/app/services/importacao.service";
import { FormaPagamentoService } from "src/app/services/forma-pagamento.service";
import { PessoaEspecialidadeService } from "src/app/services/pessoa-especialidade.service";
import { ConfirmationService } from "primeng/api";
import { ContaService } from "src/app/services/conta.service";
import { PlanoService } from "src/app/services/plano.service";

@Component({
    selector: "app-pessoa",
    templateUrl: "./pessoa.component.html",
    styleUrls: ["./pessoa.component.scss"],
})
export class PessoaComponent implements OnInit {
    personList: any[] = [];
    array_dependentes: any[] = [];
    array_coberturas: any[] = [];

    fornecedorList: any[] = [];

    clientesList: any[] = [];

    uploadFile;

    uploadFileDependentes;

    configuracao = JSON.parse(localStorage.getItem("configuracao"));

    formCad: FormGroup;

    totalRecords: number = 0;

    displayModal: boolean = false;

    dropDownTag: any[] = [];

    colsConvenio: any[] = [
        { field: "codigo_contrato", header: "Código contrato" },
        { field: "titular_nome", header: "Titular do contrato" },
        { field: "titular_dependente", header: "Responsavel" },
        { field: "codigo_plano", header: "Código plano" },
        { field: "nome", header: "Nome" },
        { field: "situacao", header: "Situação" },
    ];

    dropdownGrauParentesco: any[] = [
        { value: 1, label: "Cônjugue" },
        { value: 2, label: "Filhos" },
        { value: 3, label: "Pai e mãe" },
        { value: 4, label: "Outros" },
    ];


    valueConvenio: any[] = [];

    nomeFiltro: any;

    plano_selecionado: any

    abaActive = 0;

    clinicList = [];

    totalRecordsClinic = 0;

    pt: any = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    colsImportacao = [
        { field: "id", header: "ID" },
        { field: "nome", header: "Nome" },
        { field: "created_at", header: "Data da importação" },
    ];

    cols: any[] = [
        { field: "id", header: "ID" },
        { field: "nome", header: "Nome" },
        { field: "celular_principal", header: "Celular" },
        { field: "created_at", header: "Data cadastro" },
    ];

    colsClinic: any[] = [
        { field: "id", header: "ID", type: "string" },
        { field: "nome", header: "Nome", type: "string" },
        { field: "celular_principal", header: "Celular", type: "string" },
        { field: "especialidade", header: "Especialidade", type: "string" },
        { field: "especialidade_valor", header: "valor", type: "currency" },
        { field: "created_at", header: "Data cadastro", type: "date" },
    ];

    colsAccredited: any[] = [
        { field: "id", header: "ID", type: "string" },
        { field: "nome", header: "Nome", type: "string" },
        { field: "celular_principal", header: "Celular", type: "string" },
        { field: "especialidade", header: "Especialidade", type: "string" },
        { field: "especialidade_valor", header: "valor", type: "currency" },
        { field: "created_at", header: "Data cadastro", type: "date" },
    ];

    colsFilesTable = [
        { field: "nome", header: "Nome" },
        { field: "tipo", header: "Tipo" },
    ];

    dropDownTipo: any[] = [
        { value: "FISICA", label: "Pessoa física" },
        { value: "JURIDICA", label: "Pessoa jurídica" },
    ];

    dropDownTipoFiltro: any[] = [
        { value: null, label: "Todos" },
        { value: "FISICA", label: "Pessoa física" },
        { value: "JURIDICA", label: "Pessoa jurídica" },
    ];

    clientesBkChat = false;

    dropDownValoresCripto: any[] = [
        { value: "0", label: " De R$  1,00 a R$1.000,00" },
        { value: "1", label: " De R$  1.000,00 a R$10.000,00" },
        { value: "2", label: " De R$  10.000,00 a R$50.000,00" },
        { value: "3", label: " Acima de R$50.000,00" },
    ];

    importacaoList = [];

    customFields: any[] = [];

    typeForm = "cliente";

    colsImportacaoDialog = [];

    importacaoDialogList = [];

    modalImportacao: boolean = false;

    formImportacao: FormGroup;

    usuario: any = JSON.parse(localStorage.getItem("user"));

    colsDependentes = [
        { field: "nome", header: "Nome" },
        { field: "cpf", header: "CPF" },
        { field: "nascimento", header: "Nascimento" },
    ];

    dependentesList = [];

    files = [];

    typeFile;

    formCobranca: FormGroup;

    dropdownTypeFiles = [
        { value: "RG", label: "RG" },
        { value: "CPF", label: "CPF" },
        { value: "CNH", label: "CNH" },
        { value: "CERTIDAO_NASCIMENTO", label: "Certidão de nascimento" },
        { value: "COMPROVANTE_RESIDENCIA", label: "Comprovante de residencia" },
        { value: "EXTRATO_BANCARIO", label: "Extrato bancário" },
        { value: "CONTRATO_SOCIAL", label: "Contrato Social" },
        { value: "CARTAO_CNPJ", label: "Cartão CNPJ" },
        { value: "TERMO_RESPONSABILIDADE", label: "Termo de responsabilidade" },
        { value: "CONTRATO_MUNDIAL-OUTROS", label: "Contrato Mundial/ Outros" },
    ];

    dropDownCriptNegociada = [
        { value: 0, label: "Não" },
        { value: 1, label: "Sim" },
    ];

    filesGrid = [];

    dropdownFormaPagamento = [];

    accreditedList: any[] = [];

    subdominio = localStorage.getItem("subdominio");

    totalRecordsCredenciados = 0;

    dropdownEspecialidades = [];

    dropdownEspecialidadesFiltro = [];

    modalEspecialidade = false;

    formEspecialidade: FormGroup;

    especialidadeFiltro = null;

    especialidadeFiltroClinica = null;

    tipoPessoaFiltro = null;

    sellerList = [];

    totalRecordsVendedores = 0;

    dropdownTipoComissao = [
        { value: "0", label: "Percentual" },
        { value: "1", label: "Monetário" },
    ];

    cpfCnpjFiltro;

    especialidadesAdd = [];

    dependentesArquivo = [];

    dropdownBanco = [];

    dropdownTipoConta = [
        { value: 'POUPANCA', label: 'Poupança' },
        { value: 'CORRENTE', label: 'Corrente' },
        { value: 'SALARIO', label: 'Salário' },
    ]

    showCoberturaPlano = false;

    constructor(
        private pessoaService: PessoaService,
        private _formBuilder: FormBuilder,
        private campoPersonalizadoService: CampoCustomizadoService,
        private router: Router,
        private http: HttpClient,
        private arquivoService: ArquivoService,
        private importacaoService: ImportacaoService,
        private formaPagamentoService: FormaPagamentoService,
        private pessoaEspecialidade: PessoaEspecialidadeService,
        private confirmationService: ConfirmationService,
        private contaService: ContaService,
        private planoService: PlanoService,
    ) { }

    ngOnInit(): void {
        this.loadLazyClient();
        this.createForm();
        this.listCustomFields();
        this.listFormaPagamento();
        this.listEspecialidades();

        this.formImportacao = this._formBuilder.group({
            id: [null],
            url_arquivo: [null],
            nome_arquivo: [null],
            usuario_id: [null],
            arquivo_id: [null],
            tipo: [null],
        });

        this.formEspecialidade = this._formBuilder.group({
            id: [null],
            nome: [null],
        });

        this.formEspecialidade = this._formBuilder.group({
            id: [null],
            nome: [null],
        });

        this.getBancos();
    }

    onUpload(event) {
        this.uploadFile = event;
    }


    styleGridDependentes(dependente) {
        if (dependente["ativo"]) {
            return { "background-color": "#bcffbc" };
        } else {
            return { "background-color": "#eda5ac" };
        }
    }

    addCobertura() {
        this.array_coberturas.push({
            descricao: null
        })
    }

    removeCobertura(item) {
        let arr = this.array_coberturas.filter((e) => {
            return e != item;
        });
        this.array_coberturas = arr;
    }


    addFileGrid() {
        this.files.push({
            file: this.uploadFile,
            nome: this.uploadFile.name,
            tipo: this.typeFile,
        });

        this.filesGrid = [
            ...this.filesGrid,
            { nome: this.uploadFile.name, tipo: this.typeFile },
        ];

        this.uploadFile = undefined;
    }

    showModalEspecialidade() {
        this.modalEspecialidade = true;
    }

    createForm() {
        this.formCad = this._formBuilder.group({
            pessoa: this._formBuilder.group({
                id: [null],
                ativo: [true],
                nome: [null, Validators.required],
                tipo: [this.dropDownTipo[0]["value"]],
                telefone_principal: [null],
                celular_principal: [null],
                email_principal: [null],
                cliente: [true],
                funcionario: [null],
                fornecedor: [null],
                credenciado: [null],
                codigo_integracao: [null],
                payload: [null],
                vendedor: [null],
                tipo_comissao: [null],
                comissao: [null],
                clinica: [null],
                numero_carteirinha_plano: [null],
                considerar_contato_empresa_contrato: [null],
                crypto_negociada: [null],
                crypto_moeda: [null],
                crypto_valor: [null],
                dependente_contrato: [null],
                observacao: [null],
            }),
            fisica: this._formBuilder.group({
                nascimento: [null],
                apelido: [null],
                cpf: [null],
                rg: [null],
            }),
            juridica: this._formBuilder.group({
                razao_social: [null],
                cnpj: [null],
                ie: [null],
                nome_contato: [null],
                celular_contato: [null],
                telefone_contato: [null],
            }),
            endereco: this._formBuilder.group({
                cep: [null],
                logradouro: [null],
                bairro: [null],
                municipio: [null],
                complemento: [null],
                uf: [null],
                numero: [null],
            }),
            tag: this._formBuilder.group({
                tags: ([] = []),
            }),
            especialidades: this._formBuilder.group({
                especialidade: [null],
                valor: [null],
            }),
            dadosBancario: this._formBuilder.group({
                contas: this._formBuilder.array([])
            }),
        });

        this.formCobranca = this._formBuilder.group({
            dia_faturamento: [null],
            dia_vencimento: [null],
            forma_pagamento_id: [null],
            enviar_cobranca_whatsapp: [null],
            enviar_cobranca_email: [null],
        });
    }

    get contas(): FormArray {
        return this.formCad.get('dadosBancario.contas') as FormArray;
    }

    addConta(): void {
        this.contas.push(this._formBuilder.group({
            banco_id: ['', Validators.required],
            agencia: ['', Validators.required],
            conta: ['', Validators.required],
            digito_conta: ['', Validators.required],
            tipo_conta: ['', Validators.required],
            pix: [''],
        }));
    }

    detailConvenio(event) {
        this.plano_selecionado = event.nome
        this.planoService.show(event.codigo_plano).subscribe(plano => {
            plano.plano_cobertura.map(item => {
                this.array_coberturas.push({
                    descricao: item.descricao
                })
            });

            this.showCoberturaPlano = true;
        })
    }

    voltarGridConvenio() {
        this.array_coberturas = []
        this.showCoberturaPlano = false;
    }

    getBancos() {
        this.contaService.getBancos().subscribe(json => {
            this.dropdownBanco = json.map(item => { return { value: item.id, label: item.nome } });
        })
    }

    checkContatoEmpresa() {
        if (
            this.formCad.get("pessoa.considerar_contato_empresa_contrato")
                .value == true
        ) {
            this.formCad.get("pessoa.celular_principal").setValue(null);
            this.formCad.get("pessoa.celular_principal").disable();
        } else {
            this.formCad.get("pessoa.celular_principal").enable();
        }
    }

    listFormaPagamento() {
        this.formaPagamentoService.index().subscribe((json) => {
            this.dropdownFormaPagamento = json.map((item) => {
                return { value: item.id, label: item.descricao };
            });
        });
    }

    listCustomFields() {
        this.customFields.push(
            {
                tipo: "STRING",
                field: "localDeTrabalho",
                label: "Local de trabalho",
                value: null,
            },
            {
                tipo: "STRING",
                field: "cargo",
                label: "Cargo",
                value: null,
            }
        );
        // let request = 'localidade=/financeiro/pessoa/cliente';
        // this.campoPersonalizadoService.index(request).subscribe(customFields => {
        //   customFields.map(field => {
        //     this.customFields.push({
        //       tipo: field.tipo,
        //       label: field.campo,
        //       value: null
        //     })
        //   });
        // })
    }


    saveDependente(event) {
        let arr = this.array_dependentes.find((x) => {
            return x == event;
        });
        arr.loading_edit = true;
        let body = {
            pessoa: {
                nome: event.nome,
                celular_principal: event.celular_principal,
            },
            pessoaFisica: {
                cpf: event.cpf,
                nascimento: event.nascimento,
            }
        }

        this.pessoaService.updateDependentePessoa(body, event.id).subscribe(json => {
            console.log(json)

            arr.loading_edit = false;
        }, err => {
            arr.loading_edit = false;
        })
    }

    patchForm(json) {
        this.formCad.enable();

        if (json.crypto_valor != null) {
            this.formCad
                .get("pessoa.crypto_valor")
                .setValue(json.crypto_valor);
        }

        if (json.celular_principal) {
            if (json.celular_principal.length == 14) {
                json.celular_principal = "+55" + json.celular_principal;
            }
        }

        if (json.telefone_principal) {
            if (json.telefone_principal.length == 13) {
                json.telefone_principal =
                    "+55" + json.telefone_principal;
            }
        }

        this.formCad.get("pessoa")?.patchValue(json);

        if (json.contas.length > 0) {
            json.contas.forEach(conta => {
                this.contas.push(this._formBuilder.group({
                    banco_id: [conta.banco_id],
                    agencia: [conta.agencia],
                    conta: [conta.conta],
                    digito_conta: [conta.digito_conta],
                    tipo_conta: [conta.tipo_conta],
                    pix: [conta.pix],
                }));
            });
        }

        try {
            this.formCobranca.patchValue(json?.cobranca);
        } catch (error) { }

        if (json["fisica"]) {
            if (json["fisica"]["nascimento"]) {
                let nascimento = moment(json["fisica"]["nascimento"])
                    .utc(false)
                    .format("DD/MM/YYYY");
                json["fisica"]["nascimento"] = nascimento;
            }

            this.formCad.get("fisica")?.patchValue(json["fisica"]);
        }

        if (json["juridica"]) {
            this.formCad.get("juridica")?.patchValue(json["juridica"]);
        }

        if (json["endereco"]) {
            this.formCad.get("endereco")?.patchValue(json["endereco"]);
        }

        if (json["payload"] != null) {
            let payload = JSON.parse(json["payload"]);
            this.customFields.map((item) => {
                item.value = payload[item.field] || null;
            });
        }

        if (json["arquivos"] != null) {
            json.arquivos.map((item) => {
                this.filesGrid.push({
                    download:
                        "https://4sells-storage.s3.sa-east-1.amazonaws.com/arquivos-chats/" +
                        item.arquivo.path,
                    nome: item.arquivo.original_name,
                    tipo: item.tipo,
                });
            });
        }

        if (json["especialidades"] != null) {
            json.especialidades.map((item) => {
                this.especialidadesAdd.push({
                    id: item.especialidade.id,
                    nome: item.especialidade.nome,
                    valor: item.valor,
                });
            });
        }


        if (this.typeForm == "cliente") {
            this.formCad.get("pessoa.cliente").setValue(true);
        } else if (this.typeForm == "fornecedor") {
            this.formCad.get("pessoa.fornecedor").setValue(true);
        } else if (this.typeForm == "credenciado") {
            this.formCad.get("pessoa.credenciado").setValue(true);
        } else if (this.typeForm == "clinica") {
            this.formCad.get("pessoa.clinica").setValue(true);
        } else if (this.typeForm == 'colaborador') {
            this.formCad.get('pessoa.vendedor').setValue(true);
        }
    }

    resetForm(type) {
        this.typeForm = type;

        console.log('typeForm', this.typeForm);

        this.filesGrid = [];

        this.especialidadesAdd = [];

        this.uploadFile = undefined;

        this.customFields.map((item) => {
            item.value = null;
        });

        this.formCad.reset();

        this.formCobranca.reset();

        this.formCad.get("pessoa.ativo").setValue(true);
        this.formCad.get("pessoa.dependente_contrato").setValue(false);
        this.formCad
            .get("pessoa.considerar_contato_empresa_contrato")
            .setValue(false);

        if (this.typeForm == "credenciado") {
            this.formCad.get("pessoa.vendedor").setValue(true);
            this.formCad.get("pessoa.tipo_comissao").setValue("0");
        }

        if (this.typeForm == "clinica") {
            this.formCad.get("pessoa.tipo").setValue("JURIDICA");
        } else {
            this.formCad.get("pessoa.tipo").setValue("FISICA");
        }
    }

    showDialogForm(person = null, type) {
        this.resetForm(type);

        if (person != null) {
            this.array_dependentes = []
            this.array_coberturas = []
            this.pessoaService.show(person.id).subscribe((jsonPessoa: any) => {

                if (jsonPessoa.tipo == 'FISICA') {
                    this.pessoaService.listDependents(person.id).subscribe(json => {

                        if (json.length > 0) {
                            json.map(item => {
                                let nascimento: any = null;

                                let cpf: any = null;

                                if (item.dependente["fisica"]) {

                                    if (item.dependente["fisica"]["nascimento"]) {
                                        nascimento = moment(item.dependente["fisica"]["nascimento"])
                                            .utc(false)
                                            .format("DD/MM/YYYY");
                                    }

                                    if (item.dependente["fisica"]["cpf"]) {
                                        cpf = item.dependente["fisica"]["cpf"]
                                    }

                                }

                                this.array_dependentes.push({
                                    loading_edit: false,
                                    id: item.dependente.id,
                                    nome: item.dependente.nome,
                                    cpf: cpf,
                                    nascimento: nascimento,
                                    celular_principal: item.dependente.celular_principal,
                                    parentesco: item.parentesco
                                });

                            })
                        }

                    });
                } else {
                    this.pessoaService.listDependentesFromTitular(jsonPessoa.id).subscribe(dependentes => {
                        if (dependentes.length > 0) {
                            dependentes.map(item => {
                                let nascimento: any = null

                                if (item.nascimento != null) {
                                    nascimento = moment(item.nascimento).utc(false).format("DD/MM/YYYY");
                                }

                                this.array_dependentes.push({
                                    loading_edit: false,
                                    id: item.id,
                                    nome: item.nome,
                                    cpf: item.cpf,
                                    nascimento: nascimento,
                                    celular_principal: item?.celular_principal,
                                });
                            })
                        }
                    })
                }

                this.patchForm(jsonPessoa);

                this.configurarTipoCadastro();



                this.pessoaService.getConvenio(person.id).subscribe(json => {
                    this.valueConvenio = json
                })
            });
        } else {
            this.configurarTipoCadastro();
        }

        this.displayModal = true;
    }

    configurarTipoCadastro() {
        this.formCad.get("pessoa.vendedor").setValue(false);
        this.formCad.get("pessoa.clinica").setValue(false);
        this.formCad.get("pessoa.credenciado").setValue(false);


        if (this.typeForm == "colaborador") {
            this.formCad.get("pessoa.vendedor").setValue(true);

            if (this.formCad.get("pessoa.tipo_comissao").value == null) {
                this.formCad.get("pessoa.tipo_comissao").setValue("0");
            }
        }

        if (this.typeForm == "clinica") {
            this.formCad.get("pessoa.clinica").setValue(true);

            if (this.formCad.get("pessoa.tipo").value == null) {
                this.formCad.get("pessoa.tipo").setValue("JURIDICA");
            }
        }

        if (this.typeForm == "credenciado") {
            this.formCad.get("pessoa.credenciado").setValue(true);

            if (this.formCad.get("pessoa.tipo").value == null) {
                this.formCad.get("pessoa.tipo").setValue("JURIDICA");
            }
        }
    }

    loadLazyClient(event = null) {
        this.clientesList = [];
        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let row = 100;

        body = {
            ...body,
            typeFilterPerson: "client",
            origem_bkchat: this.clientesBkChat,
        };

        if (this.nomeFiltro != undefined) {
            body = { ...body, nome: this.nomeFiltro };
        }

        if (this.cpfCnpjFiltro != undefined) {
            body = { ...body, cpfCnpj: this.cpfCnpjFiltro };
        }

        if (this.tipoPessoaFiltro != null) {
            body = { ...body, tipo: this.tipoPessoaFiltro };
        }

        this.pessoaService
            .filtroPaginado(page, body, row)
            .subscribe((pessoas) => {
                this.totalRecords = pessoas.total;

                this.clientesList = pessoas.data;
            });
    }

    loadLazyAccredited(event = null) {
        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let row = 100;

        body = { ...body, typeFilterPerson: "accredited" };

        if (this.nomeFiltro != undefined) {
            body = { ...body, nome: this.nomeFiltro };
        }

        if (this.especialidadeFiltro != undefined) {
            body = { ...body, especialidade: this.especialidadeFiltro };
        }

        this.pessoaService
            .filtroPaginado(page, body, row, event?.sortField, event?.sortOrder)
            .subscribe((pessoas) => {
                this.totalRecordsCredenciados = pessoas.total;

                this.accreditedList = pessoas.data;

                console.log("accreditedList", this.accreditedList);
            });
    }

    loadLazySupplier(event = null) {
        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let apelido: any;

        let row = 100;

        body = { ...body, typeFilterPerson: "supplier" };

        if (this.nomeFiltro != undefined) {
            body = { ...body, nome: this.nomeFiltro };
        }

        this.pessoaService
            .filtroPaginado(page, body, row)
            .subscribe((pessoas) => {
                this.totalRecords = pessoas.total;

                this.fornecedorList = pessoas.data;
            });
    }

    loadLazyClinic(event = null) {
        console.log(event);

        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let apelido: any;

        let row = 100;

        body = { ...body, typeFilterPerson: "clinic" };

        if (this.nomeFiltro != undefined) {
            body = { ...body, nome: this.nomeFiltro };
        }

        if (this.especialidadeFiltroClinica != undefined) {
            body = { ...body, especialidade: this.especialidadeFiltroClinica };
        }

        this.pessoaService
            .filtroPaginado(page, body, row, event?.sortField, event?.sortOrder)
            .subscribe((pessoas) => {
                this.totalRecordsClinic = pessoas.total;
                this.clinicList = pessoas.data;
            });
    }

    loadLazySeller(event = null) {
        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let apelido: any;

        let row = 100;

        body = { ...body, typeFilterPerson: "seller" };

        if (this.nomeFiltro != undefined) {
            body = { ...body, nome: this.nomeFiltro };
        }

        this.pessoaService
            .filtroPaginado(page, body, row)
            .subscribe((pessoas) => {
                this.totalRecordsVendedores = pessoas.total;

                this.sellerList = pessoas.data;
            });
    }

    changeAba(e) {
        console.log("ABA", e);
        this.abaActive = e?.index || e;

        if (this.abaActive == 0) {
            this.loadLazyClient();
        }

        if (this.abaActive == 1) {
            this.loadLazySupplier();
        }

        if (this.abaActive == 2) {
            this.loadLazyClinic();
        }

        if (this.abaActive == 3) {
            this.loadLazyAccredited();
        }

        if (this.abaActive == 4) {
            this.loadLazySeller();
        }
    }

    buscaCEP() {
        let cep = this.formCad.get("endereco.cep").value;

        try {
            cep = parseInt(cep.replace(/[^0-9]/g, ""));
        } catch (error) {
            cep = this.formCad.get("endereco.cep").value;
        }

        if (cep == null) {
            return;
        }

        this.http
            .get(`https://viacep.com.br/ws/${cep}/json`)
            .toPromise()
            .then((response) => {
                response["municipio"] = response["localidade"];
                response["cep"] = cep;
                this.formCad.get("endereco").patchValue(response);
            });
    }

    save() {
        let custom: any = {};
        this.customFields.map((field) => {
            custom[field.field] = field.value;
        });

        this.formCad.get("pessoa.payload").setValue(JSON.stringify(custom));

        let fisica = this.formCad.get("fisica")?.value;
        let nascimento = new Date(fisica.nascimento);
        fisica.nascimento = nascimento;

        let coberturas: any[] = []
        this.array_coberturas.map(item => {
            coberturas.push(
                item.descricao
            )
        })

        if (this.typeForm == "cliente") {
            this.formCad.get("pessoa.cliente").setValue(true);
        } else if (this.typeForm == "fornecedor") {
            this.formCad.get("pessoa.fornecedor").setValue(true);
        } else if (this.typeForm == "credenciado") {
            this.formCad.get("pessoa.credenciado").setValue(true);
        } else if (this.typeForm == "clinica") {
            this.formCad.get("pessoa.clinica").setValue(true);
        } else if (this.typeForm == 'colaborador') {
            this.formCad.get('pessoa.vendedor').setValue(true);
        }

        let body: any = {
            pessoa: this.formCad.get("pessoa")?.value,
            pessoaFisica: this.formCad.get("fisica")?.value,
            pessoaJuridica: this.formCad.get("juridica")?.value,
            endereco: this.formCad.get("endereco")?.value,
            cobranca: this.formCobranca.value,
            contas: this.formCad.get('dadosBancario.contas')?.value,
            coberturas: coberturas
        };

        console.log(body);

        if (this.typeForm == "credenciado" || this.typeForm == "clinica") {
            body = { ...body, especialidades: this.especialidadesAdd };
        }

        if (this.typeForm == "colaborador") {
            body = {
                ...body,
                tipo_comissao: this.formCad.get("pessoa.tipo_comissao")?.value,
                comissao: this.formCad.get("pessoa.comissao")?.value,
            };
        }

        this.pessoaService
            .save(body, this.formCad.get("pessoa.id")?.value)
            .subscribe((pessoa) => {
                this.files.map((file) => {
                    let formData = new FormData();
                    formData.append("myFile", file.file);
                    formData.append("tipo", file.tipo);
                    formData.append("id", pessoa.id);
                    this.pessoaService.uploadFile(formData).subscribe();
                });

                this.displayModal = false;
                this.changeAba(this.abaActive);
            });
    }

    handleFileInput(files, type) {
        let file = files[0];

        let formData1 = new FormData();
        formData1.append("myFile", file);
        this.arquivoService.upload_s3(formData1).subscribe((arquivos) => {
            let arquivo = arquivos[0];
            this.formImportacao.patchValue({
                arquivo_id: arquivo.id,
                usuario_id: this.usuario.id,
                tipo: type,
            });

            let formData = new FormData();

            formData.append("csv", file);

            this.arquivoService.read_csv(formData).subscribe((planilha) => {
                this.importacaoDialogList = planilha;

                let cols = [];

                this.importacaoDialogList.forEach((e, i) => {
                    const keys = Object.keys(e);

                    keys.map((key) => {
                        let col = cols.find((c) => {
                            return c.field == key;
                        });
                        if (col == undefined) {
                            cols.push({ field: key, header: key });
                        }
                    });
                });

                this.colsImportacaoDialog = cols;

                this.modalImportacao = true;
            });
        });
    }

    confirmImport() {
        let body = {
            importacao: this.formImportacao.value,
            pessoas: this.importacaoDialogList,
        };

        this.importacaoService.pessoas(body).subscribe((json) => {
            this.importacaoService.index().subscribe((importacoes) => {
                this.importacaoList = importacoes;
                this.modalImportacao = false;
            });
        });
    }

    listEspecialidades() {
        this.pessoaEspecialidade.index().subscribe((especialidades) => {
            this.dropdownEspecialidades = especialidades;

            this.dropdownEspecialidadesFiltro.push({
                value: null,
                label: "Todos",
            });

            especialidades.map(item => {
                this.dropdownEspecialidadesFiltro.push({ value: item.id, label: item.nome });
            });
        });
    }

    saveEspecialidade() {
        this.pessoaEspecialidade
            .save(
                this.formEspecialidade.value,
                this.formEspecialidade.get("id").value
            )
            .subscribe((json) => {
                this.listEspecialidades();
                this.modalEspecialidade = false;
            });
    }

    addEspecialidade() {
        let especialidades = this.formCad.get("especialidades").value;
        this.especialidadesAdd.push({
            id: especialidades.especialidade.id,
            nome: especialidades.especialidade.nome,
            valor: especialidades.valor,
        });
    }

    removeEspecialidade(item) {
        let arr = this.especialidadesAdd.filter((e) => {
            return e.id != item.id;
        });
        this.especialidadesAdd = arr;
    }


    delete(rowData, type) {
        this.confirmationService.confirm({
            message: "Você realmente deseja excluir este Registro ?",
            header: "Confirmação da ação",
            icon: "pi pi-info-circle",
            accept: () => {
                this.pessoaService.destroy(rowData.id).subscribe(json => {
                    if (type == 'client') {
                        this.loadLazyClient();
                    } else if (type == 'fornecedor') {
                        this.loadLazySupplier();
                    } else if (type == 'credenciado') {
                        this.loadLazyAccredited();
                    } else if (type == 'clinica') {
                        this.loadLazyClinic();
                    }
                })
            }
        });
    }

    getByDocument() {
        let document;
        let type = this.formCad.get('pessoa.tipo').value;
        if (type == 'FISICA') {
            document = this.formCad.get('fisica.cpf').value;
        } else {
            document = this.formCad.get('juridica.cnpj').value;
        }

        this.pessoaService.showByDocument(document, type).subscribe(pessoa => {
            this.showDialogForm(pessoa, this.typeForm);
        })
    }
}
