import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'arquivo');
  }

  upload(formData): Observable<any> {
    let url = environment.urlMultiUpload;
    return this.http.post(`${url}`, formData);
  }

  upload_s3(formData): Observable<any> {
    let url = environment.urlBase + '/api/arquivo/upload-file-simple';
    return this.http.post(`${url}`, formData);
  }

  multi(formData): Observable<any> {
    let url = environment.urlBase + '/api/multi/upload/files';
    return this.http.post(`${url}`, formData);
  }

  read_csv(formData): Observable<any> {
    let url = environment.urlBase + '/api/read-csv-file';
    return this.http.post(`${url}`, formData);
  }

  loadXLS(formData): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/load/data/xls`, formData);
  }

}
