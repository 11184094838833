import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class InsumoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'insumo');
  }

  listPurchases(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/crypto/purchases`);
  }

  listSales(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/crypto/sales`);
  }

  indexCripto(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/crypto/list`);
  }

  listByFornecedor(fornecedorId: number): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list-by-fornecedor/${fornecedorId}`);
  }

  storeCripto(params: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/crypto/save`, params);
  }

  updateCripto(id: number, params: any): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/crypto/save/${id}`, params);
  }

  storeBuyCripto(params: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/crypto/buy`, params);
  }

  storeSellCripto(params: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/crypto/sell`, params);
  }

}
