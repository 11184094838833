import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class AeronaveService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'aeronave');
    }

    listCotistas(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/cotistas`);
    }

    listAeronaves(): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/aeronaves`);
    }

    listCotistasAbertura(id): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/cotistas-abertura/` + id);
    }

    listPilotos(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/pilotos`);
    }

    listResponsaveis(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/responsaveis`);
    }

    listPatios(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/patios`);
    }

    listFabricantes(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/fabricantes`);
    }

    listModelos(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/modelos`);
    }

    listProprietarios(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/proprietarios`);
    }

    listOperadores(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/operadores`);
    }

    listMedidas(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/medidas`);
    }

    listSituacao(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/situacao`);
    }

    listHabilitacoes(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/habilitacoes`);
    }

    listImagens(veiculoId): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/imagens/` + veiculoId);
    }

    deleteCotista(id): Observable<any> {
        return this.http.delete(`${this.url_base}/${this.prefixo}/delete/cotista/${id}`);
    }

    deleteDocumento(id): Observable<any> {
        return this.http.delete(`${this.url_base}/${this.prefixo}/delete/documento/${id}`);
    }

    deletePiloto(id): Observable<any> {
        return this.http.delete(`${this.url_base}/${this.prefixo}/delete/piloto/${id}`);
    }

    storeCategoria(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/store/categoria`, params);
    }

    listCategorias(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/categorias`);
    }

    storeClasseAeronave(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/store/classe-aeronave`, params);
    }

    listClasses(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/classes`);
    }

    storeStatusOperacao(params): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/store/status-operacao`, params);
    }

    listStatusOperacao(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/status-operacao`);
    }

    listPassageiros(): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/passageiros`);
    }

    listAeronaveCotista(id): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/aeronave-cotista/${id}`);
    }

    listAeronaveSelecionada(id): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/aeronave-selecionada/${id}`);
    }

    listAeronaveCotistaWeb(id): Observable<any> {
        return this.http.get(`${this.url_base}/aeronave/list/aeronave-cotista-web/${id}`);
    }

    rabImport(data): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/rab-import`, data);
    }

    autoCompleteAeronave(param): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/auto-complete-aeronave/${param}`);
    }

    diarioFechado(idAeronave): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/get/ultimo/diario/fechado/${idAeronave}`);
    }

    pousos(idAeronave): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/pousos/count/${idAeronave}`);
    }

    trechos(idAeronave): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/trechos/count/${idAeronave}`);
    }

}
