import { CommonModule, } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
    selector: 'app-carteirinha',
    templateUrl: './carteirinha.component.html',
    styleUrls: ['./carteirinha.component.scss']
})
export class CarteirinhaComponent implements OnInit {
    @Input() data = {
        nome: null,
        dependentes: [],
        nascimento: null,
        cadastro: null,
        convenio: null
    };

    constructor(
        private route: ActivatedRoute,
        private pessoaService: PessoaService
    ) {

    }

    ngOnInit(): void {
        console.log('carteirinha')

        let id = this.route.snapshot.paramMap.get('id');

        this.pessoaService.show(id).subscribe(json => {
            this.data.cadastro = json.contrato?.created_at;
            this.data.dependentes = json.dependentes;
            this.data.convenio = json.contrato?.plano[0].nome;
            this.data.nome = json.nome;
            this.data.nascimento = json.fisica.nascimento;

            setTimeout(() => {
                // this.printComponent('container')
            }, 1000);
        });
    }

    printComponent(cmpName) {
        var conteudoParaImprimir = document.getElementById('container').outerHTML;

        // Abre uma nova janela para impressão
        var janelaImprimir = window.open('', '_blank');

        // Insere o conteúdo na nova janela
        janelaImprimir.document.write(conteudoParaImprimir);

        // Chama a função de impressão
        janelaImprimir.print();

        // Fecha a janela após a impressão
        janelaImprimir.close();
    }
}
