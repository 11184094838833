import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class DespesaReceitaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'despesa-receita');
  }

  receitas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/receitas`);
  }

  uploadFile(formData): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload/file`, formData);
  }

  despesas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/despesas`);
  }

  balanco(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/balanco`);
  }

  overdue(startDate, endDate, pessoaId = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/report/overdue/${startDate}/${endDate}`;

    if (pessoaId != null) {
      url += `?pessoaId=${pessoaId}`;
    }

    return this.http.get(url);
  }

  listPagamentos(tituloId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pagamentos/${tituloId}`);
  }

  savePayment(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/save/pagamentos`, params);
  }

  reversal(id): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/reversal/${id}`, null);
  }

  storeBalanco(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/balanco`, params);
  }

  updateBalanco(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/balanco/${id}`, params);
  }

  showBalanco(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/balanco/${id}`);
  }

  filtro(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/report/balanco/percentual`, params);
  }

  storeDespesaReceita(params): Observable<any> {
    return this.http.post(`${this.url_base}/despesa-receita`, params);
  }

  updateDespesaReceita(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/despesa-receita/${id}`, params);
  }

  processarBalanco(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/despesa-receita/processar/balanco/${id}`, params);
  }

  deletarBalanco(params, id): Observable<any> {
    return this.http.delete(`${this.url_base}/despesa-receita/deletar/balanco/${id}`, params);
  }

  getBoletoAsaas(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/boleto-asaas/${id}`);
  }

  getRecibo(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/recibo/${id}`);
  }

  removeCobrancaInLote(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/remove/lote`, body);
  }

  estornoCobrancaInLote(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/estorno/lote`, body);
  }

  listInvoicesPjPerDay(day): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/invoices/pj/${day}`);
  }

  sendInvoices(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/send/invoices/asaas`, body);
  }

  liquidate(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/liquidate`, body);
  }

  getBoleto(emissor, id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/boleto/${emissor}/${id}`);
  }
}
