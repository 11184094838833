import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CentroCustoService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'centro-custo');
    }

    tree(): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/arvore`);
      }

}
