import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { PessoaService } from './services/pessoa.service';

@Component({
    selector: 'app-menu',

    template: `
    <ul class="layout-menu layout-main-menu clearfix">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>
    `

    //     <div class="nav-side-menu" >
    //     <i class="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

    //         <div class="menu-list">

    //             <ul id="menu-content" class="menu-content collapse out">


    //                 <li>
    //                   <a routerLink="/dashboards/generic">
    //                   <i class="fa fa-dashboard fa-lg"></i> Dashboard
    //                   </a>
    //                 </li>

    //                 <li  data-toggle="collapse" data-target="#cadastro" class="collapsed active">
    //                   <a href="#"><i class="pi pi-user-edit"></i> Cadastro <span class="arrow"></span></a>
    //                 </li>
    //                  <ul class="sub-menu collapse" id="cadastro">
    //                     <li *ngIf="servico || varejo" ><a routerLink="/pessoa/cliente">Cliente</a></li>
    //                     <li *ngIf="servico || varejo" ><a routerLink="/pessoa/fornecedor">Fornecedor</a></li>
    //                     <li *ngIf="escola" ><a routerLink="/escola/aluno">Aluno</a></li>
    //                     <li *ngIf="escola" ><a routerLink="/escola/instrutores">Instrutor</a></li>
    //                     <li *ngIf="varejo" ><a routerLink="/cadastro/add-pdv">PDV</a></li>
    //                     <li *ngIf="escola" ><a routerLink="/escola/curso">Curso</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/licencas-habilitacoes">Licença / Habilitação</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/unidade-medida">Unidade de Medida</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/patio">Localização</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/fabricante">Fabricante</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/modelo">Modelo</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/pessoa">Pessoa</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/piloto">Piloto</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/aeronave">Aeronave</a></li>
    //                     <li *ngIf="sisfly" ><a routerLink="/sisfly/abertura-trecho">Voo</a></li>
    //                 </ul>

    //                 <li *ngIf="varejo" data-toggle="collapse" data-target="#suplemento" class="collapsed active">
    //                   <a href="#"><i class="fa fa-archive"></i> Suplemento <span class="arrow"></span></a>
    //                 </li>
    //                  <ul  class="sub-menu collapse" id="suplemento">
    //                     <li *ngIf="varejo" ><a routerLink="/suplemento/insumo">Insumo</a></li>
    //                     <li *ngIf="varejo" ><a routerLink="/suplemento/compra">Compra</a></li>
    //                 </ul>

    //                 <li *ngIf="varejo || servico" data-toggle="collapse" data-target="#venda" class="collapsed active">
    //                   <a href="#"><i class="pi pi-shopping-cart"></i> Venda/Atendimento <span class="arrow"></span></a>
    //                 </li>
    //                  <ul class="sub-menu collapse" id="venda">
    //                     <li *ngIf="varejo" ><a routerLink="/venda/balcao">Balcão</a></li>
    //                     <li *ngIf="servico || varejo" ><a routerLink="">Estimativa / Orçamento</a></li>
    //                     <li *ngIf="servico" ><a routerLink="">Ordem de serviço</a></li>
    //                 </ul>

    //                  <li  data-toggle="collapse" data-target="#financeiro" class="collapsed active">
    //                   <a href="#"><i class="fa fa-money"></i> Financeiro <span class="arrow"></span></a>
    //                 </li>
    //                  <ul class="sub-menu collapse" id="financeiro">
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/conta-bancaria">Conta Bancária</a></li>
    //                     <li *ngIf="servico || varejo" ><a routerLink="/financeiro/caixa">Caixa</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/banco">Banco</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/forma-pagamento">Forma de pagamento</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/plano-conta">Plano de conta</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/natureza-financeira">Natureza Financeira</a></li>
    //                     <li *ngIf="servico || varejo" ><a routerLink="/financeiro/orcamento">Orçamento</a></li>
    //                     <li><a routerLink="/financeiro/receita">Receita</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/despesa">Despesa</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/movimento-bancario">Movimento Bancário</a></li>
    //                     <li *ngIf="servico || varejo || sisfly" ><a routerLink="/financeiro/fatura-cartao">Fatura do cartão</a></li>
    //                     <li *ngIf="false"><a routerLink="/financeiro/fluxo-caixa">Fluxo de caixa</a></li>

    //                 </ul>

    //                 <li *ngIf="varejo" data-toggle="collapse" data-target="#relatorio" class="collapsed active">
    //                 <a href="#"><i class="pi pi-file-excel"></i> Relatório <span class="arrow"></span></a>
    //               </li>
    //                <ul class="sub-menu collapse" id="relatorio">
    //                <li *ngIf="escola" ><a routerLink="/escola/valores-curso">Curso</a></li>
    //               </ul>

    //                 <li  *ngIf="servico || varejo"  data-toggle="collapse" data-target="#configuracao" class="collapsed active">
    //                   <a href="#"><i class="fa fa-cog"></i> Configuração <span class="arrow"></span></a>
    //                 </li>
    //                  <ul  *ngIf="servico || varejo" class="sub-menu collapse" id="configuracao">
    //                     <li  ><a routerLink="/configuracao/contabil">Contábil</a></li>
    //                 </ul>

    //                 <li *ngIf="bksoft"  data-toggle="collapse" data-target="#software" class="collapsed active">
    //                   <a href="#"><i class="fa fa-archive"></i> Software <span class="arrow"></span></a>
    //                 </li>
    //                  <ul *ngIf="varejo"  class="sub-menu collapse" id="software">
    //                     <li><a routerLink="/software/banco-dados">Novo banco de dados</a></li>
    //                     <li><a routerLink="/software/liberar-licenca">Liberar licença</a></li>
    //                 </ul>
    //             </ul>
    //      </div>
    // </div>


})
export class AppMenuComponent implements OnInit {
    model: any[];
    modulos: any[];

    escola: boolean = true;
    varejo: boolean = false;
    financeiro: boolean = false;
    bksoft: boolean = false;
    suplemento: boolean = false;
    servico: boolean = false;
    sisfly: boolean = false;
    administrador: boolean = false;

    configuracao = JSON.parse(localStorage.getItem('configuracao'));

    user: any = JSON.parse(localStorage.getItem('user'));



    constructor(public app: AppMainComponent, public _pessoaService: PessoaService) { }

    ngOnInit() {

        this.escola = false;
        this.varejo = false;
        this.bksoft = false;
        this.suplemento = false;
        this.servico = false;
        this.sisfly = false;
        this.administrador = false;

        this.modulos = [];

        let itensDashboard = [];
        itensDashboard.push({ label: 'Financeiro', icon: 'fa fa-money', routerLink: ['/financeiro/dashboard'] });
        if (this.configuracao.modulo_atendimento == true) {
            itensDashboard.push({ label: 'Atendimentos', icon: 'fa fa-address-book', routerLink: ['/atendimento/dashboard'] });
        }

        this.model = [
            {
                label: 'Dashboard', icon: 'fa fa-bar-chart', items: itensDashboard
            },
            {
                label: 'Pessoas',
                icon: 'fa fas fa-user',
                routerLink: ['/financeiro/pessoas'],
                //   visible: this.showMenu('PESSOAS'),
            },
        ];

        let financeiroItens = [];

        financeiroItens.push({
            label: 'Conta Bancária',
            routerLink: ['/financeiro/contas'],
        });

        if (this._pessoaService.direitos('FORMA_PAGAMENTO') == true) {
            financeiroItens.push({
                label: 'Formas de pagamento',
                routerLink: ['/financeiro/forma-pagamento'],
            });
        }

        if (this._pessoaService.direitos('PLANO_CONTAS') == true) {

            financeiroItens.push({
                label: 'Plano de contas',
                routerLink: ['/financeiro/plano-conta'],
            })
        }

        financeiroItens.push({
            label: 'Centros de custo',
            routerLink: ['/financeiro/centro-custo'],
        });

        
        financeiroItens.push({
            label: 'Lançamentos',
            routerLink: ['/financeiro/page'],
        });

        financeiroItens.push({
            label: 'Movimentação Bancária',
            routerLink: ['/financeiro/movimentacao-bancaria'],
        });

        this.model = [...this.model, {
            label: 'Financeiro',
            icon: 'fa fa-money',
            items: financeiroItens
        }];

        if (this._pessoaService.direitos('CONTRATOS') == true && this.configuracao.modulo_contrato == true) {
            this.model = [...this.model, {
                label: 'Contratos',
                icon: 'fa fa-pencil',
                routerLink: ['/financeiro/contratos'],
            }];
        }

        if (this.configuracao.modulo_pedido_venda == true) {
            this.model = [...this.model, {
                label: 'Pedidos', icon: 'pi pi-shopping-cart', routerLink: ['/financeiro/pedido']
            },]
        }

        if (this.configuracao.modulo_agenda == true) {
            this.model = [...this.model, {
                label: 'Agenda',
                icon: 'fa fas fa-calendar',
                routerLink: ['/agenda'],
                //   visible: this.showMenu('PESSOAS'),
            }]
        }



        if (this.configuracao.modulo_atendimento == true) {
            var menu = {
                label: 'Atendimentos',
                icon: 'pi pi-id-card',
                routerLink: ['/atendimentos'],
            }

            this.model = [...this.model, menu];
        }

        if (this.configuracao.modulo_clinica == true) {
            var menu = {
                label: 'Planos',
                icon: 'pi pi-folder-open',
                routerLink: ['/planos'],
            }

            this.model = [...this.model, menu];
        }

        if (this.configuracao.modulo_cripto == true) {
            this.model = [...this.model,
            {
                "label": "Ativo digital",
                "icon": "fa fa-btc",
                "route": "cadastro/ativo-digital",
                "dir_flag": "ATIVO-DIGITAL"
            },
            {
                "label": "Comprar ativo digital",
                "icon": "fa fa-btc",
                "route": "comprar/ativo-digital",
                "dir_flag": "COMPRAR-ATIVO-DIGITAL"
            },
            {
                "label": "Vender ativo digital",
                "icon": "fa fa-btc",
                "route": "vender/ativo-digital",
                "dir_flag": "VENDER-ATIVO-DIGITAL"
            }
            ];
        }


        this.model = [...this.model, {
            label: 'Relatórios', icon: 'fa fa-bar-chart', routerLink: ['/relatorio']
        },]

        this.model = [...this.model, {
            label: 'Configurações',
            icon: 'fa fa-cog',
            routerLink: ['/financeiro/configuracoes'],
            //   visible: this.showMenu('PESSOAS'),
        }]



    }

}
