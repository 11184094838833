import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-bk-upload',
  templateUrl: './bk-upload.component.html',
  styleUrls: ['./bk-upload.component.css']
})
export class BkUploadComponent implements OnInit {
  @ViewChild('myFile') myFiles!: FileUpload;

  @Input() uploadFile;

  @Output() onUpload = new EventEmitter<any>();

  @Input() clearAfterUpload = false;

  constructor() { }

  ngOnInit(): void {
    this.uploadFile = undefined;

    console.log(this.uploadFile);
  }

  uploadHandlerDoc(event) {
    this.uploadFile = event.files[0];
    this.myFiles.clear();
    this.onUpload.emit(this.uploadFile);

    if (this.clearAfterUpload) {
      this.uploadFile = undefined;
    }
  }

  removeFile() {
    this.uploadFile = undefined;
    this.onUpload.emit(this.uploadFile);
  }
}
