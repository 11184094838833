import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CampoCustomizadoService } from 'src/app/services/campo-customizado.service';

@Component({
  selector: 'app-campo-personalizado',
  templateUrl: './campo-personalizado.component.html',
  styleUrls: ['./campo-personalizado.component.css']
})
export class CampoPersonalizadoComponent implements OnInit {
  cols: any[] = [
    { field: 'id', header: 'ID' },
    { field: 'campo', header: 'Campo' },
    { field: 'tipo', header: 'Tipo' },
    { field: 'ativo', header: 'Ativo' },
  ];

  @Input() localidade: string;

  customFieldsList: any[] = [];

  modal: boolean = false;

  form: FormGroup

  dropDownTipo: any[] = [
    { value: 'INTEGER', label: 'Numero Inteiro' },
    { value: 'STRING', label: 'Texto' },
  ]

  constructor(
    private campoCustomizadoService: CampoCustomizadoService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      id: [null],
      campo: [null],
      tipo: [null],
      ativo: [true],
      localidade: [this.localidade],
    });


    this.index();
  }

  index() {
    let request = 'localidade=' + this.localidade;
    this.campoCustomizadoService.index(null, request).subscribe(campos => {
      this.customFieldsList = campos;
    });
  }

  inactive(item) {
    if (confirm('Deseja realmente inativar o campo personalizado?')) {
      item.ativo = false;
      let body = item;
  
      this.campoCustomizadoService.update(body, item.id).subscribe(json => {
        this.index();
      })
    }
  }

  showDialogForm(item = null) {
    this.form.reset();

    if (item != null) {
      this.campoCustomizadoService.show(item.id).subscribe(json => {
        this.form.patchValue(json);
      })
    } else {
      this.form.get('localidade').setValue(this.localidade);
      this.form.get('ativo').setValue(true);
    };

    this.modal = true;
  }

  save() {
    let params = this.form.value;

    let requests = [];

    if (params.id == null) {
      requests.push(this.campoCustomizadoService.store(params));

    } else {
      requests.push(this.campoCustomizadoService.update(params, params.id));
    }

    forkJoin(requests).subscribe(json => {
      this.modal = false;
      this.index();
    })
  }
}
