import { Component } from "@angular/core";
import {
    trigger,
    state,
    transition,
    style,
    animate,
} from "@angular/animations";
import { Router } from "@angular/router";

@Component({
    selector: "app-inline-profile",
    template: `
        <!-- <div class="profile" [ngClass]="{ 'profile-expanded': active }">
            <a href="#">
                <img
                    class="profile-image"
                    src="assets/layout/images/perfil.png"
                />
                <span class="profile-name">{{ user.name }}</span>
                <span class="profile-role">{{ user.email }}</span>
                <span
                    class="profile-role"
                    style="margin-top: 20px; color: blue"
                    (click)="logout()"
                    >Sair do sistema</span
                >
            </a>
        </div> -->
        <div class="profile">
            <img src="assets/layout/images/perfil.png" alt="Avatar" />
            <div class="user-info">
                <span class="name">{{ user.name }}</span>
                <span class="email">{{ user.email }}</span>
                <a href="#" (click)="logout()" class="logout"
                    >Sair do sistema</a
                >
            </div>
        </div>

        <ul
            id="profile-menu"
            class="layout-menu"
            [@menu]="active ? 'visible' : 'hidden'"
        >
            <li role="menuitem">
                <a href="#" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-user"></i>
                    <span>Profile</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Profile</div>
                </div>
            </li>
            <li role="menuitem">
                <a href="#" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-fw pi-lock"></i>
                    <span>Privacy</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Privacy</div>
                </div>
            </li>
            <li role="menuitem">
                <a href="#" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-cog"></i>
                    <span>Settings</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Settings</div>
                </div>
            </li>
            <li role="menuitem">
                <a href="#" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-sign-out"></i>
                    <span>Logout</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">Logout</div>
                </div>
            </li>
        </ul>
    `,
    styleUrls: ["./app.profile.component.css"],
    animations: [
        trigger("menu", [
            state(
                "hidden",
                style({
                    height: "0px",
                })
            ),
            state(
                "visible",
                style({
                    height: "*",
                })
            ),
            transition(
                "visible => hidden",
                animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
            ),
            transition(
                "hidden => visible",
                animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
            ),
        ]),
    ],
})
export class AppProfileComponent {
    user = JSON.parse(localStorage.getItem("user"));
    active: boolean;

    constructor(private router: Router) {}

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    logout() {
        localStorage.clear();
        this.router.navigate(["/login"]);
    }
}
