import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContaService } from 'src/app/services/conta.service';

@Component({
  selector: 'app-conta-bancaria',
  templateUrl: './conta-bancaria.component.html',
  styleUrls: ['./conta-bancaria.component.css']
})
export class ContaBancariaComponent implements OnInit {

  cols = [
    { field: 'agencia', header: 'Agencia' },
    { field: 'conta', header: 'Conta' },
    { field: 'digito_conta', header: 'Digito' },
    { field: 'banco', header: 'Banco' },
  ];

  dataSource = [];

  loadingGrid = false;

  dropDownTipo = [
    { value: '0', label: 'Caixa' },
    { value: '1', label: 'Conta corrente' },
    { value: '2', label: 'Poupança' },
  ]

  dropdownBanco = [];

  form: FormGroup;

  formOpeApi: FormGroup;

  modal = false;

  dropdownEmissorBoleto = [
    { value: null, label: 'Selecione' },
    { value: 0, label: 'ASAAS' },
    { value: 1, label: 'SICREDI' },
  ];



  constructor(
    private contaService: ContaService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getBancos();

    this.form = this.formBuilder.group({
      id: [null],
      agencia: [null],
      conta: [null],
      digito_conta: [null],
      tipo: [null],
      banco_id: [null],
      emissor_boleto: [null],
      codigo_conta: [null],
    });

    this.formOpeApi = this.formBuilder.group({
      id: [null],
      usuario: [null],
      senha: [null],
      api_key: [null],
      codigo_beneficiario: [null],
    });

    this.index();
  }

  getBancos() {
    this.contaService.getBancos().subscribe(json => {
      this.dropdownBanco = json.map(item => { return { value: item.id, label: item.nome } });
    })
  }

  showForm(rowData = null) {
    this.modal = true;

    this.form.reset();

    this.formOpeApi.reset();

    if (rowData != null) {
      this.contaService.show(rowData.id).subscribe(json => {
        this.form.patchValue(json);
        this.formOpeApi.patchValue(json.open_api);
      })
    }
  }

  index() {
    this.contaService.index().subscribe(json => {
      this.dataSource = json.map(conta => {
        return {
          id: conta.id,
          agencia: conta.agencia,
          conta: conta.conta,
          digito_conta: conta.digito_conta,
          banco: conta?.banco?.nome
        }
      });
    })
  }

  save() {

    let body = {
      agencia: this.form.get('agencia').value,
      conta: this.form.get('conta').value,
      digito_conta: this.form.get('digito_conta').value,
      tipo: this.form.get('tipo').value,
      codigo_conta: this.form.get('codigo_conta').value,
      banco_id: this.form.get('banco_id').value,
      emissor_boleto: this.form.get('emissor_boleto').value,
      openApi: {
        usuario: this.formOpeApi.get('usuario').value,
        senha: this.formOpeApi.get('senha').value,
        codigo_beneficiario: this.formOpeApi.get('codigo_beneficiario').value,
        api_key: this.formOpeApi.get('api_key').value,
      }
    }

    this.contaService.save(body, this.form.get('id').value).subscribe(json => {
      this.modal = false;
      this.index();
    });
  }
}
