import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class AgendaService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'agenda');
    }
}
