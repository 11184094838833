import { Component, OnInit, Input } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.css']
})
export class ChartLineComponent implements OnInit {
  @Input() dataChart:any;
  
  @Input() chartId:any;

  @Input() title;

  @Input() label:any;

  @Input() labelEixoX:any;

  @Input() series:any = [];


  constructor() { }

  ngOnInit(): void {
  }

  buildCharts() {
    var chartDom = document.getElementById(this.chartId);
    var myChart = echarts.init(chartDom);
    var option;

    this.series.map(item => {
      item.type = 'line',
      item.stack = 'total';
    })

    option = {
      title: {
        text: this.title
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: this.label
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.labelEixoX
      },
      yAxis: {
        type: 'value'
      },
      series: this.series
    };

    option && myChart.setOption(option);
  }
}
