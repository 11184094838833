import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContaService } from 'src/app/services/conta.service';
import { FormaPagamentoService } from 'src/app/services/forma-pagamento.service';
import { MovimentacaoBancariaService } from 'src/app/services/movimentacao-bancaria.service';
import * as moment from 'moment';
import { PessoaService } from 'src/app/services/pessoa.service';
import { PlanoContaService } from 'src/app/services/plano-conta.service';


@Component({
  selector: 'app-movimentacao-bancaria',
  templateUrl: './movimentacao-bancaria.component.html',
  styleUrls: ['./movimentacao-bancaria.component.css']
})
export class MovimentacaoBancariaComponent implements OnInit {
  cols = [
    { field: 'conta', header: 'Conta' },
    { field: 'historico', header: 'Histórico' },
    { field: 'created_at', header: 'Data' },
    { field: 'valor', header: 'Valor' ,type: 'currency'},
    { field: 'saldoAnterior', header: 'Saldo Anterior' ,type: 'currency'},
  ];

  dataSource = [];
  filteredPessoas: any[]= [];

  loadingGrid = false;

  dropDownTipo = [
    { value: null, label: 'Todos' },
    { value: 'ENTRADA', label: 'Entrada' },
    { value: 'SAIDA', label: 'Saida' },
  ]

  dropdownFormaPagamento = [];

  dropdownConta = [];
  dropdownPlanoConta = [];

  form: FormGroup;

  formFiltro: FormGroup;

  modal = false;

  totSaidas = 0;

  totEntradas = 0;

  saldo = 0;

  pt: any = {
    firstDayOfWeek: 0,
    dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
    monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
    monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
};

  constructor(
    private _movimentacaoService: MovimentacaoBancariaService,
    private _formaPagamentoService: FormaPagamentoService,
    private _contaService: ContaService,
    private _pessoaService: PessoaService,
    private _planoContasService: PlanoContaService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      tipo: [null],
      conta_id: [null],
      conta_destino_id: [null],
      valor: [null],
      historico: [null],
      forma_pagamento_id: [null],
      gerarTitulo : [null],
      pessoa_id : [null],
      plano_contas_id : [null],
      vencimento : [null],
    });

    this.formFiltro = this.formBuilder.group({
      tipo: [null],
      conta_id: [null],
      periodo: [null],
    });

    this.index();
    this.listContas()
    this.listFormaPagamento()
    this.listPlanoConta()
  }

  filterPessoa(event) {
    this._pessoaService.autoCompleteAll(event.query).subscribe(json => {
      this.filteredPessoas = json;
    });
  }

  styleGrid(row) {
    if (row['tipo'] == 'c') {
      return { 'color': 'white', 'background-color': '#88b988', 'font-weight': '500' }
    } else {
      return { 'color': 'white', 'background-color': '#ef9797', 'font-weight': '500' }
    }
  }

  listPlanoConta() {
    this.dropdownPlanoConta = [];
    this.dropdownPlanoConta.push({ value: null, label: "Selecione" });
    this._planoContasService.index().subscribe((json) => {
        json.map((item) => {
            this.dropdownPlanoConta.push({
                value: item.id,
                label: item.nome,
            });
        });
    });
}

  index() {
    this.dataSource = [];

    let filtro = this.formFiltro.value;

    let query = '';

    if (filtro.conta_id != null) {
      query += `&conta_id=${filtro.conta_id}`;
    }

    if (filtro.tipo != null) {
      query += `&tipo=${filtro.tipo}`;
    }

    if (filtro.periodo != null) {
      let startDate = moment(filtro.periodo[0]).format("YYYY-MM-DD");
      let endDate = moment(filtro.periodo[1]).format("YYYY-MM-DD");
      query += `&startDate=${startDate}`;
      query += `&endDate=${endDate}`;
    }

    this.totEntradas = 0;
    this.totSaidas = 0;

    this._movimentacaoService.index(null, query).subscribe(json => {
      json.map(item => {
        if (item.valor >= 0) {
          this.totEntradas += parseFloat(item.valor);
        } else {
          this.totSaidas += parseFloat(item.valor);
        }

        let conta = `${item.conta?.banco?.nome} - AG: ${item.conta?.agencia} / CC: ${item.conta?.conta}-${item.conta?.digito_conta}`;
        this.dataSource.push({
          conta: conta,
          historico: item.historico,
          created_at: moment(item.created_at).format("DD/MM/YYYY HH:mm"),
          valor: item.valor,
          tipo: item.valor > 0 ? 'c' : 'd',
          saldoAnterior: item.saldoAnterior || 0
        });
      });

      this.totSaidas = this.totSaidas * -1;
      this.saldo = this.totEntradas - this.totSaidas;
    })
  }

  showForm(rowData = null) {
    this.modal = true;

    this.form.reset();
    this.form.get('gerarTitulo').setValue(false)

    console.log(rowData);

    if (rowData != null) {
      this._movimentacaoService.show(rowData.id).subscribe(json => {
        this.form.patchValue(json);
      })
    }
  }

  save() {
    let data = this.form.value
    if (this.form.get('tipo').value == 'TRANSFERENCIA') {
 
      let body = {
        origem: {
          conta_id: this.form.get('conta_id').value,
        }, destino: {
          conta_id: this.form.get('conta_destino_id').value
        },
        forma_pagamento_id: this.form.get('forma_pagamento_id').value,
        valor: this.form.get('valor').value,
        gerarTitulo: this.form.get('gerarTitulo').value,
        vencimento: this.form.get('vencimento').value,
        plano_contas_id: this.form.get('plano_contas_id').value,
        pessoa_id: data?.pessoa_id?.id,
      }

      this._movimentacaoService.transfer(body).subscribe(json => {
        this.modal = false;
      })

    } else {
      let body = {
        forma_pagamento_id: this.form.get('forma_pagamento_id').value,
        tipo: this.form.get('tipo').value,
        conta_id: this.form.get('conta_id').value,
        valor: this.form.get('valor').value,
        historico: this.form.get('historico').value,
        gerarTitulo: this.form.get('gerarTitulo').value,
        vencimento: this.form.get('vencimento').value,
        plano_contas_id: this.form.get('plano_contas_id').value,
        pessoa_id: data?.pessoa_id?.id,
      }

      this._movimentacaoService.store(body).subscribe(json => {
        this.modal = false;
        this.index();
      })
    }

  }

  listFormaPagamento() {
    this.dropdownFormaPagamento = []
    this._formaPagamentoService.index().subscribe(formasPagamentos => {
      this.dropdownFormaPagamento = formasPagamentos.map(f => { return { value: f.id, label: f.descricao } });
    })
  }

  listContas() {
    this.dropdownConta = []
    this._contaService.index().subscribe(json => {
      this.dropdownConta.push({ value: null, label: 'Selecione' })
      json.map(f => {
        this.dropdownConta.push({ value: f.id, label: 'Agência: ' + f.agencia + ' - Conta: ' + f.conta + ' - Digito: ' + f.digito_conta })
      });
    })
  }

}
