import { Component, OnInit } from '@angular/core';
import ptLocale from '@fullcalendar/core/locales/pt';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AgendaService } from 'src/app/services/agenda.service';
import { AgendaTipoService } from 'src/app/services/agenda-tipo.service';
import { PessoaService } from 'src/app/services/pessoa.service';
let window: any;
@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
    form: FormGroup;
    loading: boolean = false
    events: any[] = [];
    dropDownTipos: any[] = []
    tipos: any[] = [];
    totalRecords: any;
    options: any;

    pt = {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
            'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };

    dialog: boolean = false

    dialogTipo: boolean = false

    filteredPessoas = [];

    dropDownSituacao: any[] = [
        { value: null, label: 'Selecione' },
        { value: 'ABERTO', label: 'Aberto' },
        { value: 'FINALIZADO', label: 'Finalizado' },
        { value: 'CANCELADO', label: 'Cancelado' },
    ];

    dropDownResponsaveis: any[] = []

    dropDownFrequencia: any[] = [
        { value: null, label: 'Selecione' },
        { value: 'UNICA', label: 'Única' },
        { value: 'SEMANAL', label: 'Semanal' },
        { value: 'MENSAL', label: 'Mensal' },
    ];

    pessoaList = [];

    constructor(
        private _formBuilder: FormBuilder,
        private _usuarioService: UsuarioService,
        private _agendaService: AgendaService,
        private _agendaTipoService: AgendaTipoService,
        private _pessoaService: PessoaService,
    ) { }

    ngOnInit(): void {
        window = this;
        this.form = this._formBuilder.group({
            agenda: this._formBuilder.group({
                id: [null],
                titulo: [null, Validators.required],
                responsavel: [null, Validators.required],
                status: [null, Validators.required],
                frequencia: [null, Validators.required],
                meses: [null],
                semanas: [null],
                descricao: [null, Validators.required],
                data: [null, Validators.required],
                tipo: [null, Validators.required],
            }),
            tipo: this._formBuilder.group({
                id: [null],
                descricao: [null],
            })
        })

        this.options = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            defaultDate: Date.now(),
            locale: 'pt',
            locales: [ptLocale],
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            editable: true,
            dateClick: function (event) {
            },
            eventClick: function (info) {
                localStorage.setItem('evento_agenda_id', info.event.id);
                window.editEvent();
            },
            eventColor: 'red',
        };
        this.index()
        this.listResponsaveis()
        this.listTipos()

    }

    addPessoa() {
        this.pessoaList.push({ pessoa: null });
    }

    removePessoa(item) {
        let pessoas = this.pessoaList.filter(p => { return p != item });
        this.pessoaList = pessoas;
    }

    index() {
        this.events = []
        this._agendaService.index().subscribe(json => {
            console.log(json)
            let color: any

            json.map(agenda => {

                if (agenda.status == 'ABERTO') {
                    color = 'blue'
                } else if (agenda.status == 'FINALIZADO') {
                    color = 'green'
                } else {
                    color = 'red'
                }

                this.events = [...this.events, {
                    id: agenda.id,
                    title: agenda.titulo,
                    start: agenda.inicio,
                    end: agenda.inicio,
                    color: color
                }]
            })
            this.loading = false;
        }, err => {
            console.log(err)
            this.loading = false;
        })

    }

    openNew() {
        this.form.reset()
        this.dialog = true
    }

    listResponsaveis() {
        this._usuarioService.grid().subscribe(json => {
            this.dropDownResponsaveis = []

            json.map(item => {
                this.dropDownResponsaveis.push(
                    { value: item.id, label: item.nome_pessoa }
                )
            })
        })
    }
    listTipos() {
        this.dropDownTipos = []
        this.tipos = []
        this._agendaTipoService.index().subscribe(json => {
            this.tipos = json
            this.dropDownTipos.push(
                { value: null, label: 'Selecione' }
            )
            json.map(item => {
                this.dropDownTipos.push(
                    { value: item.id, label: item.descricao }
                )
            })
        })
    }

    editEvent() {
        this.form.get('agenda').reset()
        let id = localStorage.getItem('evento_agenda_id');
        this._agendaService.show(id).subscribe(json => {
            console.log(json)
            this.form.get('agenda.id').setValue(json.id)
            this.form.get('agenda.descricao').setValue(json.descricao)
            this.form.get('agenda.frequencia').setValue(json.frequencia)
            this.form.get('agenda.data').setValue(json.inicio)
            this.form.get('agenda.status').setValue(json.status)
            this.form.get('agenda.titulo').setValue(json.titulo)
            this.form.get('agenda.responsavel').setValue(json.usuario_responsavel_id)
            this.form.get('agenda.tipo').setValue(json.tipo_agenda_id)

            this.dialog = true
        })

    }

    agendar() {
        this.loading = true;

        let vezes: any

        if (this.form.get('agenda.frequencia').value == 'SEMANAL') {
            vezes = this.form.get('agenda.semanas').value;
        } else if (this.form.get('agenda.frequencia').value == 'MENSAL') {
            vezes = this.form.get('agenda.meses').value;
        } else {
            vezes = 0;
        }

        let params = {
            titulo: this.form.get('agenda.titulo').value,
            status: this.form.get('agenda.status').value, //FECHADO, ABERTO CANCELADO
            frequencia: this.form.get('agenda.frequencia').value,
            descricao: this.form.get('agenda.descricao').value,
            inicio: this.form.get('agenda.data').value,
            responsavel_id: this.form.get('agenda.responsavel').value,
            usuario_responsavel_id: this.form.get('agenda.responsavel').value,
            tipo_agenda_id: this.form.get('agenda.tipo').value,
            numero_repeticao: vezes,
            pessoas: this.pessoaList
        }

        this._agendaService.save(params, this.form.get('agenda.id').value).subscribe(json => {
            this.index()
            this.loading = false
            this.dialog = false
        }, err => {
            this.loading = false
            console.log(err)
        })
    }

    showDialogForm(event) {
        this.form.get('tipo.id').setValue(null)
        this.form.get('tipo.descricao').setValue(null)
        if (event?.id) {
            this._agendaTipoService.show(event.id).subscribe(json => {
                this.form.get('tipo').setValue(json)
            }, err => {
                alert(err)
            })
        }
        this.dialogTipo = true
    }

    saveTipo() {
        if (this.form.get('tipo.id').value == null) {
            this._agendaTipoService.store(this.form.get('tipo').value).subscribe(json => {
                this.listTipos()
                this.dialogTipo = false
            }, err => {
                alert(err)
            })
        } else {
            this._agendaTipoService.update(this.form.get('tipo').value, this.form.get('tipo.id').value).subscribe(json => {
                this.listTipos()
                this.dialogTipo = false
            }, err => {
                alert(err)
            })
        }
    }

    filterPessoa(event) {
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = json.filter((p) => {
                return p.tipo == "FISICA" && p.cliente == true;
            });
        });
    }

}