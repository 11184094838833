import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class TipoPlanoService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'tipo-plano');
    }

}
