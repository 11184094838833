import { Component, OnInit } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  periodo_prestacao: any = null
  transportadora: any  = null
  ambiente: any = null
  file: any = null
  message: any = null
  loading: boolean = false
  displayMessage: boolean = false
  error: boolean = false
  success: boolean = false
  dropDownAmbiente: any[] = [
    {value: null, label: 'Selecione'},
    {value: 1, label: 'Produção'},
    {value: 2, label: 'Homologação'}
  ]

  constructor(
    private _pessoaService: PessoaService,
  ) { }

  ngOnInit() {
  }

  inputGallery(files: FileList): void {
    this.file = files[0];
  }

  removeArquivo(){
    this.file = null
  }

  save() {
    this.loading = true
    let formData: FormData = new FormData();

    setTimeout(() => {
      formData.append('file', this.file);
      formData.append('transportadora', this.transportadora.toLowerCase());
      formData.append('periodo_prestacao', this.periodo_prestacao.toUpperCase());
      formData.append('ambiente', this.ambiente);
     
      this._pessoaService.uploadAlis(formData).subscribe(json => {
        this.loading = false
        this.displayMessage = true
        this.success = true
      }, err => {
        this.loading = false
        this.displayMessage = true
        this.error = true
        this.message = err.error.error
      })



    }, 1000);

  }

}
