import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormaPagamentoService } from "src/app/services/forma-pagamento.service";
import { InsumoService } from "src/app/services/insumo.service";
import { PessoaService } from "src/app/services/pessoa.service";

@Component({
    selector: "app-comprar-ativo-digital",
    templateUrl: "./comprar-ativo-digital.component.html",
    styleUrls: ["./comprar-ativo-digital.component.css"],
})
export class ComprarAtivoDigitalComponent implements OnInit {
    form: FormGroup;
    compras: any[] = [];
    totalRecords: number = 0;
    dialog: boolean = false;
    dropDownFornecedores: any[] = [];
    dropDownFormasPagamento: any[] = [];
    dropDownProdutos: any[] = [];
    constructor(
        private _formBuilder: FormBuilder,
        private _insumoService: InsumoService,
        private _pessoaService: PessoaService,
        private _formaPagamentoService: FormaPagamentoService
    ) {}

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            compra: this._formBuilder.group({
                id: [null],
                fornecedor_id: [null],
                moeda_produto_id: [null],
                custo_unitario: [null],
                quantidade: [null],
                forma_pagamento_id: [null],
                data_compra: [null],
                total: [null],
                hash: [null],
            }),
        });
        this.index();
    }

    index() {
        this.compras = [];
        this._insumoService.listPurchases().subscribe((json) => {
            json.map((item) => {
                item.quantidade = parseInt(item.quantidade);
            });
            this.compras = json;
            this.totalRecords = json.length;
        });
    }

  

    total() {
        let x = this.form.get("compra").value;
        if(x.quantidade > 0){
            
            if(x.total > 0){
                this.form.get("compra.custo_unitario").setValue(x.total / x.quantidade);
            }
            if(x.custo_unitario > 0){
                this.form.get("compra.total").setValue(x.custo_unitario * x.quantidade);
        } 
            }
            
       
    }

    unitario() {
        let x = this.form.get("compra").value;
        if(x.quantidade > 0){
            
            this.form.get("compra.custo_unitario").setValue(x.total / x.quantidade);
            if(x.custo_unitario > 0){
                this.form.get("compra.total").setValue(x.custo_unitario * x.quantidade);
            }
        }
       
    }

    // changeFornecedor() {
    //     this.dropDownProdutos = [];
    //     this.dropDownProdutos = [
    //         ...this.dropDownProdutos,
    //         { value: null, label: "Selecione" },
    //     ];
    //     this._insumoService
    //         .listByFornecedor(this.form.get("compra.fornecedor_id").value)
    //         .subscribe((json) => {
    //             json.map((item) => {
    //                 this.dropDownProdutos = [
    //                     ...this.dropDownProdutos,
    //                     { value: item.id, label: item.nome },
    //                 ];
    //             });
    //         });
    // }

    showDialogForm(event) {
        this.form.reset();
        this.dropDownFornecedores = [];
        this.dropDownFornecedores = [
            ...this.dropDownFornecedores,
            { value: null, label: "Selecione" },
        ];
        this.dropDownFormasPagamento = [
            ...this.dropDownFormasPagamento,
            { value: null, label: "Selecione" },
        ];
        this._formaPagamentoService.index().subscribe((json) => {
            json.map((item) => {
                this.dropDownFormasPagamento = [
                    ...this.dropDownFormasPagamento,
                    { value: item.id, label: item.descricao },
                ];
            });
        });
        this.dropDownProdutos = [];
        this.dropDownProdutos = [
            ...this.dropDownProdutos,
            { value: null, label: "Selecione" },
        ];
        this._insumoService.indexCripto().subscribe((json) => {
            json.map((item) => {
                this.dropDownProdutos = [
                    ...this.dropDownProdutos,
                    { value: item.id, label: item.nome },
                ];
            });
        });
        this._pessoaService.listFornecedores().subscribe((json) => {
            json.map((item) => {
                this.dropDownFornecedores = [
                    ...this.dropDownFornecedores,
                    { value: item.id, label: item.nome },
                ];
            });
            if (event == null) {
                this.form.get('compra.quantidade').setValue(0)
                this.form.get('compra.custo_unitario').setValue(0)
                this.form.get('compra.total').setValue(0)
                this.dialog = true;
            } else {
                this._insumoService.show(event.id).subscribe(
                    (json) => {
                        this.form.get("ad.ativo").setValue(json.ativo);
                        this.form.get("ad.nome").setValue(json.nome);
                        if (json.fornecedores) {
                            let fornecedores: any[] = [];
                            json.fornecedores.map((item) => {
                                fornecedores = [...fornecedores, item.id];
                            });
                            this.form
                                .get("ad.fornecedores")
                                .setValue(fornecedores);
                        }

                        this.dialog = true;
                    },
                    (err) => {
                        alert(err);
                    }
                );
            }
        });
    }

    save() {
        let x = this.form.get("compra").value;
        let params = {
            fornecedor_id: x.fornecedor_id,
            moeda_produto_id: x.moeda_produto_id,
            custo_unitario: x.custo_unitario,
            quantidade: x.quantidade,
            forma_pagamento_id: x.forma_pagamento_id,
            data_compra: x.data_compra,
            total: x.total,
            hash: x.hash,
        };

        if (x.id == null) {
            this._insumoService.storeBuyCripto(params).subscribe(
                (json) => {
                    console.log(json);
                    this.index();
                    this.dialog = false;
                },
                (err) => {
                    alert(err);
                }
            );
        } else {
        }
    }
}
