import { Component, OnInit, Input } from '@angular/core';
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'app-show-multi-pdf',
  templateUrl: './show-multi-pdf.component.html',
  styleUrls: ['./show-multi-pdf.component.css']
})
export class ShowMultiPdfComponent implements OnInit {

  constructor() { }

  @Input() pdfUrls = [];

  ngOnInit(): void {
    this.mergePDFs();
  }

  async mergePDFsService(pdfUrls: string[]): Promise<Uint8Array> {
    const mergedPdf = await PDFDocument.create();
    
    for (const pdfUrl of pdfUrls) {
      const pdfBytes = await fetch(pdfUrl).then(response => response.arrayBuffer());
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach(page => mergedPdf.addPage(page));
    }

    return mergedPdf.save();
  }

  async mergePDFs(): Promise<void> {

    const mergedPdfBytes = await this.mergePDFsService(this.pdfUrls);
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

    const a = document.createElement('a');
    a.href = mergedPdfUrl;
    a.target = '_blank';
    a.download = 'merged.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
