import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'configuracao');
  }

  listTipoContabilFormaPagamento(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/tipo-contabil-forma-pagamento`);
  } 

  storeContabil(param): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/contabil`, param);
  } 

  indexContabil(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/contabil/index`);
  } 

  indexContabilBancaria(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/contabil/bancaria/index`);
  } 

  removeContabil(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/contabil/remove/${id}`);
  } 
}
