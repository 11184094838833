import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class FormaPagamentoService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'forma-pagamento');
  }

  getDataTable(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/datatable`);
  }
  autocomplete(param): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/${param}`);
  }
}
