import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class PessoaService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'pessoa');
  }

  listFuncionarios(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/funcionarios`);
  }

  paginate(app = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/paginate`;


    url += '?' + app


    return this.http.post(url, null);
  }



  getByCelular(celular = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get-by-celular/${celular}`);
  }

  autoCompleteEmpresa(value: any = undefined): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/autocomplete/empresa`;

    if (value != undefined) {
      url += `?nome=${value}`;
    }

    return this.http.get(url);
  }

  autoCompleteAll(value: any = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/autocomplete`;
    if (value != null && value != "" && value != undefined) {
      url += `?value=${value}`;
    }
    return this.http.get(url);
  }


  filtroPaginado(page: number, body = null, row = null, fieldOrder = null, order = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/paginate`;

    if (page !== null) {
      url += '?page=' + page;
    }

    if (row != null) {
      url += '&row=' + row;
    }

    if (fieldOrder != null) {
      url += `&fieldOrder=${fieldOrder}`
    }

    if (order != null) {
      url += `&order=${order}`
    }

    return this.http.post(url, body);
  }

  linkTag(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/tag`, body);
  }

  removeTag(body: any): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/remove/tag`, body);
  }

  updateDadosChat(body: any, id: number): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/dados/chat/${id}`, body);
  }

  autoCompletePessoa(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/list/pessoas/filtro`, body);
    // if (param !== '') {
    //   return this.http.get(`${this.url_base}/${this.prefixo}/get/autocomplete/${param}`);
    // } else {

    // }
  }

  createBiteasy(body = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/create/biteasy`, body);
  }

  listAvailable(value = null): Observable<any> {
    if (value !== null) {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/available?value=${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/available`);
    }

  }

  listForwarding(value = null): Observable<any> {
    if (value !== null) {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/forwarding?value=${value}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/list/forwarding`);
    }

  }

  showProfileChat(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/profile-chat/${id}`);
  }

  saveProfile(body, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/save/profile/${id}`, body);
  }

  tagLinked(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/tag-linked/${id}`);
  }

  linkOneTag(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/one-tag`, body);
  }

  linkOneResponsavel(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/link/one-responsavel`, body);
  }

  showLite(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/lite/${id}`);
  }

  updateLite(body, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/lite/${id}`, body);
  }

  listClientes(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/clientes`);
  }

  listAlunos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/alunos`);
  }

  listInstrutores(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/instrutores`);
  }

  listPilotos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pilotos`);
  }

  listFornecedores(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/fornecedores`);
  }

  listEmpresas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/empresas`);
  }

  listCotistas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/cotistas`);
  }

  listOperadores(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/operadores`);
  }

  listPessoasMenosCotista(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-menos-cotista`);
  }

  listPessoasMenosAeroporto(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-menos-aeroporto`);
  }

  deleteContato(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/contato/${id}`);
  }

  deleteRelacao(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/relacao/${id}`);
  }

  deleteEndereco(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/endereco/${id}`);
  }

  listModulos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/modulos`);
  }

  saveHoras(data): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/save/horas`, data);
  }

  storeAluno(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/aluno`, params);
  }

  updateAluno(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/aluno/${id}`, params);
  }

  showAluno(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/show/aluno/${id}`);
  }

  listOperador(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/operador/${id}`);
  }

  listCotistaRelacionado(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/cotista-relacionado/${id}`);
  }

  storePiloto(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/piloto`, params);
  }

  storeCotista(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/cotista`, params);
  }
  storePassageiro(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/passageiro`, params);
  }

  updatePiloto(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/piloto/${id}`, params);
  }
  updateCotista(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/cotista/${id}`, params);
  }
  updatePassageiro(params, id): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/passageiro/${id}`, params);
  }

  storeLicencaHabilitacao(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/licenca-habilitacao`, params);
  }

  storeCertificado(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/certificado`, params);
  }

  storePropOper(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/prop-oper`, params);
  }

  storePassageiroModal(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/passageiro-modal`, params);
  }

  listLicenca(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/licenca`);
  }

  listHabilitacao(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/habilitacao`);
  }

  listCertificado(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/certificado`);
  }

  storeTreinamento(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/treinamento`, params);
  }

  listTreinamentos(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/treinamentos`);
  }

  listPassageiros(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/passageiros`);
  }

  listPessoasAtivas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-ativas`);
  }

  listPessoas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas`);
  }

  listPessoasRelacionadasUsuario(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-relacionadas-usuario`);
  }

  listUsuariosEmpresa(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/usuarios-empresa`);
  }

  listPessoasEmpresa(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-empresa`);
  }

  listPessoasEmpresaMenosCotista(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-empresa-menos-cotista`);
  }

  listMunicipios(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/municipios`);
  }

  uploadImagem(params): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload/imagem`, params);
  }

  listImagens(pessoId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/imagens/` + pessoId);
  }

  listPassageirosCotista(cotistaId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/passageiros-cotista/` + cotistaId);
  }

  deleteImagem(id): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/delete/imagem/${id}`);
  }

  downloadImagem(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/download-imagem/${id}`);
  }

  listCidadeInformada(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/cidade-informada/${id}`);
  }

  listPessoasRelacionadas(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/pessoas-relacionadas/${id}`);
  }

  procuraNome(nome): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/procura-nome/${nome}`);
  }

  procuraCpf(cpf): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/procura-cpf/${cpf}`);
  }

  procuraRg(rg): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/procura-rg/${rg}`);
  }

  procuraCnpj(cnpj): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/procura-cnpj/${cnpj}`);
  }

  procuraContato(contato): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/procura-contato/${contato}`);
  }

  filtroPessoa(nome = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/filtro/pessoa/${nome}`);
  }

  filtroPessoaMenosOperador(nome = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/filtro/pessoa-menos-operador/${nome}`);
  }

  filtroPessoaMenosCotista(nome = null): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/filtro/pessoa-menos-cotista/${nome}`);
  }

  autoCompleteFornecedores(nome): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/fornecedor/auto-complete/${nome}`);
  }

  listPJ(nome): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/PJ/${nome}`);
  }

  // autoCompleteEmpresa(nome): Observable<any> {
  //   return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/empresa/${nome}`);
  // }

  autoCompleteClientes(nome): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/cliente/auto-complete/${nome}`);
  }

  uploadFile(formData): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/upload/file`, formData);
  }

  fastStore(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/fast`, body);
  }

  listDependentesFromTitular(empresaId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/dependentes/titular/${empresaId}`);
  }

  updateDependenteEmpresa(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/update/dependentes/empresa`, body);
  }

  saveDependenteToPessoaContrato(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/save/dependente-to-pessoa-contrato`, body);
  }

  checkFuncionarioContratoFile(body): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/check-funcionario-contrato-file`, body);
  }

  listAllCredenciados(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list-all/credenciados`);
  }

  listAllClinicas(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list-all/clinicas`);
  }

  getEspecialidadeByCredenciado(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get-especialidade/credenciado/${id}`);
  }

  listDependents(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/dependents/${id}`);
  }

  updateDependentePessoa(body: any, id: number): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/dependente-to-pessoa-contrato/${id}`, body);
  }

  showByDocument(document, type): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/document/${document}/${type}`);
  }
  // autoCompleteAll(param): Observable<any> {
  //   return this.http.get(`${this.url_base}/${this.prefixo}/get/autocomplete/${param}`);
  // }

  getConvenio(id): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/convenio/${id}`);
  }

}
