import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlanoService extends GenericService {

  static tipoDependentes = [
    { value: null, label: 'Digite para pesquisar' },
    { value: 1, label: 'Cônjugue' },
    { value: 2, label: 'Filhos' },
    { value: 3, label: 'Pai e mãe' },
    { value: 4, label: 'Outros' },
  ];

  constructor(http: HttpClient) {
    super(http, 'plano');
  }


}
