import { Component, OnInit } from '@angular/core';
// import { CompraService } from 'src/app/services/compra.service';
import { InsumoService } from 'src/app/services/insumo.service';
// import { LocalidadeEstoqueService } from 'src/app/services/localidade-estoque.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { PlanoContaService } from 'src/app/services/plano-conta.service';
import { environment } from 'src/environments/environment';
import { FormaPagamentoService } from 'src/app/services/forma-pagamento.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PedidoVendaService } from 'src/app/services/pedido-venda.service';
// import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-pedido-venda',
  templateUrl: './pedido-venda.component.html',
  styleUrls: ['./pedido-venda.component.scss']
})
export class PedidoVendaComponent implements OnInit {
  dialog: boolean = false
  compras: any[] = []
  globalFilterFields = [
    "id",
    "descricao",
    "localidade",
    "ativo",
    "created_at",
  ]
  dropdownFormaPagamento: any[] = []
  filteredPessoas: any[] = [];

  // urlUpload: String = environment.url_sv;

  items = [
    { label: 'Pedidos' },
  ];

  home = { icon: 'pi pi-home', routerLink: '/' };

  id_compra: any
  custoTotal: any
  fornecedor: any
  tipo: any
  produto: any
  nota: any
  total_produtos: any
  outros_custos: any
  localidade_id: any
  status: any
  observacao: any
  plano_conta_id: any
  forma_pagamento_id: any
  parcela: any
  valor_parcela: any
  pessoa_id: any
  array_arquivos: any[]
  array_produto: any[] = []
  gerar_financeiro: boolean = true
  exibir_parcela: boolean = false
  vencimento_titulo: any = null

  dropDownLocais: any[] = []
  dropDownProdutos: any[] = []
  dropDownFornecedores: any[] = []
  dropDownPlanoConta: any[] = []
  dropDownTipo: any[] = [
    { label: 'Selecione', value: "null" },
    { label: 'Compra', value: 0 },
    { label: 'Doação', value: 1 }
  ]
  dropDownStatus: any[] = [
    { label: 'Selecione', value: "null" },
    { label: 'Aberto', value: 'ABERTO' },
    { label: 'Processado', value: 'PROCESSADO' },
    { label: 'Cancelado', value: 'CANCELADO' },
  ]

  cols: any[] = [
    { field: 'id', header: 'Código' },
    { field: 'tipo', header: 'Tipo' },
    { field: 'localidade', header: 'Localidade' },
    { field: 'fornecedor', header: 'Fornecedor' },
    { field: 'total_produtos', header: 'Total em produtos' },
    { field: 'outros_custos', header: 'Outros custos' },
    { field: 'valor_total', header: 'Valor total' },
    { field: 'status', header: 'Status' },
    { field: 'created_at', header: 'Data do cadastro' },
  ];


  form: FormGroup;

  formPagamento: FormGroup;

  pagamentosList = [];


  pedidoVendaList = [];

  constructor(
    protected _insumo: InsumoService,
    // protected _compra: CompraService,
    // protected _le: LocalidadeEstoqueService,
    protected _pessoa: PessoaService,
    protected _planoConta: PlanoContaService,
    protected _pessoaService: PessoaService,
    // private _permissionService: PermissionService
    private _formaPagamentoService: FormaPagamentoService,
    private formBuilder: FormBuilder,
    private pedidoVendaService: PedidoVendaService,

  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      id: [null],
      cliente: [null],
      total: [null],
      sub_total: [null],
      observacao: [null],
      natureza: [null],
      desconto: [null],
    });


    this._insumo.index().subscribe((json: any) => {
      this.dropDownProdutos = [],
        json.map(item => {
          if (item.ativo == true) {
            this.dropDownProdutos = [...this.dropDownProdutos, { value: item.id, label: item.nome, numero_serie: item.numero_serie },
            ];
          }

        })
    })

    this._planoConta.tree().subscribe(json => {
      this.dropDownPlanoConta = []
      this.dropDownPlanoConta.push({ value: "null", label: 'Selecione' })
      json.map(item => {
        this.dropDownPlanoConta = [...this.dropDownPlanoConta, { value: item.id, label: item.descricao },
        ];
      })
    })

    // this._le.index().subscribe((json: any) => {
    //   this.dropDownLocais = []
    //   this.dropDownLocais.push({ value: "null", label: 'Selecione' })
    //   json.map(item => {
    //     if (item.ativo == true) {
    //       this.dropDownLocais = [...this.dropDownLocais, { value: item.id, label: item.descricao + ' - ' + item.nome },
    //       ];
    //     }

    //   })
    // })

    this._pessoa.listFornecedores().subscribe((json: any) => {
      this.dropDownFornecedores = []
      this.dropDownFornecedores.push({ value: "null", label: 'Selecione' })
      json.map(item => {
        this.dropDownFornecedores = [...this.dropDownFornecedores, { value: item.id, label: item.nome },
        ];
      })
    })
    this.index()

    this._formaPagamentoService.index().subscribe((formasPagamentos) => {
      this.dropdownFormaPagamento = []
      this.dropdownFormaPagamento.push({ value: "null", label: 'Selecione' })

      this.dropdownFormaPagamento = formasPagamentos.map((f) => {
        return {
          value: f.id,
          label: `${f.descricao}`, permite_parcelamento: f.permite_parcelamento,
        };
      });

    });
  }

  filterPessoa(event) {
    this._pessoaService.autoCompleteAll(event.query).subscribe(json => {
      this.filteredPessoas = json;
    });
  }

  changeFormaPagamento(item) {
    let x = this.dropdownFormaPagamento.find(x => x.value == item['forma_pagamento_id'])

    if (x.permite_parcelamento == true) {
      this.exibir_parcela = true
    } else {
      this.exibir_parcela = false
    }
  }

  valorParcela(item) {
    let valorParcela = 0;
    let custoTotal = this.form.get('custo_total').value || 0;
    let parcelaCount = item['parcela'] || 1;

    valorParcela = custoTotal / parcelaCount

    item['valor_parcela'] = valorParcela;
  }

  openNew() {
    this.array_arquivos = [];

    this.addProduto();

    this.addPagamento();

    this.form.reset();

    this.dialog = true;
  }



  save() {

    if (this.form.get('natureza').value == null) {
      this.form.get('natureza').setValue('ORCAMENTO');
    }

    let body = {
      venda: this.form.value,
      pagamentos: this.pagamentosList,
      produtos: this.array_produto
    }

    this.pedidoVendaService.save(body, this.form.get('id').value).subscribe(json => {
      this.dialog = false;
    })
  }

  index() {
    this.pedidoVendaService.index().subscribe(json => {
      this.pedidoVendaList = json;
    })
  }

  edit(event) {
    this.array_produto = [];

    this.pagamentosList = [];

    this.pedidoVendaService.show(event.id).subscribe(json => {
      this.form.patchValue(json);

      if (json.insumos.length > 0) {
        json.insumos.map(item => {
          this.array_produto.push({
            id: item.id,
            insumo_id: item.insumo_id,
            numero_serie: null,
            quantidade: item.quantidade,
            valor_unitario: item.preco_unitario,
            valor_total: item.total
          })
        })
      }


      if (json.pagamentos.length > 0) {
        json.pagamentos.map(item => {
          this.pagamentosList.push({
            id: item.id,
            forma_pagamento_id: item.forma_pagamento_id,
            parcela: item.vezes,
            intervalo: item.intervalo,
            valor_parcela: item.valor,
          });
        })
      }

      this.dialog = true;
    })
  }

  addProduto() {
    this.array_produto.push({
      id: null,
      insumo_id: null,
      numero_serie: null,
      quantidade: 0,
      valor_unitario: 0,
      valor_total: 0
    })
  }

  addPagamento() {
    this.pagamentosList.push({
      id: null,
      forma_pagamento_id: null,
      parcela: null,
      intervalo: null,
      vencimento: null,
      valor_parcela: null,
    })
  }

  // changeProduto(event){
  //   let x = this.dropDownProdutos.find(z => z.value == event)
  //   let array_produto = this.array_produto.find(z => z.insumo_id == event)
  //   array_produto.numero_serie = x.numero_serie
  //   console.log(array_produto)
  //   console.log(x)
  // }

  uploadArquivo(event) {
    let fileRet = event.originalEvent.body;
    console.log(fileRet)
    this.array_arquivos.push({
      id: fileRet.id,
      url: fileRet.url,
      extensao: fileRet.extensao,
      nome: fileRet.nome,
    })
  }

  removeArquivo(event) {
    let arr = this.array_arquivos.filter(x => { return x.id !== event.id });

    this.array_arquivos = []

    this.array_arquivos = arr;
  }

  removeProduto(event) {
    let total_itens = this.form.get('sub_total').value || 0;
    total_itens -= event.valor_total
    this.form.get('sub_total').setValue(total_itens);


    let arr = this.array_produto.filter(x => { return x !== event });

    this.array_produto = []

    this.array_produto = arr;
    this.custoFinal()
  }

  removePagamento(event) {
    let arr = this.pagamentosList.filter(x => { return x !== event });
    this.pagamentosList = []
    this.pagamentosList = arr;
  }


  calculoTotal(event) {
    let totalArr = 0
    let total = event.valor_unitario * event.quantidade
    let arr = this.array_produto.find(x => { return x == event });

    arr.valor_total = total

    this.array_produto.map(item => {
      totalArr += item.valor_total
    })

    let total_produtos = totalArr
    this.form.get('sub_total').setValue(total_produtos);

    this.custoFinal()
  }

  custoFinal() {
    let total_produtos = this.form.get('sub_total').value || 0;
    let desconto = this.form.get('desconto').value || 0;
    let custo_total = parseFloat(total_produtos) - parseFloat(desconto);
    this.form.get('total').setValue(custo_total);
  }

  exportPdf() {
    let compras: any[] = []
    compras = this.compras
    let col = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default("landscape");


        (doc as any).autoTable(col, compras, {
          startY: (doc as any).autoTable() + 70,

          margin: { horizontal: 10 },
          styles: { overflow: "linebreak" },
          bodyStyles: { valign: "top" },
          columnStyles: { email: { columnHeight: 2 } },
          theme: "striped",
          showHead: "everyPage",
          didDrawPage: function (data) {

            // Header
            doc.setFontSize(15);
            doc.setTextColor(40);
            doc.text("Relatório de Alunos", data.settings.margin.left, 10);

            //Footer
            var str = "Pagina " + (doc as any).internal.getNumberOfPages();

            doc.setFontSize(10);

            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
          }
        });


        doc.save('relatorio-compras-doacoes.pdf');

      })
    })

  }


  exportExcel() {
    let compras: any[] = []
    compras = this.compras
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(compras);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "compras");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, 'compras-doacoes' + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  invoice() {
    this.pedidoVendaService.invoice(this.form.get('id').value).subscribe(json => {

    })
  }

}
