import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { FormaPagamentoService } from 'src/app/services/forma-pagamento.service';
import { PlanoComissaoService } from 'src/app/services/plano-comissao.service';
import { PlanoContaService } from 'src/app/services/plano-conta.service';

@Component({
  selector: 'app-configuracao-page',
  templateUrl: './configuracao-page.component.html',
  styleUrls: ['./configuracao-page.component.css']
})
export class ConfiguracaoPageComponent implements OnInit {
  form: FormGroup;

  dropdownPlataformaDisparoCobranca = [
    { value: 'v1', label: 'v1' },
    { value: 'v2', label: 'v2' },
  ];

  dropdownPlanoContas = [];

  dropdownFormaPagamento = [];

  constructor(
    private formBuilder: FormBuilder,
    private planoContaService: PlanoContaService,
    private configuracaoService: ConfiguracaoService,
    private formaPagamentoService: FormaPagamentoService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      asaas: [false],
      habilita_campos_crypto: [false],
      plataforma_disparo_cobranca: [null],
      dias_vencimento_envio_cobranca: [5],
      token_asaas: [null],
      contrato_plano_conta_id: [null],
      utiliza_modulo_contrato: [null],
      plataforma_bkchat: [null],
      recebimento_carne: [null],
      mensagem_cobranca_remessa: [null],
      habilitar_dependentes_empresa: [null],
      percentual_juros_contrato: [null],
      percentual_multa_contrato: [null],
      integracao_factor: [null],
      plano_contas_pagamento_motorista_factor: [null],
      forma_pagamento_motorista_factor_id: [null],
      modulo_atendimento: [null],
      modulo_clinica: [null],
      modulo_cripto: [null],
      modulo_agenda: [null],
      modulo_contrato: [null],
      modulo_pedido_venda: [null],
      permite_celular_duplicado: [null],
      notificar_agenda: [1],
    });

    this.listPlanoContas();

    this.listFormaPagamento();

    this.show();
  }

  listPlanoContas() {
    this.planoContaService.index().subscribe(planos => {
      console.log(planos);
      this.dropdownPlanoContas = planos.map(item => { return { value: item.id, label: item.nome } })
    })
  }

  listFormaPagamento() {
    this.formaPagamentoService.index().subscribe(formas => {
      this.dropdownFormaPagamento = formas.map(item => { return { value: item.id, label: item.descricao } });
    })
  }

  show() {
    this.configuracaoService.index().subscribe(json => {
      this.form.patchValue(json[0]);
    })
  }

  save() {
    let body = {
     configuracao: this.form.value
    }
    this.configuracaoService.update(body, this.form.get('id')?.value).subscribe(json => {
      this.configuracaoService.index().subscribe(json => {
        localStorage.setItem('configuracao', JSON.stringify(json[0]));
        window.location.reload();
      })
    })
  }
}
