import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlanoContaService } from 'src/app/services/plano-conta.service';

@Component({
  selector: 'app-plano-conta',
  templateUrl: './plano-conta.component.html',
  styleUrls: ['./plano-conta.component.css']
})
export class PlanoContaComponent implements OnInit {

  form: FormGroup;
  formDialog: boolean = false;
  plano_conta: any[] = []
  arvore: any[] = []
  totalRegistros: any;
  cols: any[] = [
    { field: 'descricao', header: 'Descrição' },
    { field: 'tipo', header: 'Tipo' },
  ];

  items = [
    { label: 'Plano de contas' }
  ]

  home = { icon: 'pi pi-home', routerLink: '/' };

  dropDownTipo: any[] = [
    { value: null, label: 'SELECIONE' },
    { value: 'DESPESA', label: 'DESPESA' },
    { value: 'RECEITA', label: 'RECEITA' },
  ];

  dropDownTipoGasto: any[] = [
    { value: null, label: 'SELECIONE' },
    { value: "PERIODICO", label: 'PERIÓDICO' },
    { value: "MENSAL", label: 'MENSAL' },
  ];

  dropDownPlanoConta: any[] = [];

  planoSelecionado: any[] = [];


  direitoInserir: boolean = false
  direitoAlterar: boolean = false

  constructor(
    protected _formBuilder: FormBuilder,
    protected planoContaService: PlanoContaService,

  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      tipo: [null, Validators.required],
      ativo: [null],
      plano_conta_id: [null],
    })
    this.index();
    this.tree();

    // this.direitoInserir = this.planoContaService.direitos('INSERIR_PLANO_CONTAS')
    // this.direitoAlterar = this.planoContaService.direitos('ALTERAR_PLANO_CONTAS')
  }

  openNew() {
    this.form.reset()
    this.form.get('ativo').setValue(true)
    this.formDialog = true;
  }

  hideDialog() {
    this.formDialog = false;
  }

  index() {
    this.planoContaService.index().subscribe(json => {

      this.plano_conta = json;
      this.totalRegistros = json.length;

      this.dropDownPlanoConta = [];
      this.plano_conta.map(item => {
        this.dropDownPlanoConta = [...this.dropDownPlanoConta, { value: item.id, label: item.nome }];
      })
    })
  }

  changeTipo() {
    let x = this.form.get('tipo').value

    this.planoContaService.index().subscribe(json => {


      this.dropDownPlanoConta = [];
      this.plano_conta.map(item => {
        if (item.tipo == x) {
          this.dropDownPlanoConta = [...this.dropDownPlanoConta, { value: item.id, label: item.nome }];
        }

      })
    })

  }

  edit(event) {
    this.planoContaService.show(event.node.data).subscribe(json => {
     
      this.form.patchValue(json);
      this.formDialog = true;
    })
  }

  planoSelect(event) {
    this.edit(event);
  }

  tree() {
    this.arvore = []
    this.planoContaService.tree().subscribe(json => {
      
      this.arvore = json;
    })
  }

  store() {
    this.planoContaService.store(this.form.value).subscribe(json => {
      this.formDialog = false;
      this.tree();
    })
  }

  save() {
    if (this.form.get('id').value == null) {
      this.store();
    } else {
      this.update();
    }
  }


  update() {
    this.planoContaService.update(this.form.value, this.form.get('id').value).subscribe(json => {
      this.formDialog = false;
      this.tree();
    })
  }


}
