import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { IntegracaoWhatsappService } from "src/app/services/integracao-whatsapp.service";
import { NotificacaoAgendadaService } from "src/app/services/notificacao-agendada.service";

@Component({
    selector: "app-cobranca-automatica",
    templateUrl: "./cobranca-automatica.component.html",
    styleUrls: ["./cobranca-automatica.component.css"],
})
export class CobrancaAutomaticaComponent implements OnInit {
    dataSource = [];

    form: FormGroup;

    cols = [
        { field: "id", header: "ID" },
        { field: "created_at", header: "Data cadastro" },
        { field: "ativo", header: "Ativo" },
    ];

    dropDownTipo = [{ value: "REMESSA_BANCO", label: "Arquivo de remessa" }];

    uploadFile = undefined;

    loadingGrid = false;

    modal = false;

    numeroRegras = 0;

    regras = [];

    dropdownWhatsapp = [];

    constructor(
        private formBuilder: FormBuilder,
        private notificacaoAgendadaService: NotificacaoAgendadaService,
        private integracaoWhatsappService: IntegracaoWhatsappService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [null],
            ativo: [true],
            mensagem: [null],
            nome_arquivo: [null],
            tipo_arquivo: [null],
            integracao_whatsapp_id: [null],
        });

        this.listCanalWhatsapp();

        this.index();
    }

    index() {
        let request = "tipo_arquivo=REMESSA_BANCO";
        this.notificacaoAgendadaService
            .index(null, request)
            .subscribe((json) => {
                console.log(json);
                this.dataSource = json;
                if (this.dataSource) {
                    this.dataSource.map((item) => {
                        item.created_at = moment(item.created_at).format(
                            "DD/MM/YYYY HH:mm"
                        );
                        item.ativo = item.ativo ? "Sim" : "Não";
                    });
                }
            });
    }

    listCanalWhatsapp() {
        this.dropdownWhatsapp = [];
        this.integracaoWhatsappService.index().subscribe((json) => {
            json.map((item) => {
                this.dropdownWhatsapp.push({
                    value: item.id,
                    label: `${item.nome} - ${item.whatsapp}`,
                });
            });
        });
    }

    showDialogForm() {
        this.form.reset();
        this.uploadFile = undefined;
        this.regras = [];
        this.modal = true;
    }

    onUpload(event) {
        this.uploadFile = event;

        console.log(this.uploadFile);
    }

    addEnvios() {
        for (let index = 0; index < this.numeroRegras; index++) {
            this.regras.push({ dias_disparo: 0 });
        }
    }

    save() {
        let formData = new FormData();
        formData.append("myFile", this.uploadFile);

        let body = {
            notificacao: this.form.value,
            regras: this.regras,
        };

        formData.append("payload", JSON.stringify(body));

        this.notificacaoAgendadaService.insert(formData).subscribe((json) => {
            this.modal = false;
        });
    }

    close() {
        this.modal = false;
    }
}
