import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bk-form',
  templateUrl: './bk-form.component.html',
  styleUrls: ['./bk-form.component.scss']
})
export class BkFormComponent implements OnInit {

  @Input() fields: any[] = [];
  @Input() fieldset: any[] = [];
  @Input() form: FormGroup;
  @Input() skeleton: boolean = true;
  @Input() formDialog: boolean = false;
  @Input() legend: any;


  


  @Output() onBlur = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<{ event: any, element: any }>();
  @Output() editGrid = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<{ event: any, element: any }>();
  @Output() onDelDetail = new EventEmitter<{ rowData: any, element: any }>();
  @Output() hideDialog = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();


  // md: string = 'ui-md-2 ui-lg-2 ui-g-2';
  md: string = 'p-field p-sm-12 p-md-12 p-xl-2';

  constructor() { }

  ngOnInit(): void {
  }

  onChangeEvent(event, element) {
    this.onChange.emit({ event: event, element: element });
  }

  onClickEvent(event, element) {
    this.onClick.emit({ event, element });
  }

  onDelDetailEvent(rowData, element) {
    this.onDelDetail.emit({ rowData, element });
  }

  close() {
    this.formDialog = false;
  }
}
