import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ArquivoService } from "src/app/services/arquivo.service";
import { ContratoService } from "src/app/services/contrato.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { PlanoService } from "src/app/services/plano.service";

@Component({
    selector: "app-contrato-titular-plano",
    templateUrl: "./contrato-titular-plano.component.html",
    styleUrls: ["./contrato-titular-plano.component.css"],
})
export class ContratoTitularPlanoComponent implements OnInit {
    @Input() dataSource = [];

    @Input() contrato_id;

    form: FormGroup;

    cols = [
        { field: "titular_id", header: "ID", type: "default" },
        { field: "titular_nome", header: "Titular", type: "default" },
        { field: "plano_nome", header: "Plano", type: "default" },
        { field: "nascimento", header: "Nascimento", type: "default" },
        { field: "plano_valor", header: "Valor", type: "currency" },
        { field: "valor_adicional", header: "Adicional", type: "default" },
        { field: "total", header: "Total", type: "currency" },
    ];

    filteredPessoas = [];

    dropdownPlano = [];

    planoImportacao;

    loadingCarteirinha: boolean = false

    titularSelectedDependente;

    modalDependente = false;

    @Input() dependentes = [];

    @Output() onEmitValorAdicional = new EventEmitter<any>();

    @Output() onEmitDataSource = new EventEmitter<any>();

    @Output() onEmitTotalContrato = new EventEmitter<any>();

    tipoDependentePlano;

    totalContrato = 0;

    uploadFileFuncionarios = undefined;

    funcionariosXLS = [];

    modalImportacaoFuncionarios = false;

    constructor(
        private formBuilder: FormBuilder,
        private _pessoaService: PessoaService,
        private planoService: PlanoService,
        private arquivoService: ArquivoService,
        private contratoService: ContratoService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            titular: [null],
            plano: [null],
            valor: [null],
        });

        // this.dataSource = [];

        this.listPlanos();
    }

    filterPessoa(event) {
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = json.filter((p) => {
                return p.tipo == "FISICA" && p.cliente == true;
            });
        });
    }

    listPlanos() {
        this.planoService.index().subscribe((planos) => {
            this.dropdownPlano = planos;
            this.planoImportacao = this.dropdownPlano[0];
        });
    }

    downloadCarteirinha(event) {
        this.loadingCarteirinha = true
        this.contratoService
            .getCarteirinha(this.contrato_id, event.titular_id)
            .subscribe((json) => {
                window.open(json, "_blank");
                this.loadingCarteirinha = false
            });
    }

    changePlano() {
        let plano = this.form.get("plano").value;
        this.form.get("valor").setValue(plano.valor);
    }

    styleGridDependentes(dependente) {
        if (dependente["ativo"]) {
            return { "background-color": "#bcffbc" };
        } else {
            return { "background-color": "#eda5ac" };
        }
    }

    addTitular() {
        let form = this.form.value;

        let obj = {};

        obj["titular_id"] = form.titular.id;
        obj["titular_nome"] = form.titular.nome;
        obj["titular_cpf"] = form.titular.cpf;
        obj["plano_id"] = form.plano?.id || null;
        obj["plano_nome"] = form.plano?.nome || null;
        obj["plano_valor"] = form.plano?.valor || null;
        obj["ativo"] = true;
        obj["valor_adicional"] = 0;
        obj["total"] = obj["plano_valor"] + obj["valor_adicional"];

        this.dataSource.push(obj);

        this.onEmitDataSource.emit(this.dataSource);

        this.form.reset();
    }

    showDialogDependente(item) {
        this.titularSelectedDependente = item;

        this.dependentes = [];

        this.modalDependente = true;

        this.planoService.show(item.plano_id).subscribe((plano) => {
            this.tipoDependentePlano = plano.tipo_dependentes;

            if (this.titularSelectedDependente?.dependentes) {
                this.dependentes = this.titularSelectedDependente?.dependentes;
            }
        });
    }

    onEmitDependentes(event) {
        this.dependentes = event;

        this.dataSource.map((item) => {
            if (item.titular_id == this.titularSelectedDependente.titular_id) {
                item["dependentes"] = this.dependentes;
            }
        });

        this.onEmitDataSource.emit(this.dataSource);
    }

    onValorAdicional(event) {
        this.onEmitValorAdicional.emit(event);
    }

    closeModalDependente() {
        this.modalDependente = false;

        let totalAdicional = 0;
        this.dependentes.map((item) => {
            totalAdicional += parseFloat(item.payload.valor_adicional) || 0;
        });

        let planoValor = parseFloat(this.titularSelectedDependente.plano_valor);
        let soma = planoValor + totalAdicional;

        this.titularSelectedDependente.total = soma;
        this.titularSelectedDependente.valor_adicional = totalAdicional;

        this.totalContrato = 0;

        this.dataSource.map((item) => {
            this.totalContrato += parseFloat(item.total);
        });

        this.onEmitTotalContrato.emit(this.totalContrato);
    }

    onUploadFuncionario(event) {
        this.uploadFileFuncionarios = event;
        this.showFuncionariosFile();
    }

    showFuncionariosFile() {
        this.funcionariosXLS = [];

        let formData = new FormData();
        formData.append("myFile", this.uploadFileFuncionarios);
        this.arquivoService.loadXLS(formData).subscribe((data) => {
            data.map((item) => {
                try {
                    let cpf = item[1];

                    let cpfDup = this.funcionariosXLS.find((f) => {
                        return f.cpf == cpf.replace(/\D/g, "");
                    });

                    this.funcionariosXLS.push({
                        nome: item[0],
                        cpf: cpf.replace(/\D/g, ""),
                        nascimento: item[2],
                        duplicado: cpfDup == undefined ? false : true,
                    });
                } catch (error) { }
            });

            this.modalImportacaoFuncionarios = true;
        });
    }

    checkFuncionariosFile() {
        let bodyFuncionarios = [];

        this.funcionariosXLS.map((item) => {
            bodyFuncionarios.push({
                nome: item.nome,
                cpf: item.cpf,
                nascimento: item.nascimento,
            });
        });

        this._pessoaService
            .checkFuncionarioContratoFile(bodyFuncionarios)
            .subscribe((json) => {
                console.log("checkFuncionariosFile");

                json.map((item) => {
                    let funcionario = this.dataSource.find((f) => {
                        return f.titular_cpf == item.cpf;
                    });
                    if (funcionario == undefined) {
                        let obj = {};
                        obj["titular_id"] = item.id;
                        obj["titular_nome"] = item.nome;
                        obj["titular_cpf"] = item.cpf;
                        obj["plano_id"] = this.planoImportacao?.id || null;
                        obj["plano_nome"] = this.planoImportacao?.nome || null;
                        obj["plano_valor"] =
                            this.planoImportacao?.valor || null;
                        obj["valor_adicional"] = 0;
                        obj["ativo"] = true;
                        obj["total"] =
                            obj["plano_valor"] + obj["valor_adicional"];
                        this.dataSource.push(obj);
                    } else {
                        funcionario["plano_id"] =
                            this.planoImportacao?.id || null;
                        funcionario["plano_nome"] =
                            this.planoImportacao?.nome || null;
                        funcionario["plano_valor"] =
                            this.planoImportacao?.valor || null;
                        funcionario["total"] =
                            funcionario["plano_valor"] +
                            funcionario["valor_adicional"];
                    }
                });

                this.dataSource.map((item) => {
                    let funcionario = this.funcionariosXLS.find((f) => {
                        return f.cpf == item.titular_cpf;
                    });
                    if (funcionario == undefined) {
                        item["ativo"] = false;
                    }
                });

                this.onEmitDataSource.emit(this.dataSource);

                this.closeModalFuncionarios();
            });
    }

    closeModalFuncionarios() {
        this.modalImportacaoFuncionarios = false;
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.dataSource);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "funcionarios");
        });
      }

      saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
      }
    
}
