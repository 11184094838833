import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { InsumoService } from "src/app/services/insumo.service";
import { PessoaService } from "src/app/services/pessoa.service";

@Component({
    selector: "app-ativo-digital",
    templateUrl: "./ativo-digital.component.html",
    styleUrls: ["./ativo-digital.component.css"],
})
export class AtivoDigitalComponent implements OnInit {
    ativosDigitais: any[] = [];
    form: FormGroup;
    dialog: boolean = false;
    dropDownFornecedores: any[] = [];
    totalRecords: any = 0;

    constructor(
        private _formBuilder: FormBuilder,
        private _insumoService: InsumoService,
        private _pessoaService: PessoaService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            ad: this._formBuilder.group({
                id: [null],
                nome: [null],
                tipo: [null],
                ativo: true,
                fornecedores: [null],
            }),
        });
        this.index();
    }

    index() {
        this.ativosDigitais = [];
        this._insumoService.indexCripto().subscribe((json) => {
            let ativo: any;
            json.map((item) => {
                if (item.ativo == true) {
                    ativo = "Sim";
                } else {
                    ativo = "Não";
                }

                this.ativosDigitais.push({
                    id: item.id,
                    nome: item.nome,
                    ativo: ativo,
                    created_at: item.created_at,
                });
            });

            this.totalRecords = json.length;
        });
    }

    showDialogForm(event) {
        this.form.reset();
        this.dropDownFornecedores = [];
        this._pessoaService.listFornecedores().subscribe((json) => {
            json.map((item) => {
                this.dropDownFornecedores = [
                    ...this.dropDownFornecedores,
                    { value: item.id, label: item.nome },
                ];
            });
            if (event == null) {
                this.dialog = true;
            } else {
                this._insumoService.show(event.id).subscribe(
                    (json) => {
                        this.form.get("ad.id").setValue(json.id);
                        this.form.get("ad.ativo").setValue(json.ativo);
                        this.form.get("ad.nome").setValue(json.nome);
                        if (json.fornecedores) {
                            let fornecedores: any[] = [];
                            json.fornecedores.map((item) => {
                                fornecedores = [...fornecedores, item.id];
                            });
                            this.form
                                .get("ad.fornecedores")
                                .setValue(fornecedores);
                        }

                        this.dialog = true;
                    },
                    (err) => {
                        alert(err);
                    }
                );
            }
        });
    }

    removeAtivo(event) {
        this.confirmationService.confirm({
            message: "Você realmente deseja excluir este Registro ?",
            header: "Confirmação da ação",
            icon: "pi pi-info-circle",
            accept: () => {
                console.log("event", event);
                this._insumoService.show(event.id).subscribe((json) => {
                    this.form.get("ad.ativo").setValue(json.ativo);
                    this.form.get("ad.nome").setValue(json.nome);
                    if (json.fornecedores) {
                        let fornecedores: any[] = [];
                        json.fornecedores.map((item) => {
                            fornecedores = [...fornecedores, item.id];
                        });
                        this.form.get("ad.fornecedores").setValue(fornecedores);
                    }

                    let x = this.form.get("ad").value;
                    console.log("form", x);
                    let params = {
                        nome: x.nome,
                        ativo: x.ativo,
                        fornecedores: x.fornecedores,
                        excluido: true,
                    };
                    console.log("params", params);
                    this._insumoService
                        .updateCripto(event.id, params)
                        .subscribe((json) => {
                            this.index();
                        });
                });
            },
        });
    }

    save() {
        let x = this.form.get("ad").value;

        let params = {
            nome: x.nome,
            ativo: x.ativo,
            fornecedores: x.fornecedores,
        };

        if (x.id == null) {
            this._insumoService.storeCripto(params).subscribe(
                (json) => {
                    this.index();
                    this.dialog = false;
                },
                (err) => {
                    // alert(err);
                }
            );
        } else {
            this._insumoService.updateCripto(x.id, params).subscribe(
                (json) => {
                    this.index();
                    this.dialog = false;
                },
                (err) => {
                    // alert(err);
                }
            );
        }
    }
}
