import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DespesaReceitaService } from 'src/app/services/despesa-receita.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import * as moment from "moment";

@Component({
  selector: 'app-inadimplente',
  templateUrl: './inadimplente.component.html',
  styleUrls: ['./inadimplente.component.css']
})
export class InadimplenteComponent implements OnInit {
  filteredClientes: any[] = [];
  formFiltro: FormGroup;
  dataSource: any[] = []

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  cols = [
    { field: 'nome', header: 'Nome' },
    { field: 'tipo', header: 'Tipo' },
    { field: 'count_parcela', header: 'Número de parcelas' },
    { field: 'total_parcela', header: 'Total' },
    { field: 'vencimento', header: 'Vencimento' },
    { field: 'celular_principal', header: 'Celular' },
    { field: 'email_principal', header: 'E-mail' },
  ];

  constructor(
    private _pessoaService: PessoaService,
    private _despesaReceita: DespesaReceitaService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.formFiltro = this._formBuilder.group({
      data_vencimento: [null],
      cliente: [null],
    });
    this.formFiltro.get('data_vencimento').setValue(null)
  }

  filterCliente(event) {
    this._pessoaService.autoCompleteClientes(event.query).subscribe((json) => {
      this.filteredClientes = json
    });
  }

  index() {
    const formatadorMoeda = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    let startDate;
    let endDate;

    let rangeDates = this.formFiltro.get('data_vencimento').value
    let pessoa = this.formFiltro.get('cliente').value

    if (rangeDates !== null) {
      startDate = moment(rangeDates[0]).format("YYYY-MM-DD");

      endDate = moment(rangeDates[1]).format("YYYY-MM-DD");
    }


    this._despesaReceita.overdue(startDate, endDate, pessoa?.value).subscribe((json) => {

      json.map(item => {
        let tipo;

        if (item.tipo == 'FISICA') {
          tipo = 'Particular';
        } else {
          tipo = 'Empresarial';
        }

        this.dataSource.push({
          celular_principal: item.celular_principal,
          count_parcela: item.count_parcela,
          email_principal: item.email_principal,
          nome: item.nome,
          tipo: tipo,
          vencimento: item.vencimento ? moment(item.vencimento).format("DD/MM/YYYY") : null,
          total_parcela: formatadorMoeda.format(item.total_parcela)
        })
      })
    });
  }


  exportPdf() {
    let data = this.dataSource
    let col = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default("landscape");


        (doc as any).autoTable(col, data, {
          startY: (doc as any).autoTable() + 70,

          margin: { horizontal: 10 },
          styles: { overflow: "linebreak" },
          bodyStyles: { valign: "top" },
          columnStyles: { email: { columnHeight: 2 } },
          theme: "striped",
          showHead: "everyPage",
          didDrawPage: function (data) {

            // Header
            doc.setFontSize(15);
            doc.setTextColor(40);
            doc.text('Inadimplentes', data.settings.margin.left, 10);

            //Footer
            var str = "Pagina " + (doc as any).internal.getNumberOfPages();

            doc.setFontSize(10);

            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
          }
        });


        doc.save('inadimplentes.pdf');

      })
    })
  }

  exportExcel() {
    let data = this.dataSource
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Inadimplentes');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }


}
