import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class GrupoUsuarioService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'grupo-usuario');
  }

  autocomplete(param: any = null): Observable<any> {
    if (param !== '') {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete/${param}`);
    } else {
      return this.http.get(`${this.url_base}/${this.prefixo}/autocomplete`);
    }
  }
}
