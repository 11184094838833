import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';
import { SettingsService } from '../services/settings.service';


@Injectable()

export class Interceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private messageService: MessageService,
        public _settingsService: SettingsService

    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token = localStorage.getItem('token-financeiro');

        let subdominio = localStorage.getItem('conexao');

        
        if (token && request.url.indexOf('https://viacep.com.br') == -1) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    codigoEmpresa: localStorage.getItem('codigo_empresa'),
                    subdominio: subdominio ? subdominio : '',
                    plataforma: 'financeiro'
                }
            })
        }

        this._settingsService.loading = true;

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {

                    let message = '';
                    if (event.status == 201) {

                        if (event.body.message) {
                            message = event.body.message;
                        } else {
                            message = 'Registro salvo com sucesso';
                        }

                        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: `${message}` });

                    } else if (event.status == 500) {

                        message = 'Ocorreu um erro no sistema, entre em contato com o suporte.';
                        this.messageService.add({ severity: 'error', summary: 'Erro', detail: `${message}` });

                    } else {

                        if (event.status !== 200) {
                            message = event.body.message;
                            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: `${message}` });
                        }

                    }
                }

                this._settingsService.loading = false;

                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let message = (error.error.message ? error.error.message : '');
                // let message = '';

                switch (error.status) {
                    case 401:
                        {
                            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: `Sessão expirada, logue novamente no sistema` });

                            setTimeout(() => {
                                localStorage.clear();
                                this.router.navigate(['/login']);
                            }, 2000);

                            break;
                        }
                    case 405: {
                        this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: `Usuário sem permisão pra acessar a pagina` });

                        setTimeout(() => {
                            localStorage.clear();
                            this.router.navigate(['/notfound']);
                        }, 2000);

                        break;
                    }
                    default:
                        {
                            if (error.status == 422) {
                                this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: message });
                                break;
                            } else {
                                this.messageService.add({ severity: 'error', summary: 'Erro', detail: message || `Erro ao exeutar a ação, entre em contato com o suporte!` });
                                break;
                            }
                        }
                }

                // localStorage.setItem('loading', '0');
                return throwError(error);
            })


        )

    }
}
