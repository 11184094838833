import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormaPagamentoService } from "src/app/services/forma-pagamento.service";
import { InsumoService } from "src/app/services/insumo.service";
import { PessoaService } from "src/app/services/pessoa.service";

@Component({
    selector: "app-vender-ativo-digital",
    templateUrl: "./vender-ativo-digital.component.html",
    styleUrls: ["./vender-ativo-digital.component.css"],
})
export class VenderAtivoDigitalComponent implements OnInit {
    form: FormGroup;
    vendas: any[] = [];
    totalRecords: number = 0;
    dialog: boolean = false;
    dropDownClientes: any[] = [];
    dropDownFormasPagamento: any[] = [];
    dropDownProdutos: any[] = [];
    constructor(
        private _formBuilder: FormBuilder,
        private _insumoService: InsumoService,
        private _pessoaService: PessoaService,
        private _formaPagamentoService: FormaPagamentoService
    ) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            venda: this._formBuilder.group({
                id: [null],
                cliente_id: [null],
                moeda_produto_id: [null],
                custo_unitario: [null],
                valor_unitario: [null],
                quantidade: [null],
                forma_pagamento_id: [null],
                data_venda: [null],
                total: [null],
                hash: [null],
            }),
        });
        this.index();
    }

    index() {
        this.vendas = [];
        this._insumoService.listSales().subscribe((json) => {
            json.map((item) => {
                item.quantidade = parseInt(item.quantidade);
                item.quantidade = parseInt(item.quantidade);
            });
            this.vendas = json;
            this.totalRecords = json.length;
        });
    }

    total() {
        let x = this.form.get("venda").value;
        if(x.quantidade > 0){
            
            if(x.total > 0){
                this.form.get("venda.valor_unitario").setValue(x.total / x.quantidade);
            }
            if(x.valor_unitario > 0){
                this.form.get("venda.total").setValue(x.valor_unitario * x.quantidade);
        } 
            }
            
       
    }

    unitario() {
        let x = this.form.get("venda").value;
        if(x.quantidade > 0){
            
            this.form.get("venda.valor_unitario").setValue(x.total / x.quantidade);
            if(x.valor_unitario > 0){
                this.form.get("venda.total").setValue(x.valor_unitario * x.quantidade);
            }
        }
       
    }

    showDialogForm() {
        this.form.reset();
        this.dropDownClientes = [];
        this.dropDownClientes = [
            ...this.dropDownClientes,
            { value: null, label: "Selecione" },
        ];
        this.dropDownFormasPagamento = [
            ...this.dropDownFormasPagamento,
            { value: null, label: "Selecione" },
        ];
        this.dropDownProdutos = [
            ...this.dropDownProdutos,
            { value: null, label: "Selecione" },
        ];
        this._formaPagamentoService.index().subscribe((json) => {
            json.map((item) => {
                this.dropDownFormasPagamento = [
                    ...this.dropDownFormasPagamento,
                    { value: item.id, label: item.descricao },
                ];
            });
        });
        this._insumoService.indexCripto().subscribe((json) => {
            json.map((item) => {
                this.dropDownProdutos = [
                    ...this.dropDownProdutos,
                    { value: item.id, label: item.nome },
                ];
            });
        });
        this._pessoaService.listClientes().subscribe((json) => {
            json.map((item) => {
                this.dropDownClientes = [
                    ...this.dropDownClientes,
                    { value: item.id, label: item.nome },
                ];
            });
            this.form.get('venda.custo_unitario').setValue(0)
            this.form.get('venda.valor_unitario').setValue(0)
            this.form.get('venda.quantidade').setValue(0)
            this.form.get('venda.total').setValue(0)
            this.dialog = true;
        });
    }

    save() {
        let x = this.form.get("venda").value;

        let params = {
            cliente_id: x.cliente_id,
            moeda_produto_id: x.moeda_produto_id,
            custo_unitario: x.custo_unitario,
            valor_unitario: x.valor_unitario,
            quantidade: x.quantidade,
            forma_pagamento_id: x.forma_pagamento_id,
            data_venda: x.data_venda,
            total: x.total,
            hash: x.hash,
        };

        if (x.id == null) {
            this._insumoService.storeSellCripto(params).subscribe(
                (json) => {
                    console.log(json);
                    this.index();
                    this.dialog = false;
                },
                (err) => {
                    alert(err);
                }
            );
        } else {
        }
    }
}
