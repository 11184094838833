import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ContratoService extends GenericService {
    constructor(http: HttpClient) {
        super(http, "contrato");
    }

    getContratoMundialCard(id): Observable<any> {
        return this.http.get(`${this.url_base}/contrato-pdf/${id}`);
    }

    linkContratoPF(id, arquivoId): Observable<any> {
        return this.http.post(
            `${this.url_base}/${this.prefixo}/link/contrato-pf/${id}/${arquivoId}`,
            null
        );
    }

    generateCarne(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/generate/carne/${id}`);
    }

    agruparContratos(body): Observable<any> {
        return this.http.post(`${this.url_base}/${this.prefixo}/agrupar`, body);
    }

    getAgrupamentos(contratoId): Observable<any> {
        return this.http.get(
            `${this.url_base}/${this.prefixo}/agrupamentos/${contratoId}`
        );
    }

    getCarteirinha(
        contratoId,
        titularId,
        dependenteId = null
    ): Observable<any> {
        let url = `${this.url_base}/pessoa/get/carteirinha/${contratoId}/${titularId}`;

        if (dependenteId != null) {
            url += "?dependenteId=" + dependenteId;
        }

        return this.http.get(url);
    }

    delete(id): Observable<any> {
        return this.http.delete(`${this.url_base}/${this.prefixo}/${id}`);
    }

    termoCancelamento(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/termo/cancelamento/${id}`);
    }
}
