import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class ReservaService extends GenericService {

    constructor(http: HttpClient) {
        super(http, 'reserva');
    }


    listTrechosSolicitados(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/trechos-solicitados/${id}`);
    }

    filtroReserva(situacao): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/filtro-reserva/${situacao}`);
    }

    visualizarTrechosSolicitados(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/visualizar/trechos-solicitados/${id}`);
    }

    listTrechosConfirmados(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/trechos-confirmados/${id}`);
    }

    listTrechos(id): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/trechos/${id}`);
    }

    analise(id): Observable<any> {
        return this.http.put(`${this.url_base}/${this.prefixo}/analise/${id}`, null);
    }

    realizado(id): Observable<any> {
        return this.http.put(`${this.url_base}/${this.prefixo}/realizado/${id}`, null);
    }

    listReservas(): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/reservas`);
    }

    listChecklists(): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/list/checklists`);
    }

    deleteTrecho(id): Observable<any> {
        return this.http.delete(`${this.url_base}/${this.prefixo}/delete/trecho/${id}`);
    }

    confirmaTrecho(params, id): Observable<any> {
        return this.http.put(`${this.url_base}/${this.prefixo}/confirma/trecho/${id}`, params);
    }

    listReservaAeronave(id): Observable<any> {
        return this.http.get(`${this.url_base}/reserva/list/reserva-aeronave/${id}`);
    }

    contaRegistros(): Observable<any> {
        return this.http.get(`${this.url_base}/${this.prefixo}/conta/registros`);
    }

    listReservaProtocolo(protocolo): Observable<any> {
        return this.http.get(`${this.url_base}/reserva/list/reserva-protocolo/${protocolo}`);
    }

}
