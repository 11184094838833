import { Component, OnInit } from '@angular/core';
import { PessoaService } from 'src/app/services/pessoa.service';

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.css']
})
export class FinanceiroComponent implements OnInit {
  direitoReceita = true;
  
  direitoDespesa = true;

  constructor(
    private _pessoaService: PessoaService
  ) { }

  ngOnInit(): void {
    this.direitoReceita = this._pessoaService.direitos('CONTAS_RECEBER');
    this.direitoDespesa = this._pessoaService.direitos('CONTAS_PAGAR');
  }

  changeAba(event) {

  }

}
