import { Component, OnInit } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
    selector: "app-financeiro-dashboard",
    templateUrl: "./financeiro-dashboard.component.html",
    styleUrls: ["./financeiro-dashboard.component.css"],
})
export class FinanceiroDashboardComponent implements OnInit {
    cards: any = {
        totTitulosPago: 0,
        totTitulosAberto: 0,
        totTitulosVencido: 0,
        totTitulos: 0,
        countVencidos: 0,
        totDespesas: 0,
    };

    lancamentos: any[] = [];

    dataChartBar;

    labelChartBar;

    seriesName;

    foto_perfil: any;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        this.getFinancial();
        let empresa = JSON.parse(localStorage.getItem("empresa"));
        if (empresa.foto_perfil) {
            this.foto_perfil = empresa.foto_perfil;
        }
    }

    getFinancial() {
        this.dashboardService
            .financial("2023-01-01", "due")
            .subscribe((json) => {
                this.cards = json;

                this.lancamentos = json.lancamentos;

                let series: any[] = [];

                let receitas = this.lancamentos.map((l) => {
                    return Math.round(l.total_receita * 100) / 100;
                });

                let despesas = this.lancamentos.map((l) => {
                    return Math.round(l.total_despesa * 100) / 100;
                });

                let label = this.lancamentos.map((l) => {
                    return l.vencimento;
                });

                series.push(
                    {
                        name: "Receitas",
                        type: "bar",
                        barGap: 0,
                        emphasis: {
                            focus: "series",
                        },
                        data: receitas,
                    },
                    {
                        name: "Despesas",
                        type: "bar",
                        barGap: 0,
                        emphasis: {
                            focus: "series",
                        },
                        data: despesas,
                    }
                );

                this.dataChartBar = series;

                this.labelChartBar = label;

                this.seriesName = ["Receitas", "Despesas"];
            });
    }
}
