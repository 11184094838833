import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CentroCustoService } from 'src/app/services/centro-custo.service';

@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.css']
})
export class CentroCustoComponent implements OnInit {
  form: FormGroup;
  formDialog: boolean = false;
  arvore: any[] = []
  centro_custo: any[] = []
  totalRegistros: any;
  cols: any[] = [
    { field: 'nome', header: 'Descrição' },
    { field: 'tipo', header: 'Tipo' },
  ];

  items = [
    { label: 'Centros de custo' }
  ]

  home = { icon: 'pi pi-home', routerLink: '/' };
  dropDownCentroCusto: any[] = [];
  centroSelecionado: any[] = [];
  constructor(
    protected _formBuilder: FormBuilder,
    protected centroCustoService: CentroCustoService,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      ativo: [null],
      centro_custo_id: [null],
    })
    this.index();
    this.tree();
  }

  openNew() {
    this.form.reset()
    this.form.get('ativo').setValue(true)
    this.formDialog = true;
  }

  hideDialog() {
    this.formDialog = false;
  }

  index() {
    this.centroCustoService.index().subscribe(json => {

      this.centro_custo = json;
      this.totalRegistros = json.length;

      this.dropDownCentroCusto = [];
      this.centro_custo.map(item => {
        this.dropDownCentroCusto = [...this.dropDownCentroCusto, { value: item.id, label: item.nome }];
      })
    })
  }

 

  edit(event) {
    this.centroCustoService.show(event.node.data).subscribe(json => {
     
      this.form.patchValue(json);
      this.formDialog = true;
    })
  }

  planoSelect(event) {
    this.edit(event);
  }

  tree() {
    this.arvore = []
    this.centroCustoService.tree().subscribe(json => {
     
      json.map(item => {
        if(item.label == null){
          item.label = item.nome
        }
        if(item.children){
          item.children.map(children => {
if(children.label == null){
  children.label = children.nome
}
          })

        }
      })
      this.arvore = json;
    })
  }

  store() {
    this.centroCustoService.store(this.form.value).subscribe(json => {
      this.formDialog = false;
      this.tree();
    })
  }

  save() {
    if (this.form.get('id').value == null) {
      this.store();
    } else {
      this.update();
    }
  }


  update() {
    this.centroCustoService.update(this.form.value, this.form.get('id').value).subscribe(json => {
      this.formDialog = false;
      this.tree();
    })
  }


}
